<div class="container">
    <div *ngIf="isPropertyDialog" mat-dialog-title class="dialog-secondary-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="dialog-secondary-header-title" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between">
            <label class="color-style label-text">Add Property Manager</label>
            <button mat-icon-button class="close-button mb-10" (click)="dialogClose()">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </div>
    </div>
    <mat-dialog-content>
        <form [formGroup]="additionalDataForm">
            <div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                    <div fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="signup-forms-fields" fxLayout="column"
                        fxLayoutAlign="start" fxLayoutAlign.sm="start" fxLayoutAlign="center start">
                        <mat-label class="form-label" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25">First Name*
                        </mat-label>
                        <div class="w-100-p">
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="first name" formControlName="firstName" type="text"
                                    appAlphabetOnly autocomplete="off" />
                                <mat-error>
                                    <span *ngIf="
                      additionalDataForm.controls['firstName'].errors
                        ?.required ||
                      additionalDataForm.controls['firstName'].touched
                    "><strong>Required.</strong>
                                    </span>
                                </mat-error>
                                <mat-error>
                                    <span *ngIf="
                      additionalDataForm.controls['firstName'].errors
                        ?.pattern &&
                      additionalDataForm.controls['firstName'].touched
                    "><strong>Invalid First Name.</strong>
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="signup-forms-fields" fxLayout="column"
                        fxLayoutAlign="start">
                        <mat-label class="form-label" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25">Last Name*
                        </mat-label>
                        <div class="w-100-p">
                            <mat-form-field appearance="outline">
                                <input matInput placeholder="last name" formControlName="lastName" type="text"
                                    appAlphabetOnly autocomplete="off" />
                                <mat-error>
                                    <span *ngIf="
                                    additionalDataForm.controls['lastName'].errors
                                    ?.required ||
                                  additionalDataForm.controls['lastName'].touched
                    "><strong>Required.</strong>
                                    </span>
                                </mat-error>
                                <mat-error>
                                    <span *ngIf="
                      additionalDataForm.controls['lastName'].errors?.pattern &&
                      additionalDataForm.controls['lastName'].touched
                    "><strong>Invalid Last Name.</strong>
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="isPropertyDialog" fxFlex="45" fxFlex.sm="100" fxFlex.xs="100" class="signup-forms-fields" fxLayout="column" fxLayoutAlign="start">
                        <mat-label class="form-label" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25">Email*
                        </mat-label>
                        <div class="w-100-p">
                            <mat-form-field appearance="outline">
                                <input type="email" matInput placeholder="admin@allpros.com" formControlName="email" />
                                <mat-error>
                                    <span *ngIf="
                      additionalDataForm.controls['email'].errors?.required ||
                      additionalDataForm.controls['email'].touched
                    "><strong>Required.</strong>
                                    </span>
                                </mat-error>
                                <mat-error>
                                    <span *ngIf="
                      additionalDataForm.controls['email'].errors?.pattern &&
                      additionalDataForm.controls['email'].touched
                    "><strong>Invalid Last Name.</strong>
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="signup-forms-fields" fxFlex="45" fxFlex.sm="100" fxFlex.xs="100"
                        class="signup-forms-fields" fxLayout="column" fxLayoutAlign="start">
                        <mat-label class="form-label" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25">Contact Number*
                        </mat-label>
                        <div class="w-100-p">
                            <mat-form-field appearance="outline">
                                <span matPrefix>+1&nbsp;</span>
                                <input matInput placeholder="999-999-9999" formControlName="mobile" mask="000-000-0000"
                                    maxlength="12" autocomplete="off" />
                                <mat-error>
                                    <span *ngIf="
                      additionalDataForm.get('mobile').errors?.required ||
                      additionalDataForm.get('mobile').touched
                    "><strong>Required.</strong>
                                    </span>
                                </mat-error>
                                <mat-error>
                                    <span *ngIf="
                      additionalDataForm.get('mobile').errors?.minlength &&
                      additionalDataForm.get('mobile').touched
                    "><strong>Min Length Should be 10 digits.</strong>
                                    </span>
                                </mat-error>
                                <mat-error>
                                    <span *ngIf="
                      additionalDataForm.get('mobile').errors?.maxlength &&
                      additionalDataForm.get('mobile').touched
                    "><strong>Max Length 10 digits.</strong>
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isPropertyDialog" class="dialog-footer mt-4" fxLayout="row" fxLayoutAlign="flex-end start">
                <button class="btn btn-medium btn-secondary btn-spinner-white" mat-button
                    (click)="sendAdditionalData()">
                    <span fxLayout="row" fxLayoutAlign="center center">
                        <span class="p-4" ngClass.xs="ph-8">save </span>
                        <mat-spinner [diameter]="20" *ngIf="loading"> </mat-spinner>
                    </span>
                </button>
            </div>
            <div *ngIf="isPropertyDialog" class="dialog-footer mt-4" fxLayout="row" fxLayoutAlign="flex-end start">
                <button class="btn btn-medium btn-secondary btn-spinner-white" mat-button
                    (click)="addPropertyManager()">
                    <span fxLayout="row" fxLayoutAlign="center center">
                        <span class="p-4" ngClass.xs="ph-8">add </span>
                        <mat-spinner [diameter]="20" *ngIf="loading"> </mat-spinner>
                    </span>
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>