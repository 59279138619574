<div class="container">
  <mat-card class="dialog-container">
    <mat-card-header class="dialog-header">
      <!-- <mat-card-title fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="mr-10">{{
          parentData.icon ? parentData.icon : ""
        }}</mat-icon>
        {{ parentData.title ? parentData.title : "" }}
      </mat-card-title> -->
    </mat-card-header>
    <mat-card-content class="dialog-content" fxLayout="row" fxLayoutAlign="center center">
      <p>
        {{ parentData.text || "" }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <div class="footer mt-4" fxLayout="row" fxLayoutAlign="center center">
        <!-- <button class="btn btn-medium btn-secondary btn-spinner-white" mat-button (click)="cancelDialog()">
          <span fxLayout="row" fxLayoutAlign="center center">
            <span>{{ parentData.declineLabel ? parentData.declineLabel : "no" }}
            </span>
          </span>
        </button> -->
        <button class="btn btn-medium btn-primary btn-spinner-white" mat-button (click)="confirmDialog()">
          <span fxLayout="row" fxLayoutAlign="center center">
            <span>{{ parentData.primaryButtonLabel || "yes" }}
            </span>
          </span>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>