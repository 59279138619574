import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MaterialModule } from '../material/material-module';
import { FlexLayoutModule } from '@angular/flex-layout';


const component = HeaderComponent
@NgModule({
  declarations: [component],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [component]
})
export class HeaderModule { }
