<div class="container">
    <form [formGroup]="createRequestForm">
        <div mat-dialog-title class="dialog-secondary-header" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="dialog-secondary-header-title">
                <label>request form</label>
            </div>
            <div class="close-button cursor">
                <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
            </div>
        </div>

        <!-- <div class="divider"></div> -->
        <div mat-dialog-content class="content-body">
            <!-- <div class="radio-buttons">
                <mat-radio-group formControlName="selectedReq" (change)="onItemChange($event)">
                    <mat-radio-button class="mr-20" ngClass.xs="mb-20" value="discovery">
                        I need pro referrals
                    </mat-radio-button>
                    <mat-radio-button class="mr-20" value="directRequest">
                        I have a pro
                    </mat-radio-button>
                </mat-radio-group>
            </div> -->
            <div class="service-container">
                <div class="service-categories mt-30" fxFlex.xs="100">
                    <div class="service-category-address" fxLayout="row" fxLayout.xs="column"
                        fxLayoutAlign="space-between start">
                        <div fxFlex="45" class="service-category">
                            <mat-label>Service Category</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select createRequestForm.formControlName="serviceCategory" #singleSelect
                                    formControlName="serviceCategory">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="serviceCategoryValue" placeholderLabel=""
                                            noEntriesFoundLabel="nothing to show">
                                        </ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option *ngFor="let category of $serviceCategories | async" [value]="category">
                                        {{ category.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span *ngIf="createRequestForm.get('serviceCategory').errors?.required">
                                        <strong>Required</strong>
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- <div fxFlex="45" fxFlex.xs="100"
                            *ngIf="createRequestForm.controls['selectedReq'].value !=='directRequest' && page !=='myPros' ">
                            <mat-label>Don't know the best category?</mat-label>
                            <div>
                                <mat-checkbox class="service-askAllpros-Checkbox" formControlName="askAllprosCheckbox"
                                    [disabled]="true" (change)="checkboxClicked($event)">
                                    <span class="ask-allpros-font">
                                        ask allpros
                                    </span>
                                </mat-checkbox>
                            </div>
                            <div *ngIf="isCheckboxClicked" class="ask-allpros-description">
                                Allpros will help assign your request a category and get back to you with
                                recommendations within 24 hours of your submitting this request
                            </div>
                        </div> -->
                        <div fxFlex="45" class="description">
                            <mat-label>Description*</mat-label>
                            <mat-form-field appearance="outline">
                                <textarea matInput placeholder="" formControlName="description"></textarea>
                                <mat-error>
                                    <span *ngIf="createRequestForm.get('description').errors?.required">
                                        <strong>Description cannot be empty</strong>
                                    </span>
                                </mat-error>
                                <!-- <mat-error>
                                        <span
                                            *ngIf="createRequestForm.get('description').errors?.required"><strong>Required</strong>
                                        </span>
                                    </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start"
                        class="description-image">
                        <div fxFlex="45" [ngClass.xs]="'mt-10'">
                            <mat-label>Service Address*</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="serviceAddress">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="searchTextControl"
                                            placeholderLabel="Search here" noEntriesFoundLabel="nothing to show">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <ng-container
                                        *ngFor="let address of addressList | filterBy: {'address':searchTextControl.value}">
                                        <mat-option class="home-owner-select-text"
                                            *ngIf="address.canCreateServiceTicketsForProperty" [value]="address">
                                            {{ ((address.apartmentNumber) ? address.apartmentNumber : '') + " "
                                            +address.address }}
                                            P </mat-option>
                                    </ng-container>
                                    <mat-option class="home-owner-select-text" *ngFor="let address of addresses"
                                        [value]="address.value">
                                        {{ address.viewValue }}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span *ngIf="createRequestForm.get('serviceAddress').errors?.required">
                                        <strong>Required</strong>
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="45">
                            <mat-label>How soon would you like your service completed*</mat-label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="requestFulfillDate">
                                    <mat-option class="home-owner-select-text"
                                        *ngFor="let referenceString of requestData" [value]="referenceString">
                                        {{ referenceString}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    <span *ngIf="createRequestForm.get('requestFulfillDate').errors?.required">
                                        <strong>Required</strong>
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="createRequestForm.controls['serviceAddress'].value === 'newAddress'"
                        class="address-form">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="flex-end start">
                            <span class="reset-btn" (click)="onClickReset()">reset</span>
                        </div>
                        <div fxLayout.xs="column" fxLayoutAlign="space-between">
                            <div fxFlex="45" class="service-category-zipcode">
                                <mat-label>Apartment No.</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="apartmentNumber" />
                                    <mat-error>
                                        <span *ngIf="createRequestForm.get('apartmentNumber').errors?.required ">
                                            <strong>Required</strong>
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="45" class="service-category-street">
                                <mat-label>Street*</mat-label>
                                <mat-form-field appearance="outline">
                                    <input ngx-google-places-autocomplete matInput #placesRef="ngx-places"
                                        formControlName="street" (input)="onChangeAddress()"
                                        (onAddressChange)="handleAddressChange($event)"
                                        [options]="{ strictBounds: true }" />
                                    <mat-error>
                                        <span *ngIf="createRequestForm.get('street').errors?.required">
                                            <strong>Required</strong>
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                                <mat-error> </mat-error>
                            </div>
                        </div>
                        <div fxLayout.xs="column" fxLayoutAlign="space-between">
                            <div fxFlex="45" class="service-category-city">
                                <mat-label>City*</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="city" [ngClass]="
                        disableInputFlag ? 'disable-input' : 'enable-input'
                      " />
                                    <mat-error>
                                        <span *ngIf="createRequestForm.get('city').errors?.required">
                                            <strong>Required</strong>
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="45" class="service-cateogry-state">
                                <mat-label>State*</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="state" [ngClass]="
                        disableInputFlag ? 'disable-input' : 'enable-input'
                      " />
                                    <mat-error>
                                        <span *ngIf="createRequestForm.get('state').errors?.required">
                                            <strong>Required</strong>
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                                <mat-error> </mat-error>
                            </div>
                        </div>
                        <div fxLayout.xs="column" fxLayoutAlign="space-between">

                            <div fxFlex="45" class="service-category-zipcode">
                                <mat-label>Zip Code*</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput formControlName="zipCode" [ngClass]="
                        disableInputFlag ? 'disable-input' : 'enable-input'
                      " />
                                    <mat-error>
                                        <span *ngIf="
                          createRequestForm.get('zipCode').errors?.required
                        ">
                                            <strong>Required</strong>
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                                <mat-error> </mat-error>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="page == 'myPros'" fxLayout="row" class="mt-10">
                        <p class="form-label">Selected Firm :&nbsp;</p>
                        <span>
                            {{ dialogData?.pros?.detailed_information?.firm?.name }}
                        </span>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                        fxLayoutAlign.xs="flex-end">
                        <!-- <div class="mt-10" fxFlex="45" fxFlex.xs="100" *ngIf="createRequestForm.controls['selectedReq'].value ==='directRequest'">
                            <mat-label>{{dialogData?.pros?.detailed_information?.firm?.name ? 'Choose a pro from this firm' : 'Choose pro*'}}
                            </mat-label>
                            <mat-form-field appearance="outline">
                                <input type="text" formControlName="proName" matInput placeholder="search for a pro" autocomplete="off" [matAutocomplete]="fullName" />
                                <mat-autocomplete #fullName="matAutocomplete" [displayWith]="disp.bind(this)">
                                    <mat-option class="is-loading" *ngIf="searchLoading">
                                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                    </mat-option>
                                    <mat-option *ngIf="isProEmpty" disabled>
                                        <span>no search results found</span>
                                    </mat-option>
                                    <ng-container *ngIf="!searchLoading">
                                        <div style="overflow: scroll">
                                            <ng-container *ngFor="let data of searchData">
                                                <mat-option class="firm-name" (click)="onFirmSelect(data?.firm)" [value]="data.firm">
                                                    <div fxLayout="row" fxLayoutAlign="start center" class="firm-container">
                                                        <div fxLayout="row" fxLayoutAlign="start center" class="firm-logo mr-10">
                                                            <img style="border-radius: 50%" src="{{
                                data?.firm?.logoPath
                                  ? data?.firm?.logoPath
                                  : 'https://via.placeholder.com/40/808080/FFFFF'
                              }}" alt="firm logo" class="h-30 w-30" />
                                                        </div>
                                                        <div>{{ data?.firm?.name }}</div>
                                                    </div>
                                                </mat-option>
                                                <mat-option *ngFor="let item of data?.accounts" [value]="item" (click)="onAccountSelect(item,data.firm)">
                                                    <div fxLayout="row" fxLayoutAlign="start center">
                                                        <div class="pl-28" fxLayout="row" fxLayoutAlign="center center">
                                                            <div fxLayout="row" fxLayoutAlign="center center" class="ph-10">
                                                                <img style="border-radius: 50%" src="{{
                                  data?.firm?.logoPath
                                    ? data?.firm?.logoPath
                                    : 'https://via.placeholder.com/40/808080/FFFFF'
                                }}" alt="firm logo" class="h-30 w-30" />
                                                            </div>
                                                            <div>{{ item.fullName }}</div>
                                                        </div>
                                                    </div>
                                                </mat-option>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </mat-autocomplete>
                                <mat-error>
                                    <span *ngIf="createRequestForm.get('proName').errors?.required">
                                        <strong>Required</strong>
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                        <div fxFlex="45" class="upload-picture">
                            <mat-label>Upload Pictures</mat-label>
                            <div class="image-upload" fxLayout="row" fxLayoutAlign="start">
                                <div class="m-5 container" fxLayout="column" fxLayoutAlign="center center"
                                    *ngFor="let url of urls; let index = index">
                                    <img class="images" [src]="url" height="40" width="40" />
                                    <div class="close-icon-div" fxLayout="column" fxLayoutAlign="center center"
                                        (click)="deleteImage(index)" *ngIf="deleteImageFlag">
                                        <mat-icon class="cursor" aria-hidden="false">close
                                        </mat-icon>
                                    </div>
                                </div>
                                <div class="m-5">
                                    <label for="file-input">
                                        <span class="material-icons" [ngClass]="
                                 urls.length < 5? 'add-icon-enable': 'add-icon-disable'">add</span>
                                    </label>
                                </div>
                                <input id="file-input" type="file" [attr.disabled]="urls.length < 5 ? null : ''"
                                    accept=".jpg, .jpeg, .png" (change)="onSelectFile($event)" multiple
                                    formControlName="serviceImages" />
                            </div>
                            <span class="error-msg" *ngIf="showError">{{ errorMsg }}</span>
                        </div>

                        <div fxFlex="45" fxFlex.xs="100">
                            <div>
                                <mat-checkbox class="service-askAllpros-Checkbox"
                                    formControlName="needReferralsCheckbox" (change)="checkboxClicked($event)">
                                    <span class="ask-allpros-font">
                                        Do you need referrals ?
                                    </span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="footer mt-4" fxLayout="row" fxLayoutAlign="flex-end start">
            <button class="btn btn-medium btn-secondary btn-spinner-white" mat-button [disabled]="loading"
                (click)="handleCreateRequest()">
                <span fxLayout="row" fxLayoutAlign="start center">
                    <span class="p-4" ngClass.xs="ph-8"> {{createRequestForm.get("selectedReq").value ===
                        'discovery' ? ' submit service ticket' :'request a service'}}
                    </span>
                    <mat-spinner [diameter]="20" *ngIf="loading"> </mat-spinner>
                </span>
            </button>
        </div>
    </form>
</div>