<div class="sidebar" *ngIf="session.isActive()" [ngClass]="isExpanded ? 'w-220' : 'w-96'">
    <!-- <div class="sidenav-menu-icon" *ngIf="!mobile">
    <button mat-icon-button (click)="isExpanded = !isExpanded">
      <mat-icon aria-label="Menu" *ngIf="!isExpanded">menu</mat-icon>
      <mat-icon *ngIf="isExpanded">arrow_back</mat-icon>
    </button>
  </div> -->
    <mat-nav-list>
        <mat-list-item>
            <div class="logo">
                <img *ngIf="isExpanded" [src]="(logo | async)?.allProsSideNavImageLink" alt="allpros_logo" />
                <img *ngIf="!isExpanded" src="..\assets\sidenav_logo.svg" />
            </div>
        </mat-list-item>
    </mat-nav-list>

    <div class="firm-detail"
        [ngClass]="proRole === 'HOMEOWNER' || proRole === 'PROPERTY_MANAGER' || proRole === 'PROPERTY_MANAGER_ADMIN' ? 'mt-10 mb-0' : 'mv-10'">
        <div [ngClass]="
          !isOpen ? 'header-content-close cursor' : 'header-content-open cursor'
        " fxLayout="row" fxLayoutAlign="center center" joyrideStep="profile"
            [text]="getViewProfileUserGuidedText(proRole)" stepPosition="right" (done)="endGuidedJourney()">
            <mat-list-item>
                <div fxLayout class="circular-square"
                    [routerLink]="(proRole === 'HOMEOWNER' || proRole === 'PROPERTY_MANAGER' || proRole === 'PROPERTY_MANAGER_ADMIN') ? ['/home-owner/profile']:['/pro/firm-info']">
                    <img [src]="
                  (logoPath | async)
                    ? (logoPath | async)
                    : 'https://allpros-documents.s3.amazonaws.com/default_firm_icon.png'
                " width="45px" height="45px" style="border: 0.5px solid #081835" />
                </div>
            </mat-list-item>
            <mat-list-item *ngIf="isExpanded">
                <div
                    [routerLink]="(proRole === 'HOMEOWNER' || proRole === 'PROPERTY_MANAGER' || proRole === 'PROPERTY_MANAGER_ADMIN') ? ['/home-owner/profile']:['/pro/firm-info']">
                    <p class="firminfo mb-1">{{ (fullName | async) || session?.account?.email | ellipsis: 20}}</p>
                    <span class="firminfo" *ngIf="!(proRole === 'HOMEOWNER')">

                        <app-data-handler class="customer-details-data" content="{{ FirmName | async }}" size="20"
                            style="width: max-content;">
                        </app-data-handler>

                    </span>
                    <span
                        *ngIf="proRole === 'HOMEOWNER' || proRole === 'PROPERTY_MANAGER' || proRole === 'PROPERTY_MANAGER_ADMIN'"
                        class="view-profile">
                        <p style="color:#ffffff; text-decoration: underline;">view profile</p>
                    </span>
                </div>
            </mat-list-item>
            <mat-list-item *ngIf="!isExpanded">
                <div class="firm-detail-icon">
                    <div *ngIf="proRole === 'HOMEOWNER' || proRole === 'PROPERTY_MANAGER' || proRole === 'PROPERTY_MANAGER_ADMIN'"
                        (click)="isOpen = !isOpen">
                        <div joyrideStep="propertyDropdown"
                            text="Hi, let us show you around. Begin by setting up your profile and adding your address."
                            stepPosition="right" (done)="endGuidedJourney()">
                            <button [matMenuTriggerFor]="homeOwnerMenu" style="background: none; border: none;">
                                <mat-icon class="keyboard-arrow-up" *ngIf="!isOpen">
                                    chevron_right
                                </mat-icon>
                                <mat-icon style="color: #ffffff;" *ngIf="isOpen">
                                    keyboard_arrow_down
                                </mat-icon>
                            </button>
                        </div>
                        <!-- <mat-icon class="keyboard-arrow-up" *ngIf="!isOpen && isExpanded">chevron_right</mat-icon>
            <mat-icon style="color: #ffffff" class="keyboard-arrow-down" *ngIf="isOpen  && isExpanded">
              keyboard_arrow_down
            </mat-icon> -->
                    </div>

                    <mat-menu #homeOwnerMenu="matMenu" xPosition="after" yPosition="below">
                        <!-- <button mat-menu-item (click)="onViewProfile()">view profile</button> -->
                        <button mat-menu-item [matMenuTriggerFor]="allProperties">all properties</button>
                    </mat-menu>
                    <mat-menu #allProperties="matMenu" xPosition="after" yPosition="below">
                        <button mat-menu-item (click)="$event.stopPropagation()"
                            style="border-bottom: 1px solid #e5e5e5;">
                            <input matInput [formControl]="searchTextControl" placeholder="Search here">
                        </button>

                        <button mat-menu-item (click)="onAddressChange('')">All properties</button>
                        <ng-container
                            *ngFor="let data of userAddressData | filterBy: {'label':searchTextControl.value}">
                            <button mat-menu-item (click)="onAddressChange(data.id)"
                                *ngIf="data.canViewProperty">{{data.label}}</button>
                        </ng-container>
                    </mat-menu>
                    <!-- <mat-menu #proMenu>
            <div *ngFor=" let data of settingTabLinks; index as i; let last=last">
              <div class="mb-5" *ngxPermissionsOnly="data.permission">
                <button mat-menu-item> <a [routerLink]="data.link" [routerLinkActive]="['is-active']"
                    (click)="isOpen = !isOpen;closeFilter()">{{ data.label }}</a></button>
              </div>
            </div>
          </mat-menu> -->
                </div>
            </mat-list-item>
        </div>
        <!-- <mat-list-item *ngIf="isExpanded">
      <div class="header-options" *ngIf="isOpen && isExpanded" @easeInOut>
        <div *ngFor=" let data of settingTabLinks; index as i; let last=last">
          <div class="mb-5" *ngxPermissionsOnly="data.permission">
            <a @easeInOut [routerLink]="data.link" [routerLinkActive]="['is-active']"
              (click)="isOpen = !isOpen;closeFilter()">{{
              data.label }}</a>
          </div>
        </div>
      </div>
    </mat-list-item> -->

        <mat-list-item *ngIf="isExpanded">
            <div class="sidenav-filter"
                *ngIf="proRole === 'HOMEOWNER' || proRole === 'PROPERTY_MANAGER' || proRole === 'PROPERTY_MANAGER_ADMIN' && !isOpen"
                joyrideStep="propertyDropdown" text="Switch between different properties here." stepPosition="right"
                (done)="endGuidedJourney()">

                <mat-form-field floatLabel="never">
                    <mat-label>
                        <span class="sidenav-filter-label"> All Properties </span>
                    </mat-label>
                    <mat-select [(ngModel)]="selectedAddress" (ngModelChange)="onAddressChange($event)">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="searchTextControl" placeholderLabel="Search here"
                                noEntriesFoundLabel="nothing to show">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option value=""> All Properties </mat-option>
                        <ng-container
                            *ngFor="let data of userAddressData | filterBy: {'label':searchTextControl.value}">
                            <mat-option [value]="data.id" *ngIf="data.canCreateServiceTicketsForProperty"><span>
                                    {{ data.label }}
                                </span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

            </div>
        </mat-list-item>
    </div>

    <!-- <div style="height: 2em" *ngIf="isOpen"></div> -->

    <div class="menu-section mt-20">
        <mat-list-item>
            <div *ngIf="isExpanded">
                <ng-container *ngFor="let data of tabLinks; index as i">
                    <div class="link" *ngxPermissionsOnly="data.permission">
                        <a joyrideStep="tabIndex{{ i }}" text="{{ userGuideText[i]?.text }}" [routerLink]="data.link"
                            [routerLinkActive]="['is-active']" stepPosition="right" (done)="endGuidedJourney()"
                            [ngClass]="isExpanded ? 'mv-10':'m-0'" (click)="closeFilter()">
                            <div class="active-menu">
                                <span *ngIf="data.showTab" style="position: relative;">
                                    <span style="padding-right: 
                  12px; margin:1px 0px">{{ data.label }}</span>
                                    <span
                                        *ngIf="data.label === 'notifications' && notificationData?.data?.isNewNotificationPresent"
                                        style="height: 5px;
                  width: 5px;
                  background-color: #00b2ee;
                  border-radius: 50%;
                  position: absolute;
                  right: 0px;
                  top: 6px;">

                                    </span>
                                    <!-- notificationData?.data?.isNewNotificationPresent -->
                                </span>

                                <!-- <b *ngIf="data.label === 'notifications'" [ngClass]="{
                    dot: true}"></b> -->


                            </div>
                        </a>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="!isExpanded">
                <ng-container *ngFor="let data of tabLinks; index as i">
                    <div class="link" *ngxPermissionsOnly="data.permission">
                        <a joyrideStep="tabIndex{{ i }}" text="{{ userGuideText[i]?.text }}" [routerLink]="data.link"
                            [routerLinkActive]="['is-active']" stepPosition="right" (done)="endGuidedJourney()">
                            <div class="active-menu" fxLayout="row" fxLayoutAlign="center center">
                                <span style="position: relative;">
                                    <!-- <span> -->
                                    <img height="24" width="40" *ngIf="data.showTab" style="margin-top: 6px;"
                                        matTooltip="{{data.tooltip}}" matTooltipPosition="right" src=" {{data.icon}}" />
                                    <!-- </span> -->
                                    <span
                                        *ngIf="data.label === 'notifications' && notificationData?.data?.isNewNotificationPresent"
                                        style="height: 5px;
                width: 5px;
                background-color: #00b2ee;
                border-radius: 50%;
                position: absolute;
                right: -10px;
                top: 6px;">

                                    </span>
                                </span>
                                <!-- <span class="notification-dot-color-not-expanded"><b *ngIf="data.label === 'notifications'" [ngClass]="{
                          dot: notificationData?.data?.isNewNotificationPresent}"></b></span> -->
                            </div>
                        </a>
                    </div>
                </ng-container>
            </div>
        </mat-list-item>

        <div class="section-bottom">
            <mat-list-item [fxHide]="showUserGuidedJourney()">
                <div *ngIf="isExpanded" class="pv-5" (done)="finishGuidedJourney()" joyrideStep="confirmClose"
                    text="You can revisit the brief tutorial anytime from here." stepPosition="right">
                    <a fxLayout class="cursor confirm-close" (click)="startGuidedJourney()">User Guide</a>
                </div>
                <div *ngIf="!isExpanded" class="logout-icon pl-7" (done)="finishGuidedJourney()"
                    joyrideStep="confirmClose" text="You can revisit the brief tutorial anytime from here."
                    stepPosition="right">
                    <img src="../assets/guidedJourney.svg" matTooltip="user guide" matTooltipPosition="right"
                        (click)="startGuidedJourney()">
                </div>
            </mat-list-item>

            <mat-list-item>
                <div [ngClass]="isExpanded ? '' : 'logout-icon'" (click)="logOut()">
                    <button *ngIf="isExpanded" class="logoutbutton" type="submit" mat-button color="primary">
                        sign out
                    </button>
                    <img *ngIf="!isExpanded" matTooltip="sign out" matTooltipPosition="right"
                        src="../assets/logout.svg">
                </div>
            </mat-list-item>
        </div>
    </div>


</div>