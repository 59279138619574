import { Injectable } from "@angular/core";
import { Store } from "src/app/core/store/store";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import {
  AdminAccountPayload,
  AdminPasswordPayload,
  SubmitAdminDataPayload,
} from "./admin-acoount.model";
import {
  ActivateUserAccountLoadedState,
  ActivateUserAccountLoadingErrorState,
  ActivateUserAccountLoadingState,
  AdminAccountLoadedState,
  AdminAccountLoadingErrorState,
  AdminAccountLoadingState,
  AdminPasswordLoadedState,
  AdminPasswordLoadingErrorState,
  AdminPasswordLoadingState,
  SubmitAdminDataLoadedState,
  SubmitAdminDataLoadingErrorState,
  SubmitAdminDataLoadingState,
  VerifyEmailLoadedState,
  VerifyEmailLoadingErrorState,
  VerifyEmailLoadingState,
} from "./admin-acoount.state";

@Injectable({ providedIn: "root" })
export class AdminAccountService extends Store.AbstractService {
  // Get admin account details...
  getAdminAccountDetails(
    adminAccountPayload: AdminAccountPayload
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new AdminAccountLoadingState());
    }, 0);

    const path =
      environment.api.settings.adminAccount.getAdminAccount.endpoint +
      "/" +
      adminAccountPayload.id +
      "/profile";

    me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new AdminAccountLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new AdminAccountLoadingErrorState(e));
        output.complete();

        me.logger.error("error", e);
      }
    );

    return output;
  }

  // userlogged in - 1st time user login change password
  changeAdminPassword(
    passwordPayload: AdminPasswordPayload,
    account_id: string
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new AdminPasswordLoadingState());
    }, 0);

    // const path = environment.api.settings.adminAccount.changePassword.endpoint;

    const path = me.controller.replaceVariables(
      environment.api.settings.adminAccount.changePassword.endpoint,
      {
        accountId: account_id,
        hasUserLoggedInFirstTime: passwordPayload.hasUserLoggedInFirstTime,
      }
    );

    me.controller
      .post(path, passwordPayload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new AdminPasswordLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new AdminPasswordLoadingErrorState(e));
          output.complete();

          me.logger.error("error", e);
        }
      );

    return output;
  }

  // Update admin account details with new payload...
  updateAdminAccountDetails(
    adminAccountPayload: SubmitAdminDataPayload,
    admin: string
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new SubmitAdminDataLoadingState());
    }, 0);

    // const path = environment.api.settings.adminAccount.update.endpoint;

    const path = me.controller.replaceVariables(
      environment.api.settings.adminAccount.update.endpoint,
      { accountId: admin }
    );

    me.controller
      .post(path, adminAccountPayload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new SubmitAdminDataLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new SubmitAdminDataLoadingErrorState(e));
          output.complete();

          me.logger.error("error", e);
        }
      );

    return output;
  }

  // On save , verify updated email id...
  verifyEmail(emailID: string): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new VerifyEmailLoadingState());
    }, 0);
    // const path = environment.api.settings.adminAccount.update.endpoint;
    const path = me.controller.replaceVariables(
      environment.api.settings.adminAccount.verifyEmail.endpoint,
      { email: emailID }
    );
    me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new VerifyEmailLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new VerifyEmailLoadingErrorState(e));
        output.complete();
        me.logger.error("error", e);
      }
    );
    return output;
  }


  activateUserAccount(userid) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new ActivateUserAccountLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.account.deleteAccount.endpoint,
      { userId: userid }
    );

    me.controller
      .post(path, null, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new ActivateUserAccountLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new ActivateUserAccountLoadingErrorState(e));
          output.complete();
        }
      );
    return output;
  }
}
