import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-common-dialog",
  templateUrl: "./common-dialog.component.html",
  styleUrls: ["./common-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommonDialogComponent implements OnInit {
  parentData: any;
  constructor(
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const me = this;
    me.parentData = me.data.dialogContent;
 console.log("me.parentData ", me.parentData);
  }
  cancelDialog() {
    const me = this;
    this.dialogRef.close();
  }
  confirmDialog() {
    const me = this;
    this.dialogRef.close(true);
  }
}
