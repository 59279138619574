import { Store } from "src/app/core/store/store";
import { HomeOwnerProfile } from "./home-owner-profile.model";

export class homeOwnerProfileDataLoadingState extends Store.AbstractLoadingState<HomeOwnerProfile> {}
export class homeOwnerProfileDataLoadingErrorState extends Store.AbstractErrorState<HomeOwnerProfile> {}
export class homeOwnerProfileDataLoadedState extends Store.AbstractIdealState<HomeOwnerProfile> {}

export class homeOwnerAddressDataLoadingState extends Store.AbstractLoadingState<any> {}
export class homeOwnerAddressDataLoadingErrorState extends Store.AbstractErrorState<any> {}
export class homeOwnerAddressDataLoadedState extends Store.AbstractIdealState<any> {}

export class FileUploadLoadingState extends Store.AbstractLoadingState<HomeOwnerProfile> {}
export class FileUploadLoadingErrorState extends Store.AbstractErrorState<HomeOwnerProfile> {}
export class FileUploadLoadedState extends Store.AbstractIdealState<HomeOwnerProfile> {}

export class FileDeleteLoadingState extends Store.AbstractLoadingState<HomeOwnerProfile> {}
export class FileDeleteLoadingErrorState extends Store.AbstractErrorState<HomeOwnerProfile> {}
export class FileDeleteLoadedState extends Store.AbstractIdealState<HomeOwnerProfile> {}

export class ActivateUserAccountLoadingState extends Store.AbstractLoadingState<any> {}
export class ActivateUserAccountLoadingErrorState extends Store.AbstractErrorState<any> {}
export class ActivateUserAccountLoadedState extends Store.AbstractIdealState<any> {}


export class listedPropertiesLoadingState extends Store.AbstractLoadingState<any> {}
export class listedPropertiesLoadingErrorState extends Store.AbstractErrorState<any> {}
export class listedPropertiesLoadedState extends Store.AbstractIdealState<any> {}
