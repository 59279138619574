import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PagesModule } from "./pages/pages.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoggerModule } from "ngx-logger";
import { SessionService } from "./core/session/session.service";
import { sessionProviderFactory } from "./core/session/session.module";
import { InjectorResolver } from "./core/injector/injector.service";

import { NgxPermissionsModule } from "ngx-permissions";
import { SidenavModule } from "./shared/sidenav/sidenav.module";
import { MaterialModule } from "./shared/material/material-module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LayoutModule } from "./pages/layout/layout.module";
import { JoyrideModule } from "ngx-joyride";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { HttpErrorInterceptor } from "./core/interceptors/http-error.interceptor";
import { BlockUIModule } from "ng-block-ui";

import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { facebookAppInitializer } from "./shared/facebook/facebook.intializer";
import { FacebookAuthenticationService } from "./shared/facebook/facebook.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    PagesModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    LoggerModule.forRoot(null),
    BlockUIModule.forRoot(),
    MaterialModule,
    // SidenavModule,
    FlexLayoutModule,
    LayoutModule,
    JoyrideModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule
  ],
  providers: [
    SessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: sessionProviderFactory,
      deps: [InjectorResolver, SessionService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: facebookAppInitializer, multi: true, deps: [FacebookAuthenticationService] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
