import { Store } from 'src/app/core/store/store';
import { SignupComponent } from '../signup.component';
import { SignupPayload, signupData, LocationDataResponse, GetInviteResponse, VerifyFirmResponse, ForwardInviteResponse } from './signup.model';

export class SignupLoadingState extends Store.AbstractLoadingState<signupData> { }
export class SignupLoadingErrorState extends Store.AbstractErrorState<signupData> { }
export class SignupLoadedState extends Store.AbstractIdealState<signupData> { }

export class EmailLoadingState extends Store.AbstractLoadingState<SignupComponent> { }
export class EmailLoadingErrorState extends Store.AbstractErrorState<SignupComponent> { }
export class EmailLoadedState extends Store.AbstractIdealState<SignupComponent> { }

export class CitiesLoadingState extends Store.AbstractLoadingState<LocationDataResponse> { }
export class CitiesLoadingErrorState extends Store.AbstractErrorState<LocationDataResponse> { }
export class CitiesLoadedState extends Store.AbstractIdealState<LocationDataResponse> { }


export class StatesLoadingState extends Store.AbstractLoadingState<LocationDataResponse> { }
export class StatesLoadingErrorState extends Store.AbstractErrorState<LocationDataResponse> { }
export class StatesLoadedState extends Store.AbstractIdealState<LocationDataResponse> { }

export class ZipCodeLoadingState extends Store.AbstractLoadingState<LocationDataResponse> { }
export class ZipCodeLoadingErrorState extends Store.AbstractErrorState<LocationDataResponse> { }
export class ZipCodeLoadedState extends Store.AbstractIdealState<LocationDataResponse> { }

export class AccountLoadingState extends Store.AbstractLoadingState<SignupPayload> { }
export class AccountLoadingErrorState extends Store.AbstractErrorState<SignupPayload> { }
export class AccountLoadedState extends Store.AbstractIdealState<SignupPayload> { }

export class GetInviteLoadingState extends Store.AbstractLoadingState<GetInviteResponse> { }
export class GetInviteLoadingErrorState extends Store.AbstractErrorState<GetInviteResponse> { }
export class GetInviteLoadedState extends Store.AbstractIdealState<GetInviteResponse> { }

export class VerifyFirmLoadingState extends Store.AbstractLoadingState<VerifyFirmResponse> { }
export class VerifyFirmLoadingErrorState extends Store.AbstractErrorState<VerifyFirmResponse> { }
export class VerifyFirmLoadedState extends Store.AbstractIdealState<VerifyFirmResponse> { }

export class ForwardInviteLoadingState extends Store.AbstractLoadingState<ForwardInviteResponse> { }
export class ForwardInviteLoadingErrorState extends Store.AbstractErrorState<SignupComponent> { }
export class ForwardInviteLoadedState extends Store.AbstractIdealState<ForwardInviteResponse> { }

export class BusinessLoadingState extends Store.AbstractLoadingState<SignupComponent> { }
export class BusinessLoadingErrorState extends Store.AbstractErrorState<SignupComponent> { }
export class BusinessLoadedState extends Store.AbstractIdealState<SignupComponent> { }