import { Store } from 'src/app/core/store/store';
import { CommonResponseModel } from 'src/app/utility/common.model';
import { BusinessAccountComponent } from '../business-account.component';

export class BusinessAccountLoadingState extends Store.AbstractLoadingState<BusinessAccountComponent> { }
export class BusinessAccountLoadingErrorState extends Store.AbstractErrorState<BusinessAccountComponent> { }
export class BusinessAccountLoadedState extends Store.AbstractIdealState<BusinessAccountComponent> { }

export class UpdateBusinessAccountLoadingState extends Store.AbstractLoadingState<CommonResponseModel> { }
export class UpdateBusinessAccountLoadingErrorState extends Store.AbstractErrorState<CommonResponseModel> { }
export class UpdateBusinessAccountLoadedState extends Store.AbstractIdealState<CommonResponseModel> { }

export class FileUploadLoadingState extends Store.AbstractLoadingState<BusinessAccountComponent> { }
export class FileUploadLoadingErrorState extends Store.AbstractErrorState<BusinessAccountComponent> { }
export class FileUploadLoadedState extends Store.AbstractIdealState<BusinessAccountComponent> { }

export class FileDeleteLoadingState extends Store.AbstractLoadingState<BusinessAccountComponent> { }
export class FileDeleteLoadingErrorState extends Store.AbstractErrorState<BusinessAccountComponent> { }
export class FileDeleteLoadedState extends Store.AbstractIdealState<BusinessAccountComponent> { }

export class UploadDocumentFileLoadingState extends Store.AbstractLoadingState<any> { }
export class UploadDocumentFileLoadingErrorState extends Store.AbstractErrorState<any> { }
export class UploadDocumentFileLoadedState extends Store.AbstractIdealState<any> { }

export class ViewDocumentsLoadingState extends Store.AbstractLoadingState<any> { }
export class ViewDocumentsLoadingErrorState extends Store.AbstractErrorState<any> { }
export class ViewDocumentsLoadedState extends Store.AbstractIdealState<any> { }
