<router-outlet></router-outlet>
<!-- <div fxFlex="100" fxLayout="row" fxLayoutAlign="start" class="h-100-vh">
    <div fxFlex="{{session.isActive()? '20':'0'}}">
        <app-sidenav></app-sidenav>
    </div>
    <div fxFlex="{{session.isActive()? '80':'100'}}">
        <router-outlet></router-outlet>
    </div>
</div> -->
<div *ngIf="fbService.fbLoginLoading" class="common-spinner-container">
    <span class="common-spinner">
        <mat-progress-spinner class="btn-loader" mode="indeterminate" [diameter]="40">
        </mat-progress-spinner>
    </span>
</div>
<div class="better-experience-msg" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!visited">
    <div class="ph-10">For better experience please switch to the app</div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-flat-button class="btn-blue" (click)="mixPanelDownloadEvent()">

            <ng-container *ngIf="deepLinkDetails?.role == 'pro' || showProLink">
                <a *ngIf="isAndroid && !deepLinkDetails?.code"
                    href="https://play.google.com/store/apps/details?id=com.allpros.pro" target="blank"
                    style="padding:0.1rem">
                    Download</a>

                <a *ngIf="isIOS && !deepLinkDetails?.code"
                    href="https://apps.apple.com/us/app/allpros-pros/id1604401436" target="blank"
                    style="padding:0.1rem">
                    Download</a>

                <a *ngIf="deepLinkDetails?.code" [routerLink]="['/pro-action-detector', deepLinkDetails?.code]"
                    target="blank" style="padding:0.1rem">
                    Download</a>
            </ng-container>

            <ng-container *ngIf="deepLinkDetails?.role == 'home-owner' || showHomeownerLink">
                <a *ngIf="isAndroid && !deepLinkDetails?.code"
                    href="https://play.google.com/store/apps/details?id=com.allpros.homeowner" target="blank"
                    style="padding:0.1rem">
                    Download</a>

                <a *ngIf="isIOS && !deepLinkDetails?.code"
                    href="https://apps.apple.com/us/app/allpros-find-local-home-pros/id1604402378" target="blank"
                    style="padding:0.1rem">
                    Download</a>

                <a *ngIf="deepLinkDetails?.code"
                    [routerLink]="['/home-owner/homeowner-action-detector', deepLinkDetails.code]" target="blank"
                    style="padding:0.1rem">
                    Download</a>
            </ng-container>

        </button>

        <mat-icon (click)="closePopup()" class="ph-10" aria-hidden="false" aria-label="Example home icon">close
        </mat-icon>

    </div>

</div>