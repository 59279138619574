import { environment } from "./../../../../../../environments/environment";
import { CreateRequestComponent } from "./../create-request.component";
import { payload } from "./../../add-edit-address/service/add-edit-address.model";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import {
  chooseProLoadedState,
  chooseProLoadingErrorState,
  chooseProLoadingState,
  createRequestLoadedState,
  createRequestLoadingErrorState,
  createRequestLoadingState,
  requestCompletionLoadedState,
  requestCompletionLoadingErrorState,
  requestCompletionLoadingState,
  serviceCategoriesByFirmLoadedState,
  serviceCategoriesByFirmLoadingErrorState,
  serviceCategoriesByFirmLoadingState,
  serviceCategoriesLoadedState,
  serviceCategoriesLoadingErrorState,
  serviceCategoriesLoadingState,
} from "./create-request-service.state";
import {
  requestStatusLoadedState,
  requestStatusLoadingErrorState,
} from "src/app/pages/services/service/services.state";

@Injectable({
  providedIn: "root",
})
export class CreateRequestServiceService extends Store.AbstractService {
  newAddressCreated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  $newAddressCreated: Observable<boolean> =
    this.newAddressCreated.asObservable();

  holdCreateRequestComponent: CreateRequestComponent;
  // Get all service categories of firm...
  getAllServiceCategories(payload): any {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new serviceCategoriesLoadingState());
    }, 0);

    const path = environment.api.settings.serviceCategory.list.endpoint;

    me.controller
      .post(path, payload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new serviceCategoriesLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new serviceCategoriesLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  // get referral based on payload & type of service...
  getReferral(payload, serviceType): any {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new createRequestLoadingState());
    }, 0);

    const path = me.controller.replaceVariables(
      environment.api.homeOwner.createServiceRequest.endpoint,
      { serviceType: "askAllpros" }
    );

    me.controller
      .post(path, payload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new createRequestLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new createRequestLoadingErrorState(e));
          output.complete();
        }
      );
    return output;
  }

  loadProData(queryString: string, firmId?: string, selectedCategory?: string) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new chooseProLoadingState());
    }, 0);
    let path = "";
    if (firmId) {
      path = me.controller.replaceVariables(
        environment.api.homeOwner.chooseProWithFirm.endpoint,
        { firmId: firmId, queryString: queryString, category: selectedCategory }
      );
    } else {
      path = me.controller.replaceVariables(
        environment.api.homeOwner.choosePro.endpoint,
        { queryString: queryString, category: selectedCategory }
      );
    }

    me.controller
      .post(path, null, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new chooseProLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new chooseProLoadingErrorState(e));
          output.complete();
        }
      );
    return output;
  }

  //Service categories by firm...
  getAllServiceCategoriesByFirm(firmId) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new serviceCategoriesByFirmLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.settings.serviceCategory.update.endpoint,
      { firmID: firmId }
    );
    me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new serviceCategoriesByFirmLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new serviceCategoriesByFirmLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }
  getRequestCompletion() {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new requestCompletionLoadingState());
    }, 0);
    const path = environment.api.homeOwner.requestCompletion.endpoint;
    me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new requestCompletionLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new requestCompletionLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }
}
