import { Filter } from './../../firm-info/service/firm-info.model';
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
import { MyNotificationPayload, MyUpdatedNotificationPayload, NotificationData } from "./my-notification.model";
import { MyNotificationLoadedState, MyNotificationLoadingErrorState, MyNotificationLoadingState, MyUpdateNotificationLoadedState, MyUpdateNotificationLoadingErrorState, MyUpdateNotificationLoadingState } from "./my-notification.state";

@Injectable({ providedIn: 'root' })
export class MyNotificationService extends Store.AbstractService {

  output: Subject<any> = new ReplaySubject<any>(1, 2000);

  notificationData: Observable<any>;

  notificationTimer: any //NodeJS.Timer // TODO: Use NodeJs.Timer;

  constructor() {
    super()
    this.notificationData = this.output.asObservable();

  }

  public stopTimer() {
    const me = this;
    if (me.notificationTimer) {
      clearTimeout(me.notificationTimer);
    }
  }

  getMyNotificationsList(notificationPayload: MyNotificationPayload): Observable<Store.State> {
    const me = this;

    const fromDate = moment(new Date().setDate(new Date().getDate() - 29)).startOf('day').format("YYYY-MM-DD HH:mm:SS");
    const toDate = moment(new Date()).endOf('day').format("YYYY-MM-DD HH:mm:SS")

    const filter = { ...notificationPayload.filter };
    filter.fromDate = notificationPayload?.filter?.fromDate ? notificationPayload?.filter?.fromDate : fromDate;
    filter.toDate = notificationPayload?.filter?.toDate ? notificationPayload?.filter?.toDate : toDate;

    notificationPayload.filter = filter;
    const path = environment.api.notification.getNotification.endpoint

    me.stopTimer();

    me.notificationTimer = setTimeout(() => {
      me.controller.post(path, notificationPayload, undefined, { Authorization: true }).subscribe(
        (data: any) => {

          me.output.next(new MyNotificationLoadedState(data));

          me.getMyNotificationsList(notificationPayload);
        },
        (e: any) => {
          me.output.error(new MyNotificationLoadingErrorState(e));


          me.logger.error('error', e);
        }
      );

    }, 30000)

    return me.output;
  }

  customGetMyNotificationsList(notificationPayload: MyNotificationPayload): Observable<Store.State> {
    const me = this;
    const fromDate = moment(new Date().setDate(new Date().getDate() - 29)).startOf('day').format("YYYY-MM-DD HH:mm:SS");
    const toDate = moment(new Date()).endOf('day').format("YYYY-MM-DD HH:mm:SS")

    const filter = { ...notificationPayload.filter };
    filter.fromDate = notificationPayload?.filter?.fromDate ? notificationPayload?.filter?.fromDate : fromDate;
    filter.toDate = notificationPayload?.filter?.toDate ? notificationPayload?.filter?.toDate : toDate;

    notificationPayload.filter = filter;

    const path = environment.api.notification.getNotification.endpoint

    me.controller.post(path, notificationPayload, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        me.output.next(new MyNotificationLoadedState(data));

        me.getMyNotificationsList(notificationPayload);
      },
      (e: any) => {
        me.output.error(new MyNotificationLoadingErrorState(e));
        // me.output.complete();

        me.logger.error('error', e);
      }
    );

    return me.output;
  }

  updateMyNotificationsList(Updatepayload: MyUpdatedNotificationPayload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new MyUpdateNotificationLoadingState());
    }, 0);

    const path = environment.api.notification.updateNotification.endpoint

    me.controller.post(path, Updatepayload, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new MyUpdateNotificationLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new MyUpdateNotificationLoadingErrorState(e));
        output.complete();

        me.logger.error('error', e);
      }
    );

    return output;
  }
}