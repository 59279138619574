import { Component, Input, ViewEncapsulation } from "@angular/core";
import { EllipsisPipe } from "../pipes/ellipsis/ellipsis.pipe";

@Component({
  selector: "app-data-handler",
  template:
    '<span  matTooltipClass="{{tooltipClass}}" matTooltipPosition="{{tooltipPosition}}" matTooltip="{{tooltip && content && size && content.length > size ? content : null}}">{{content ? (content | ellipsis: size) : defaultContent}}</span>',
  styleUrls: ["./data-handler.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [EllipsisPipe],
})
export class DataHandlerComponent {
  @Input() public size;
  @Input() public tooltipClass;
  @Input() public tooltip = true;
  @Input() public tooltipPosition = "above";
  @Input() public defaultContent = "-";
  @Input() public content = "";
  @Input() public width;
}
