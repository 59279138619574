import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { NgxMaskModule } from "ngx-mask";
import { NgxPermissionsGuard, NgxPermissionsModule } from "ngx-permissions";
import { SessionGuard } from "../core/session/session.guard";
import { FirmInfoComponent } from "./firm-info/firm-info.component";
import { HomeOwnerComponent } from "./home-owner/home-owner.component";
import { MyClientComponent } from "./my-client/my-client.component";

const modules = [FlexLayoutModule];

const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },



  // Boot
  {
    path: "loading",
    loadChildren: () =>
      import("./loading/loading.module").then((m) => m.LoadingModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "logout",
    loadChildren: () =>
      import("./logout/logout.module").then((m) => m.LogoutModule),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignupModule),
  },
  {
    path: "home-owner/login",
    loadChildren: () =>
      import("./home-owner/home-owner-login/home-owner-login.module").then(
        (m) => m.HomeOwnerLoginModule
      ),
    canActivate: [],
  },
  {
    path: "home-owner/signup",
    loadChildren: () =>
      import("./home-owner/home-owner-signup/home-owner-signup.module").then(
        (m) => m.HomeOwnerSignupModule
      ),
    canActivate: [],
  },

  // DO NOT ADD ANYTHING ABOVE UNLESS U KNOW WHAT U R DOING

  // Authenticated
  {
    path: "",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  // {
  //   path: "account-activity",
  //   loadChildren: () =>
  //     import("./account-activities/account-activities.module").then(
  //       (m) => m.AccountActivitiesModule
  //     ),
  //   canActivate: [SessionGuard, NgxPermissionsGuard],
  //   data: {
  //     permissions: {
  //       only: ["CAN_ACCESS_ACCOUNT_ACTIVITY"],
  //     },
  //   },
  // },
  // {
  //   path: "services",
  //   loadChildren: () =>
  //     import("./services/services.module").then((m) => m.ServicesModule),
  //   canActivate: [SessionGuard],
  // },
  // {
  //   path: "my-team",
  //   loadChildren: () =>
  //     import("./my-team/my-team.module").then((m) => m.MyTeamModule),
  //   canActivate: [SessionGuard, NgxPermissionsGuard],
  //   data: {
  //     permissions: {
  //       only: ["CAN_ACCESS_MY_TEAM"],
  //     },
  //   },
  // },
  // {
  //   path: "network",
  //   loadChildren: () =>
  //     import("./network/network.module").then((m) => m.NetworkModule),
  //   canActivate: [SessionGuard],
  // },
  // {
  //   path: "my-client",
  //   loadChildren: () =>
  //     import("./my-client/my-client.module").then((m) => m.MyClientModule),
  //   canActivate: [SessionGuard],
  // },
  // {
  //   path: "notifications",
  //   loadChildren: () =>
  //     import("./notifications/notifications.module").then(
  //       (m) => m.NotificationsModule
  //     ),
  //   canActivate: [SessionGuard],
  // },
  // {
  //   path: "home-owner/notifications",
  //   loadChildren: () =>
  //     import("./notifications/notifications.module").then(
  //       (m) => m.NotificationsModule
  //     ),
  //   canActivate: [SessionGuard],
  // },


  // {
  //   path: 'profile',
  //   loadChildren: () => import('./settings/profile/profile.module').then((m) => m.ProfileModule),
  //   canActivate: [SessionGuard]
  // },
  // {
  //   path: "admin-account",
  //   loadChildren: () =>
  //     import("./settings/admin-account/admin-account.module").then(
  //       (m) => m.AdminAccountModule
  //     ),
  //   canActivate: [SessionGuard, NgxPermissionsGuard],
  //   data: {
  //     permissions: {
  //       only: ["CAN_ACCESS_ADMIN_ACCOUNT"],
  //     },
  //   },
  // },
  // {
  //   path: "billing-information",
  //   loadChildren: () =>
  //     import("./settings/billing-information/billing-information.module").then(
  //       (m) => m.BillingInformationModule
  //     ),
  //   canActivate: [SessionGuard, NgxPermissionsGuard],
  //   data: {
  //     permissions: {
  //       only: ["CAN_ACCESS_BILLING_INFORMATION"],
  //     },
  //   },
  // },
  // {
  //   path: "my-account",
  //   loadChildren: () =>
  //     import("./settings/my-account/my-account.module").then(
  //       (m) => m.MyAccountModule
  //     ),
  //   canActivate: [SessionGuard, NgxPermissionsGuard],
  //   data: {
  //     permissions: {
  //       only: ["CAN_ACCESS_MY_ACCOUNT"],
  //     },
  //   },
  // },
  // {
  //   path: "firm-info",
  //   loadChildren: () =>
  //     import("./firm-info/firm-info.module").then((m) => m.FirmInfoModule),
  //   canActivate: [SessionGuard],
  // },
  {
    path: "firm",
    loadChildren: () =>
      import("./firm-info/unauth-firm-info/unauth-firm-info.module").then(
        (m) => m.UnauthFirmInfoModule
      ),
  },
  {
    path: "home-owner",
    loadChildren: () =>
      import("./home-owner/home-owner.module").then((m) => m.HomeOwnerModule),
    canActivate: [SessionGuard]
  },
  {
    path: "pro",
    loadChildren: () =>
      import("./layout/main-content-with-sidebar/maincontentwithsidebar.module").then((m) => m.MaincontentwithsidbarModule),
    canActivate: [SessionGuard]
  },

  // {
  //   path: "my-pros",
  //   loadChildren: () =>
  //     import("./home-owner/my-pros/my-pros.module").then((m) => m.MyProsModule),
  // },

  // {
  //   path: "home-owner/login",
  //   loadChildren: () =>
  //     import("./home-owner/home-owner-login/home-owner-login.module").then(
  //       (m) => m.HomeOwnerLoginModule
  //     ),
  // },

  {
    path: "payment",
    loadChildren: () =>
      import("./payment/payment.module").then(
        (m) => m.PaymentModule
      ),
    canActivate: [],
  },

  {
    path: "notify-service",
    loadChildren: () =>
      import("./notify-service/notify-service.module").then(
        (m) => m.NotifyServiceModule
      ),
    canActivate: [],
  },

  {
    path: "pro-action-detector/:id",
    loadChildren: () =>
      import("./pro-action-detector/pro-action-detector.module").then(
        (m) => m.ProActionDetectorModule
      ),
    canActivate: [],
  },
  {
    path: "home-owner/homeowner-action-detector/:id",
    loadChildren: () =>
      import("./home-owner/home-owner-detector/home-owner-detector.module").then(
        (m) => m.HomeOwnerDetectorModule
      ),
    canActivate: [],
  },

  // {
  //   path: "my-pros",
  //   loadChildren: () =>
  //     import("./home-owner/my-pros/my-pros.module").then((m) => m.MyProsModule),
  // },

  // {
  //   path: 'request/list/:type',
  //   loadChildren: () =>
  //     import('./requests/requests.module').then((m) => m.RequestsModule),
  //   canActivate: [NgxPermissionsGuard],
  //   data: {
  //     permissions: {
  //       only: ['COM_CTL_VIEW_REQ'],
  //     },
  //   },
  // }

  // DO ADD ANYTHING BELOW THIS LINE
  // {
  //   path: '**',
  //   redirectTo: '/error?e=ROUTE_NOT_FOUND',
  // },
];

@NgModule({
  declarations: [],
  imports: [
    modules,
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false }), // UseHash should be false, for msal to work
    NgxPermissionsModule.forChild(),
    NgxMaskModule.forRoot(),
  ],
  providers: [NgxPermissionsGuard, SessionGuard],
  exports: [RouterModule],
})
export class PagesModule {
  constructor(private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {
    this.iconRegistry.addSvgIcon(
      "facebook",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/facebook.svg"
      )
    );
  }
}
