<div class="container">
    <div fxLayout="row" [fxLayoutAlign]=" orignalInviter ? 'space-between center' : 'end center'">
        <div *ngIf="orignalInviter" class="ml-20" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
            <span class="title">HomeAssist </span>
            <span class="headingtitle">
                brought to you by</span>
            <span class="title">{{homeAssistPreferredProName}}</span>
            <span *ngIf="homeAssistPreferredProLogo">
                <img [src]="homeAssistPreferredProLogo" class="brad-50" height="30" width="30">
            </span>
        </div>
        <div class="new-service-button" fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button class="mat-buttton ph-20" (click)="openDialog('askAllpros')">
                new service
            </button>
        </div>
    </div>
</div>