import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(value: any, source?: string, filtertext?: any): any {
    if (!value) return null;
    if (!filtertext) return value;
    filtertext = filtertext.toLowerCase();
    if (source === "PRO") {
      return value.filter(function (item: any) {
        return item.request.serviceCategory.name
          .toLowerCase()
          .includes(filtertext);
      });
    } else if (source === "HO") {
      return value.filter(function (item: any) {
        return item.serviceCategory.name.toLowerCase().includes(filtertext);
      });
    } else {
      return false;
    }
  }
}
