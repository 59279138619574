import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
import { getAdditionalDataLoadedState, getAdditionalDataLoadingErrorState, getAdditionalDataLoadingState } from "./get-additional-data.state";


@Injectable({
    providedIn: "root",
})
export class GetAdditionalDataService extends Store.AbstractService {


    updateUserDetails(reqPayload: any, userid: string): Observable<Store.State> {
        const me = this;
        const output = new Subject<Store.State>();
        setTimeout(() => {
            output.next(new getAdditionalDataLoadingState());
        }, 0);
        const path = me.controller.replaceVariables(
            environment.api.homeOwner.updateHomeOwner.endpoint,
            { userid: userid }
        );

        me.controller
            .put(path, reqPayload, undefined, { Authorization: true })
            .subscribe(
                (data: any) => {
                    output.next(new getAdditionalDataLoadedState(data));
                    output.complete();
                },
                (e: any) => {
                    output.error(new getAdditionalDataLoadingErrorState(e));
                    output.complete();

                    me.logger.error("error", e);
                }
            );

        return output;
    }

}