import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from "src/app/shared/material/material-module";
import { SidenavComponent } from "./sidenav.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MyTeamComponent } from "src/app/pages/my-team/my-team.component";
import { NetworkComponent } from "src/app/pages/network/network.component";
import { SettingsComponent } from "src/app/pages/settings/settings.component";
import { AccountActivitiesComponent } from "src/app/pages/account-activities/account-activities.component";
import { ServicesComponent } from "src/app/pages/services/services.component";
import { NotificationsComponent } from "src/app/pages/notifications/notifications.component";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxPermissionsModule } from "ngx-permissions";
import { JoyrideModule } from "ngx-joyride";
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DataHandlerModule } from '../data-handler/data-handler.module';

const component = [SidenavComponent];

const imports = [
  CommonModule,
  RouterModule,
  FormsModule,
  MaterialModule,
  FlexLayoutModule,
  PipesModule,
  NgxPermissionsModule.forChild(),
  JoyrideModule.forChild(),
  FilterPipeModule,
  ReactiveFormsModule,
  NgxMatSelectSearchModule,
  DataHandlerModule
];

// export const routes =  [
//   { path: '/account-activity', component: AccountActivitiesComponent, label: 'Account Activity' },
//   { path: '/services', component: ServicesComponent, label: 'Services' },
//   { path: '/my-team', component: MyTeamComponent, label: 'My Team' },
//   { path: '/network', component: NetworkComponent, label: 'Network' },
//   { path: '/notifications', component: NotificationsComponent, label: 'Notifications' },
//   { path: '/settings', component: SettingsComponent, label: 'Settings' },
//  ];
@NgModule({
  declarations: [component],
  imports: imports,
  exports: [component],
})
export class SidenavModule { }
