import { Store } from "src/app/core/store/store";
export class listedPropertiesLoadingState extends Store.AbstractLoadingState<any> {}
export class listedPropertiesLoadingErrorState extends Store.AbstractErrorState<Store.State> {}
export class listedPropertiesLoadedState extends Store.AbstractIdealState<any> {}

export class assignPropertiesLoadingState extends Store.AbstractLoadingState<any> {}
export class assignPropertiesLoadingErrorState extends Store.AbstractErrorState<Store.State> {}
export class assignPropertiesLoadedState extends Store.AbstractIdealState<any> {}

export class searchAccountLoadingState extends Store.AbstractLoadingState<any> {}
export class searchAccountLoadingErrorState extends Store.AbstractErrorState<Store.State> {}
export class searchAccountLoadedState extends Store.AbstractIdealState<any> {}
