<div class="change-password-container">
    <div mat-dialog-title class="title">Change Your Password</div>
    <form [formGroup]="changePasswordForm">
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
                <div fxFlex="100">
                    <div fxLayout="column" fxLayoutAlign="start">
                        <div fxLayout="row" fxLayoutAlign="start center" class="w-100-p" fxLayout.xs="column"
                            fxLayout.sm="column" fxLayoutAlign.xs="start" fxLayoutAlign.sm="start"
                            fxLayoutAlign="center center">
                            <mat-label class="form-label" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">Current Password
                            </mat-label>
                            <mat-form-field appearance="outline">
                                <input type="password" matInput placeholder="" formControlName="current"
                                    (keydown.space)="$event.preventDefault();" />
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="20" fxFlex.xs="1%"></div>
                            <mat-error>
                                <span *ngIf="
                                changePasswordForm.controls['current'].errors?.required &&
                                changePasswordForm.controls['current'].touched
                                "><strong>Required.</strong>
                                </span>
                            </mat-error>
                        </div>

                    </div>
                    <div fxLayout="column" fxLayoutAlign="start">
                        <div fxLayout="row" fxLayoutAlign="start center" class="w-100-p mt-10" fxLayout.xs="column"
                            fxLayout.sm="column" fxLayoutAlign.xs="start" fxLayoutAlign.sm="start"
                            fxLayoutAlign="center center">
                            <mat-label class="form-label" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">New Password
                            </mat-label>
                            <div fxFlex="80">
                                <mat-form-field appearance="outline">
                                    <input type="password" matInput placeholder="" formControlName="new"
                                        (keydown.space)="$event.preventDefault();" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="20" fxFlex.xs="1%"></div>

                            <mat-error>
                                <span *ngIf="
                        changePasswordForm.controls['new'].errors?.required &&
                        changePasswordForm.controls['new'].touched
                          "><strong>Required.</strong>
                                </span>
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="20" fxFlex.xs="1%"></div>

                            <mat-error>
                                <span *ngIf="
                        (changePasswordForm.controls['new'].errors?.pattern &&
                        changePasswordForm.controls['new'].touched) && !changePasswordForm.controls['new'].errors?.required
                          "><strong>Password should be atleast 8 characters
                                        long and should contain one number,one Uppercase character, one Lowercase
                                        character and one
                                        special
                                        character.</strong>
                                </span>
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="start">
                        <div fxLayout="row" fxLayoutAlign="start center" class="w-100-p mt-10" fxLayout.xs="column"
                            fxLayout.sm="column" fxLayoutAlign.xs="start" fxLayoutAlign.sm="start"
                            fxLayoutAlign="center center">
                            <mat-label class="form-label" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">Confirm New
                                Password</mat-label>
                            <mat-form-field appearance="outline">
                                <input type="password" matInput placeholder=" " formControlName="confirm"
                                    (keydown.space)="$event.preventDefault();" />
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="20" fxFlex.xs="1%"></div>

                            <mat-error>
                                <span *ngIf="
                            changePasswordForm.controls['confirm'].errors?.required &&
                            changePasswordForm.controls['confirm'].touched
                            "><strong>Required.</strong>
                                </span>
                            </mat-error>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="20" fxFlex.xs="1%"></div>
                            <mat-error>
                                <span *ngIf="
                                (changePasswordForm.controls['confirm'].errors?.mustMatch &&
                                changePasswordForm.controls['confirm'].touched) && !changePasswordForm.controls['confirm'].errors?.required
                                "><strong>passwords do not match.</strong>
                                </span>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" *ngIf="CheckEmailConsent == false" fxLayoutAlign="center center">
            <div class="aggrement-text mt-32 mb-32" formGroupName="meta">
                <div class="mt-12 privacy-policy" style="display: flex;
                 align-items: center;">
                    <mat-checkbox formControlName="terms">
                    </mat-checkbox>
                    <p class="hyper-link">I have reviewed and I agree to the <a
                            href="https://allpros-documents.s3.amazonaws.com/service_provider_agreement.pdf"
                            target="_blank"> AllPros Service Provider<br />
                            Agreement</a>, <a href="https://allpros-documents.s3.amazonaws.com/general_terms_of_use.pdf"
                            target="_blank">General Terms of Use</a> and <a
                            href="https://allpros-documents.s3.amazonaws.com/privacy_agreement.pdf"
                            target="_blank">Privacy Policy</a>.</p>
                </div>
            </div>
        </div>
        <div class="button-row pt-14" fxLayout="row" fxLayoutAlign="center center">
            <button *ngIf="firstUserLoggedIn == false" type="button" mat-flat-button color="primary"
                (click)="closeDialog();">
                cancel
            </button>
            <button mat-flat-button color="primary" [disabled]="changePasswordForm.invalid"
                (click)="changePassword(changePasswordForm.value)">
                <span fxLayout="row" fxLayoutAlign="center center">
                    <span>change password</span>
                    <mat-progress-spinner class="ml-12" *ngIf="loading" mode="indeterminate" [diameter]="20">
                    </mat-progress-spinner>
                </span>
            </button>
        </div>
    </form>
</div>