import { Store } from "src/app/core/store/store";
import { SourcesResponse } from "./home-owner-signup.model";

export class homeOwnerRegisterLoadingState extends Store.AbstractLoadingState<any> { }
export class homeOwnerRegisterLoadingErrorState extends Store.AbstractErrorState<any> { }
export class homeOwnerRegisterLoadedState extends Store.AbstractIdealState<any> { }

export class SourcesLoadingState extends Store.AbstractLoadingState<SourcesResponse> { }
export class SourcesLoadingErrorState extends Store.AbstractErrorState<SourcesResponse> { }
export class SourcesLoadedState extends Store.AbstractIdealState<SourcesResponse> { }
