import { Store } from "src/app/core/store/store";
import { MemberInfo } from "./comet-chat.model";

export class MemberListLoadingState extends Store.AbstractLoadingState<any> { }
export class MemberListErrorState extends Store.AbstractErrorState<any> { }
export class MemberListLoadedState extends Store.AbstractIdealState<any> { }


export class AddGroupMemberLoadingState extends Store.AbstractLoadingState<any> { }
export class AddGroupMemberErrorState extends Store.AbstractErrorState<any> { }
export class AddGroupMemberLoadedState extends Store.AbstractIdealState<any> { }

export class UploadFileCometChatLoadingState extends Store.AbstractLoadingState<any> { }
export class UploadFileCometChatErrorState extends Store.AbstractErrorState<any> { }
export class UploadFileCometChatLoadedState extends Store.AbstractIdealState<any> { }

export class GetUploadedFileCometChatLoadingState extends Store.AbstractLoadingState<any> { }
export class GetUploadedFileCometChatErrorState extends Store.AbstractErrorState<any> { }
export class GetUploadedFileCometChatLoadedState extends Store.AbstractIdealState<any> { }