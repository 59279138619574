
import { Injectable } from '@angular/core';
import { Store } from 'src/app/core/store/store';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ServiceCoveragePayload, UpdateServiceCoveragePayload } from './service-coverage.model';
import { FinishGuidedJourneyLoadedState, FinishGuidedJourneyLoadingErrorState, FinishGuidedJourneyLoadingState, ServiceCoverageLoadedState, ServiceCoverageLoadingErrorState, ServiceCoverageLoadingState, UpdateServiceCoverageLoadedState, UpdateServiceCoverageLoadingErrorState, UpdateServiceCoverageLoadingState } from './service-coverage.state';

@Injectable({ providedIn: 'root' })
export class ServiceCoverageService extends Store.AbstractService {

  getServiceCoverageDetails(serviceCoveragePayload: ServiceCoveragePayload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new ServiceCoverageLoadingState());
    }, 0);

    const path = environment.api.settings.serviceCoverage.getServiceCoverage.endpoint;

    me.controller.post(path, serviceCoveragePayload, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new ServiceCoverageLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new ServiceCoverageLoadingErrorState(e));
        output.complete();

        me.logger.error('error', e);
      }
    );

    return output;
  }

  // POST Method: Update service coverage details based on firm id
  updateServiceCoverageDetails(reqBody, firmId): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new UpdateServiceCoverageLoadingState());
    }, 0);


    const path = environment.api.settings.serviceCoverage.updateServiceCoverage.endpoint+'/'+firmId;

    me.controller.post(path, reqBody, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new UpdateServiceCoverageLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new UpdateServiceCoverageLoadingErrorState(e));
        output.complete();

        me.logger.error('error', e);
      }
    );

    return output;
  }

// User guide journey finished...
  finishGuidedJourney(reqBody, id): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new FinishGuidedJourneyLoadingState());
    }, 0);

    const path = me.controller.replaceVariables(
      environment.api.account.updateAccount.endpoint,
      { id: id },
    );

    me.controller.post(path, reqBody, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new FinishGuidedJourneyLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new FinishGuidedJourneyLoadingErrorState(e));
        output.complete();


        me.logger.error('error', e);
      }
    );

    return output;
  }


}