import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { from, of, EMPTY, Observable, Subject } from "rxjs";
import { concatMap } from "rxjs/operators";
import { SessionService } from "src/app/core/session/session.service";
import { SignupLoginLoadedState } from "src/app/core/session/session.state";
import { Store } from "src/app/core/store/store";
import { HomeOwnerSignupService } from "src/app/pages/home-owner/home-owner-signup/service/home-owner-signup.service";
import {
  homeOwnerRegisterLoadedState,
  homeOwnerRegisterLoadingErrorState,
  homeOwnerRegisterLoadingState,
} from "src/app/pages/home-owner/home-owner-signup/service/home-owner-signup.state";

import { environment } from "src/environments/environment";
import { CommonDialogComponent } from "../common-dialog/common-dialog.component";
import { CustomUtilityService } from "../common/services/custom-utility.service";
import { SnackBarService } from "../common/services/snack-bar.service";
import { CLICK_SIGN_IN_USING_FACEBOOK, HOMEOWNER_LOG_IN, HOMEOWNER_SIGN_UP } from "../mixpanel/mixpanel.enums";
import { MixpanelService } from "../mixpanel/mixpanel.service";
import {
  ApiResponse,
  FacbookAuthenticationData,
  FacebookAuthenticationData,
  FacebookRequest,
  FacebookSignupPayload,
  FacebookUserData,
} from "./facebook.model";

declare const FB: any;

@Injectable({ providedIn: "root" })
export class FacebookAuthenticationService {
  facebookAuthObj: Partial<FacbookAuthenticationData> = null;
  facebookUserInfo: Partial<FacebookUserData> = null;
  fbLoginLoading: boolean = false;
  fbRequest: FacebookRequest = {
    scope: "email",
    auth_type: "rerequest",
    perms: "user_mobile_phone",
    fields: "email,name,first_name,last_name,middle_name,gender",
  };
  account: any;
  ipAddress: any;

  constructor(
    private sessionService: SessionService,
    private homeOwnerSignupService: HomeOwnerSignupService,
    private dialog: MatDialog,
    private mixpanelService: MixpanelService,
    private customUtilityService: CustomUtilityService,
    private snackbar: SnackBarService,
  ) { }

  // login with facebook then authenticate with the API to get a JWT auth token
  public login(): void {
    const me = this;
    // login button click event.
    me.mixPanelFacebookLoginButtonClick();
    this.facebookLogin().subscribe(
      (facebookAuthObj: Partial<FacbookAuthenticationData>) => {
        me.facebookAuthObj = facebookAuthObj;
        me.getIpAdressAndSignupMixpanelEvent();
        me.fbUserInfoWithAllprosValidation();
      },
      (err) => { }
    );
  }

  public fbUserInfoWithAllprosValidation() {
    const me = this;
    me.getFacebookUserInfo()
      .pipe(
        concatMap((fbUserInfo: Partial<FacebookUserData>) => {
          me.fbLoginLoading = true;
          me.facebookUserInfo = fbUserInfo;
          if (!me.facebookUserInfo.email) {
            me.fbLoginLoading = false;
            me.openNotificationDialog();
            return;
          }
          const signUpVersion = environment.applicationVersion;
          const signUpPlatform = me.getBrowserName();

          const socialSiteSignupPayload: FacebookSignupPayload = {
            email: me.facebookUserInfo.email,
            firstName: me.facebookUserInfo.first_name,
            lastName: me.facebookUserInfo.last_name,
            provider: "facebook",
            sn_user_id: me.facebookUserInfo.id,
            accountMeta: {
              signUpSource: "Facebook",
              signUpVersion,
              signUpPlatform,
              emailConsentProvided: true,
            },
            roles: [
              {
                code: "HOMEOWNER",
              },
            ],
          };
          return me.loginWithSocialToken(socialSiteSignupPayload);
        })
      )
      .subscribe(
        (loginData) => {

          if (loginData && loginData?.data?.appToken) {


            me.sessionService.signupLogin(loginData?.data?.appToken).subscribe(
              (state) => {
                if (state instanceof SignupLoginLoadedState) {
                  me.fbLoginLoading = false;
                  me.account = me.sessionService.account;

                  if (loginData && loginData.status && me.sessionService?.details?.mixPanelAnalyticsEnabled) {
                    me.mixpanelEvent(loginData.status);
                  }
                }
              },
              (err) => {
                me.fbLoginLoading = false;
                me.logout();
              }
            );
          }
        },
        (error) => {
          if (error.error.error.status) {
            me.snackbar.openSnackBar(error.error.error.message, 3000);
          }
          me.fbLoginLoading = false;
          me.logout();
        }
      );
  }

  // login with facebook and return observable with fb access token on success
  public facebookLogin(): Observable<Partial<FacbookAuthenticationData>> {
    const me = this;
    return from(
      new Promise<any>((resolve) =>
        FB.login(resolve, {
          scope: me.fbRequest.scope,
          auth_type: me.fbRequest.auth_type,
          perms: me.fbRequest.perms,
        })
      )
    ).pipe(
      concatMap(({ authResponse }) => {
        if (!authResponse) return EMPTY;
        return of(authResponse);
      })
    );
  }

  // get get a facebook user information.
  public getFacebookUserInfo(): Observable<Partial<FacebookUserData>> {
    const me = this;
    const path = environment.api.facebook.me.endpoint;

    return from(
      new Promise<any>((resolve) =>
        FB.api(path, { fields: me.fbRequest.fields }, (res) => {
          resolve(res);
        })
      )
    ).pipe(
      concatMap((fbUserInfo: Partial<FacebookUserData>) => {
        if (!fbUserInfo) return EMPTY;
        return of(fbUserInfo);
      })
    );
  }

  // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
  public logout(): void {
    const me = this;
    const path = environment.api.facebook.permissions.endpoint;
    FB.api(path, "delete", null, () =>
      FB.logout((response) => {
        me.facebookAuthObj = null;
        me.facebookUserInfo = null;
      })
    );
  }

  /**
   * * call the register facebook user api (if user present into DB then login else first register the user into the alllpros DB  and then login)
   * @param facebookSignupPayload 
   * @returns Observable<Partial<ApiResponse<FacebookAuthenticationData>>>
   */

  loginWithSocialToken(
    facebookSignupPayload: FacebookSignupPayload
  ): Observable<Partial<ApiResponse<FacebookAuthenticationData>>> {
    const me = this;
    const output = new Subject<
      Partial<ApiResponse<FacebookAuthenticationData>>
    >();
    me.homeOwnerSignupService.registerUser(facebookSignupPayload).subscribe(
      (state: Store.State) => {
        // if (state instanceof homeOwnerRegisterLoadingState) {}
        if (state instanceof homeOwnerRegisterLoadedState) {
          output.next(state.data);
          output.complete();
        }
      },
      (state: homeOwnerRegisterLoadingErrorState) => {
        output.error(state);
        output.complete();
        me.fbLoginLoading = false;
      }
    );
    return output;
  }
  /**
   * * this method can used for to get browser name
   * @returns void
   */
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  /**
   * * dialog for show notification of email is not linked with facebook account.
   */
  public openNotificationDialog(): void {
    const me = this;

    const dialogRef = me.dialog.open(CommonDialogComponent, {
      data: {
        dialogContent: {
          text: "We found there is no email id linked to your facebook account, kindly sign up using a valid email id.",
          primaryButtonLabel: "okay",
          secondaryButtonLable: "back",
        },
      },
    });

    dialogRef.afterClosed().subscribe((dialogResponse) => {
      me.logout();
    });
  }


  /**
   * *mixpanel facebook sign in and signup event on the basis of register API response.
   * @param registerApiAction 
   */
  private mixpanelEvent(registerApiAction: string): void {
    const me = this;
    const device = me.customUtilityService.checkDeviceInformation();
    const superProperties = {
      Email: me.account?.email,
      "User Type": "Homeowner",
      Platform: device,
      "App Version": environment.applicationVersion,
    };

    me.mixpanelService.setSuperProperties(superProperties);
    me.mixpanelService.identify(me.account?.id);

    let userProperties = {
      $first_name: me.account?.firstName,
      $last_name: me.account?.lastName,
      "User Type": "Homeowner",
      $email: me.account?.email,
      "How did you hear about us": "Facebook",
      "Provider": 'Facebook'
    };

    //Mixpanel Event Properties
    const eventProperties = {
      "First Name": me.account?.firstName,
      "Last Name": me.account?.lastName,
      "Phone Number": me.account.mobile,
      "User Type": "Homeowner",
      Email: userProperties.$email,
      "How did you hear about us": userProperties["How did you hear about us"],
      IP: me.ipAddress,
      "Provider": 'Facebook'
    };

    if (registerApiAction === 'LOGIN_SUCCESSFUL') {
      me.mixpanelService.sendEvent(HOMEOWNER_LOG_IN, eventProperties);
    } else {
      me.mixpanelService.setPeople(userProperties);
      me.mixpanelService.sendEvent(HOMEOWNER_SIGN_UP, eventProperties);
    }

  }

  /**
   * * to get IP address for mixpanel event purpose.
   */
  private getIpAdressAndSignupMixpanelEvent(): void {
    const me = this;
    me.customUtilityService.getIpAddress().subscribe(
      (data) => {
        if (data.type === "loaded") {
          me.ipAddress = data.ipData.ip;
        }
      },
      (error) => {

      }
    );
  }

  public mixPanelFacebookLoginButtonClick(): void {
    const me = this;
    if (me.sessionService?.details?.mixPanelAnalyticsEnabled) {
      const device = me.customUtilityService.checkDeviceInformation();
      const superProperties = {
        Platform: device,
        "App Version": environment.applicationVersion,
      };

      me.mixpanelService.setSuperProperties(superProperties);
      const eventProperties = {
        IP: me.ipAddress,
      };
      me.mixpanelService.sendEvent(CLICK_SIGN_IN_USING_FACEBOOK, eventProperties);
    }
  }


}
