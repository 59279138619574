import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
import { Dummy_Properties, LISTED_PROPERTIES } from "./listed-properties.data";
import {
  assignPropertiesLoadedState,
  assignPropertiesLoadingErrorState,
  assignPropertiesLoadingState,
  listedPropertiesLoadedState,
  listedPropertiesLoadingErrorState,
  listedPropertiesLoadingState,
  searchAccountLoadedState,
  searchAccountLoadingErrorState,
  searchAccountLoadingState,
} from "./listed-properties.state";

@Injectable({
  providedIn: "root",
})
export class ListedPropertiesService extends Store.AbstractService {
  getListedProperties(payload) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new listedPropertiesLoadingState());
    }, 0);
    // setTimeout(() => {
    //   output.next(new listedPropertiesLoadedState(Dummy_Properties))
    // }, 0);
    const path = environment.api.homeOwner.propertyManager.viewProperties.endpoint;
    me.controller.post(path, payload, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new listedPropertiesLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new listedPropertiesLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

  assignProperties(payload) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new assignPropertiesLoadingState());
    }, 0);
    // setTimeout(() => {
    //   output.next(new listedPropertiesLoadedState(Dummy_Properties))
    // }, 0);
    const path = environment.api.homeOwner.propertyManager.assignProperty.endpoint;
    me.controller.post(path, payload, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new assignPropertiesLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new assignPropertiesLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

  searchAccount(payload) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new searchAccountLoadingState());
    }, 0);
    // setTimeout(() => {
    //   output.next(new listedPropertiesLoadedState(Dummy_Properties))
    // }, 0);
    const path = environment.api.homeOwner.propertyManager.search.endpoint;
    me.controller.post(path, payload, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new searchAccountLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new searchAccountLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

}
