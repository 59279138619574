import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SessionService } from "src/app/core/session/session.service";
import { Store } from "src/app/core/store/store";
import { SnackBarService } from "src/app/shared/common/services/snack-bar.service";
import { SidenavComponent } from "src/app/shared/sidenav/sidenav.component";
import { AdminAccountService } from "../../admin-account/service/admin-acoount.service";
import { AdminPasswordLoadedState, AdminPasswordLoadingErrorState, AdminPasswordLoadingState } from "../../admin-account/service/admin-acoount.state";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;


  data: any;
  error: boolean;
  empty: boolean;
  loading: boolean;
  accountDetails: any;
  userLoggedIn: boolean = false;
  firstUserLoggedIn: boolean;
  CheckEmailConsent: boolean;


  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any, private fb: FormBuilder, private adminAccountService: AdminAccountService, private snackbar: SnackBarService, private sessionService: SessionService) {
    dialogRef.disableClose = true;
  }

  SidenavComponentObject = new SidenavComponent();

  ngOnInit() {
    const me = this;
    me.userLoggedIn = me.dialogData.userLoggedIn;
    me.firstUserLoggedIn = me.sessionService?.account?.hasUserLoggedInFirstTime;
    me.CheckEmailConsent = me.sessionService?.account?.meta?.emailConsentProvided;

    me.accountDetails = me.sessionService.account;
    if (me.CheckEmailConsent === false) {
      this.changePasswordForm = this.fb.group({
        'current': new FormControl('', Validators.required),
        'new': new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
        'confirm': new FormControl('', [Validators.required]),
        meta: this.fb.group({
          terms: [false, Validators.requiredTrue],
        }),
      }, {
        validator: this.MustMatch('new', 'confirm')
      });
    } else {
      this.changePasswordForm = this.fb.group({
        'current': new FormControl('', Validators.required),
        'new': new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
        'confirm': new FormControl('', [Validators.required]),


      }, {
        validator: this.MustMatch('new', 'confirm')
      });
    }




  }
  get f() { return this.changePasswordForm.controls; }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  //Password Change with new form values...
  public changePassword(data) {
    const me = this;
    const accountId = me.accountDetails.id
    const reqData = {
      oldPassword: data.current,
      newPassword: data.new,
      hasUserLoggedInFirstTime: false,
      emailConsentProvided: true,
    }
    me.adminAccountService.changeAdminPassword(reqData, accountId).subscribe(
      (state: Store.State) => {

        if (state instanceof AdminPasswordLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof AdminPasswordLoadedState) {
          me.onLoaded(state);
          me.sessionService.refreshAccountInfo();
          return;
        }
      },
      (state: AdminPasswordLoadingErrorState) => {
        me.onLoadingError(state);
      }
    );

  }

  private onLoading(state: AdminPasswordLoadingState) {
    const me = this;

    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: AdminPasswordLoadingErrorState) {
    const me = this;

    me.data = null;
    console.log(state?.error?.error?.status);

    if (state?.error?.error?.status == 'PASSWORD_DID_NOT_MATCH') {
      me.snackbar.showErrorMessage(state?.error?.error?.message)
    }
    if (state?.error?.error?.status == 'ACCOUNT_NOT_FOUND') {
      me.snackbar.showErrorMessage(state?.error?.error?.message);
    }
    me.empty = false;
    me.error = true;
    me.loading = false;
  }

  private onLoaded(state: AdminPasswordLoadedState) {
    const me = this;

    me.data = state.data;
    if (me.data.status == 'PASSWORD_CHANGED_SUCCESSFULLY') {
      me.snackbar.openSnackBar('Password Changed Successfully', 3000)
    }
    this.dialogRef.close(me.data);
    this.SidenavComponentObject.onPasswordChange();
    me.error = false;
    me.loading = false;
  }


  closeDialog() {
    this.dialogRef.close();
  }
}
