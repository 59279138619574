import {
  serviceListDataLoadedState,
  serviceListDataLoadingErrorState,
  serviceListDataLoadingState,
} from "./my-referrals-list.state";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MyReferralsListService extends Store.AbstractService {
  notifyKey = new BehaviorSubject<boolean>(false);

  getAllServiceList(payloadData): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new serviceListDataLoadingState());
    }, 0);

    const path =
      environment.api.homeOwner.serviceRequest.allServiceRequest.endpoint;
    me.controller
      .post(path, payloadData, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          // data["data"]['content'] = data.data.content.map((data) => {
          //     if (data.isAskAllProsRequest && !data.isPublished) {
          //         data.serviceCategory = {
          //             name: "Ask Allpros",
          //         };
          //         return data;
          //     }
          //     return data;
          // });
          output.next(new serviceListDataLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new serviceListDataLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }
}
