import { AbstractControl, ValidationErrors } from "@angular/forms";
import {
  SignupPayload,
  LocationPayload,
  LocationDataResponse,
  EmailPayload,
  GetInviteResponse,
  VerifyFirmResponse,
  ForwardInviteResponse,
  ForwardInvitePayload,
  searchBusinessPayload,
  Firm,
} from "./signup.model";
import { environment } from "./../../../../environments/environment";
import {
  CitiesLoadedState,
  CitiesLoadingErrorState,
  CitiesLoadingState,
  SignupLoadedState,
  SignupLoadingErrorState,
  SignupLoadingState,
  StatesLoadedState,
  StatesLoadingErrorState,
  StatesLoadingState,
  AccountLoadingState,
  AccountLoadedState,
  AccountLoadingErrorState,
  ZipCodeLoadingErrorState,
  ZipCodeLoadedState,
  ZipCodeLoadingState,
  EmailLoadingState,
  EmailLoadedState,
  EmailLoadingErrorState,


  GetInviteLoadingState,

  VerifyFirmLoadingState,

  ForwardInviteLoadedState,
  ForwardInviteLoadingState,
  ForwardInviteLoadingErrorState,
  GetInviteLoadedState,
  GetInviteLoadingErrorState,
  VerifyFirmLoadedState,
  VerifyFirmLoadingErrorState,
  BusinessLoadingErrorState,
  BusinessLoadedState,
  BusinessLoadingState,
} from "./signup.state";
import { Observable, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import { Injectable } from "@angular/core";
import { ValidatorFn } from "@angular/forms";

@Injectable({ providedIn: "root" })
export class SignupService extends Store.AbstractService {

  createAccount(
    signupPayloadData: SignupPayload,
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new SignupLoadingState());
    }, 0);

    const path = environment.api.account.registerforProUser.endpoint;

    me.controller.post(path, signupPayloadData, undefined, {}).subscribe(
      (data: any) => {
        output.next(new SignupLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new SignupLoadingErrorState(e));
        output.complete();

        me.logger.error("Signup Failed", e);
      }
    );

    return output;
  }

  getByEmail(getbyemailPayload: EmailPayload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new EmailLoadingState());
    }, 0);

    const path = environment.api.auth.verifyEmail.endpoint;
    me.controller
      .post(path, getbyemailPayload, undefined, { Authorization: false })
      .subscribe(
        (data: any) => {
          output.next(new EmailLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new EmailLoadingErrorState(e));
          output.complete();

          me.logger.error("error", e);
        }
      );

    return output;
  }

  getAccountDetails(accountId: string) {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new AccountLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.account.getAccountDetails.endpoint,
      { accountId: accountId }
    );
    me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
      (data: SignupPayload) => {
        output.next(new AccountLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new AccountLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }

  getInviteDetails(token: string) {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new GetInviteLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.invite.getInviteDetailUsingToken.endpoint,
      { token }
    );
    me.controller.get(path, null, undefined, {}).subscribe(
      (data: GetInviteResponse) => {
        output.next(new GetInviteLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new GetInviteLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }

  verifyFirm(firmData: Firm) {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new VerifyFirmLoadingState());
    }, 0);
    const path = environment.api.account.checkFirmAvailability.endpoint;

    me.controller.post(path, firmData, undefined, {}).subscribe(
      (data: VerifyFirmResponse) => {
        output.next(new VerifyFirmLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new VerifyFirmLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }


  forwardInvite(
    forwardInvitePayload: ForwardInvitePayload,
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new ForwardInviteLoadingState());
    }, 0);

    const path = environment.api.invite.forwardInvitation.endpoint

    me.controller.post(path, forwardInvitePayload, undefined, {}).subscribe(
      (data: ForwardInviteResponse) => {
        output.next(new ForwardInviteLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new ForwardInviteLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }

  //   getByEmail(getbyemailPayload: EmailPayload): Observable<Store.State> {
  //     const me = this;
  //     const output = new Subject<Store.State>();

  //     setTimeout(() => {
  //         output.next(new EmailLoadingState());
  //     }, 0);

  //     // const path = environment.api.account.getByEmail.endpoint;
  //     const path = me.controller.replaceVariables(
  //       environment.api.settings.adminAccount.verifyEmail.endpoint,
  //       { email: getbyemailPayload.email},
  //     );
  //     me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
  //         (data: any) => {
  //             output.next(new EmailLoadedState(data));
  //             output.complete();
  //         },
  //         (e: any) => {
  //             output.error(new EmailLoadingErrorState(e));
  //             output.complete();

  //             me.logger.error('error', e);
  //         }
  //     );

  //     return output;
  // }

  getStates(
    statePayload: LocationPayload,
    countryCode
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new StatesLoadingState());
    }, 0);

    const path = me.controller.replaceVariables(
      environment.api.location.states.endpoint,
      { countryCode: countryCode }
    );

    me.controller.post(path, statePayload, undefined, {}).subscribe(
      (data: LocationDataResponse) => {
        output.next(new StatesLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new StatesLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }

  getCities(
    cityPayload: LocationPayload,
    countryCode: string,
    stateCode: string
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new CitiesLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.location.city.endpoint,
      { countryCode: countryCode, stateCode: stateCode }
    );
    me.controller.post(path, cityPayload, undefined, {}).subscribe(
      (data: LocationDataResponse) => {
        output.next(new CitiesLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new CitiesLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

  getZipCodes(
    cityPayload: LocationPayload,
    countryCode: string,
    stateCode: string,
    cityId: string
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new ZipCodeLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.location.zipCode.endpoint,
      { countryCode: countryCode, stateCode: stateCode, cityId: cityId }
    );
    me.controller.post(path, cityPayload, undefined, {}).subscribe(
      (data: LocationDataResponse) => {
        output.next(new ZipCodeLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new ZipCodeLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

  getBusinessSearchData(payload: searchBusinessPayload) {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
        output.next(new BusinessLoadingState());
    }, 0);

    const path = environment.api.account.firmSearch.endpoint;

    me.controller.post(path, payload, undefined, { Authorization: false }).subscribe(
        (data: any) => {
            output.next(new BusinessLoadedState(data));
            output.complete();
        },
        (e: any) => {
            output.error(new BusinessLoadingErrorState(e));
            output.complete();

            me.logger.error('error', e);
        }
    );

    return output;

}
}
