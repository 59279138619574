import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AppError } from "src/app/core/error/error.model";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
import { RegisterHomeOwner } from "./home-owner-signup.model";
import {
  homeOwnerRegisterLoadedState,
  homeOwnerRegisterLoadingErrorState,
  homeOwnerRegisterLoadingState,
  SourcesLoadedState,
  SourcesLoadingErrorState,
  SourcesLoadingState,
} from "./home-owner-signup.state";

@Injectable({
  providedIn: "root",
})
export class HomeOwnerSignupService extends Store.AbstractService {
  // Registers new user through signing up....
  registerUser(
    registerUserPayload: Partial<RegisterHomeOwner>
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new homeOwnerRegisterLoadingState());
    }, 0);

    const path = environment.api.account.homeOwnerRegister.endpoint;

    me.controller
      .post(path, registerUserPayload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new homeOwnerRegisterLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new homeOwnerRegisterLoadingErrorState(e));

          output.complete();

          me.logger.error("error", e);
        }
      );

    return output;
  }

  getSources(): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new SourcesLoadingState());
    }, 0);

    const path = environment.api.account.source.endpoint;

    me.controller.get(path, null, undefined, {}).subscribe(
      (data: any) => {
        output.next(new SourcesLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new SourcesLoadingErrorState(e));
        output.complete();

        me.logger.error("error", e);
      }
    );

    return output;
  }
}
