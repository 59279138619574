import { from } from "rxjs";
import { messages } from "./../../../testing/mockCometChatData";
import { CreateRequestServiceService } from "src/app/pages/home-owner/dialogs/create-request/service/create-request-service.service";
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GetAdditionalDataService } from "./service/get-additional-data.service";
import { Store } from "src/app/core/store/store";
import {
  AddPropertyManagerLoadedState,
  AddPropertyManagerLoadingErrorState,
  AddPropertyManagerLoadingState,
  getAdditionalDataLoadedState,
  getAdditionalDataLoadingErrorState,
  getAdditionalDataLoadingState,
} from "./service/get-additional-data.state";
import { SessionService } from "src/app/core/session/session.service";
import { SnackBarService } from "src/app/shared/common/services/snack-bar.service";
import { HOMEOWNER_UPDATE_PROFILE } from "src/app/shared/mixpanel/mixpanel.enums";
import { MixpanelService } from "src/app/shared/mixpanel/mixpanel.service";
import { ListedPropertiesService } from "../../property-manager/properties/listed-properties/service/listed-properties.service";
import {
  assignPropertiesLoadedState,
  assignPropertiesLoadingErrorState,
  assignPropertiesLoadingState,
} from "../../property-manager/properties/listed-properties/service/listed-properties.state";
import { SignupService } from "src/app/pages/signup/service/signup.service";
import {
  SignupLoadedState,
  SignupLoadingErrorState,
  SignupLoadingState,
} from "src/app/pages/signup/service/signup.state";

@Component({
  selector: "app-get-additional-data",
  templateUrl: "./get-additional-data.component.html",
  styleUrls: ["./get-additional-data.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GetAdditionalDataComponent implements OnInit, OnDestroy {
  additionalDataForm: FormGroup;
  data: any;
  empty: boolean;
  error: any;
  loading: boolean;
  componentName: any;
  userId: string;
  isPropertyDialog: boolean;

  constructor(
    public dialogRef: MatDialogRef<GetAdditionalDataComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private createRequestService: CreateRequestServiceService,
    private getAdditionalDataService: GetAdditionalDataService,
    private signUpService: SignupService,
    private sessionService: SessionService,
    private snackBar: SnackBarService,
    private mixPanelService: MixpanelService,
    private listedPropertiesService: ListedPropertiesService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnDestroy(): void {
    const me = this;
    me.componentName = null;
  }

  ngOnInit(): void {
    const me = this;
    me.isPropertyDialog = me.dialogData?.isPropertyDialog;
    me.additionalDataForm = this._formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      mobile: ["", [Validators.required]],
      email: this.isPropertyDialog ? ["", [Validators.required]] : "",
    });
    me.componentName = me.dialogData?.componentName;
    me.userId = me.sessionService?.account.id;
  }

  sendAdditionalData() {
    const me = this;
    const newPayload = {
      firstName: me.additionalDataForm.value.firstName,
      lastName: me.additionalDataForm.value.lastName,
      mobile: me.additionalDataForm.value.mobile,
      mobile_code: "+1",
    };
    if (me.additionalDataForm.status === "VALID") {
      me.getAdditionalDataService
        .updateUserDetails(newPayload, me.userId)
        .subscribe((state: Store.State) => {
          if (state instanceof getAdditionalDataLoadingState) {
            me.onLoading(state);
            return;
          }
          if (state instanceof getAdditionalDataLoadedState) {
            //on change profile details fire mixpanel profile update event.
            me.onLoaded(state, newPayload);
            return;
          }
          (state: getAdditionalDataLoadingErrorState) => {
            me.onLoadingError(state);
          };
        });
    }
  }

  dialogClose() {
    this.dialogRef.close();
  }

  addPropertyManager() {
    const me = this;
    const fullName =
      me.additionalDataForm.value.firstName +
      " " +
      me.additionalDataForm.value.lastName;
    const newPayload = {
      firstName: me.additionalDataForm.value.firstName,
      lastName: me.additionalDataForm.value.lastName,
      fullName: fullName,
      mobile: me.additionalDataForm.value.mobile,
      mobile_code: "+1",
      email: me.additionalDataForm.value.email,
      secret: "Allpros@123",
      status: "ACTIVE",
      accountMeta: {
        emailConsentProvided: true,
      },
      roles: [
        {
          code: "PROPERTY_MANAGER",
        },
      ],
      firm: {
        id: me.sessionService.account?.firms[0].id,
        meta: {},
      },
    };
    if (me.additionalDataForm.status === "VALID") {
      me.signUpService.createAccount(newPayload).subscribe(
        (state: Store.State) => {
          if (state instanceof SignupLoadingState) {
            me.data = null;
            me.empty = false;
            me.error = null;
            me.loading = true;
            return;
          }
          if (state instanceof SignupLoadedState) {
            //on change profile details fire mixpanel profile update event.
            me.loading = false;
            me.data = state.data;
            me.snackBar.openSnackBar(me.data.message, 3000);
            this.dialogRef.close(true);
            return;
          }
        },
        (state: SignupLoadingErrorState) => {
          me.data = null;
          me.empty = false;
          me.error = true;
          me.loading = false;
          console.log(state);
          me.snackBar.openSnackBar(state?.error?.error?.message, 3000);
        }
      );
    }
  }

  private onLoading(state: getAdditionalDataLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: getAdditionalDataLoadingErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = false;
  }

  private onLoaded(state: getAdditionalDataLoadedState, userData: any) {
    const me = this;
    me.loading = false;
    me.data = state.data;
    me.snackBar.openSnackBar(me.data.message, 3000);
    me.sessionService.refreshAccountInfo();

    if (me.componentName !== "getAQuote") {
      me.createRequestService.holdCreateRequestComponent.getReferral();
    }

    me.hoMixpanelUpdateProfile(userData);
    this.dialogRef.close(true);
  }

  // update profile info on mixpanel.
  private hoMixpanelUpdateProfile(profileUserProperties: any): void {
    const me = this;
    me.mixPanelService.identify(me.userId);
    let userProperties = {
      $first_name: profileUserProperties.firstName,
      $last_name: profileUserProperties.lastName,
      $phone: profileUserProperties.mobile,
    };
    let eventProperties = {
      Source: "Request",
    };
    me.mixPanelService.setPeople(userProperties);
    me.mixPanelService.sendEvent(HOMEOWNER_UPDATE_PROFILE, eventProperties);
  }
}
