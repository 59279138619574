import { Store } from "src/app/core/store/store";
import { ServiceCoverageComponent } from "../service-coverage.component";

export class ServiceCoverageLoadingState extends Store.AbstractLoadingState<any> { }
export class ServiceCoverageLoadingErrorState extends Store.AbstractErrorState<any> { }
export class ServiceCoverageLoadedState extends Store.AbstractIdealState<any> { }

export class UpdateServiceCoverageLoadingState extends Store.AbstractLoadingState<any> { }
export class UpdateServiceCoverageLoadingErrorState extends Store.AbstractErrorState<any> { }
export class UpdateServiceCoverageLoadedState extends Store.AbstractIdealState<any> { }

export class FinishGuidedJourneyLoadingState extends Store.AbstractLoadingState<any> { }
export class FinishGuidedJourneyLoadingErrorState extends Store.AbstractErrorState<any> { }
export class FinishGuidedJourneyLoadedState extends Store.AbstractIdealState<any> { }