import { Session } from "protractor";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { environment } from "src/environments/environment";
import { FacebookUserData } from "./facebook.model";
import { FacebookAuthenticationService } from "./facebook.service";


declare const FB: any;

export function facebookAppInitializer(accountService: FacebookAuthenticationService) {
    return () => new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the angular app
        window['fbAsyncInit'] = function () {
            FB.init({
                appId: environment.facebookAppId,
                cookie: true,
                xfbml: true,
                version: environment.facebookPlatformVersion
            });

            //auto authenticate with the api if already logged in with facebook
            FB.getLoginStatus(({ authResponse }) => {
                accountService.facebookAuthObj = authResponse || null;
                const session = AppLocalStorage.get("SESSION", "SESSION", true) as Session;

                if (accountService.facebookAuthObj && !session) {
                    accountService.fbUserInfoWithAllprosValidation();
                }


            });
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        resolve(null);

    });
}