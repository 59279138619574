import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainContentComponent } from './main-content/main-content.component';
import { MainContentWithSidebarComponent } from './main-content-with-sidebar/main-content-with-sidebar.component';
import { PagesModule } from '../pages.module';
import { SidenavModule } from 'src/app/shared/sidenav/sidenav.module';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/shared/material/material-module';
import { HeaderModule } from 'src/app/shared/header/header.module';


@NgModule({
  declarations: [MainContentComponent, MainContentWithSidebarComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    HeaderModule,
    SidenavModule,
    RouterModule.forChild([])
  ],
  exports: [MainContentComponent, MainContentWithSidebarComponent]
})
export class LayoutModule { }
