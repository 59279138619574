export const IS_INTERNET_EXPLORER =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function isMobile() {
  const me = this;
  if (
    /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) && screen.orientation.angle != 90
  ) {
    // true for mobile device
    // window.addEventListener("orientationchange", function () {
      // alert("the orientation of the device is now " + screen.orientation.angle);
    // });
    return true;
  } else {
    // false for not mobile device
    return false;
  }
}
