import { HttpClient } from "@angular/common/http";
import { Store } from "src/app/core/store/store";
import { SnackBarService } from "./snack-bar.service";
import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";
import { catchError, tap } from "rxjs/operators";
import { throwError, Subject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CustomUtilityService {
    constructor(private snackbar: SnackBarService, private http: HttpClient) { }

    getProfileInitials(profileName: string): string {
        const profile = profileName.split(" ");
        return profile.length > 1
            ? profile[0].charAt(0) + profile[1].charAt(0)
            : profile[0].charAt(0);
    }

    getCommaSepareted(data: any): string {
        if (data && data.length) {
            const idArray = data.map((item: any) => {
                return item && item.name ? item.name : null;
            });
            return idArray.sort().join(", ");
        } else {
            return "-";
        }
    }

    getArraysOfId(data: any): Array<string | number> {
        const ids = [];

        if (data?.length) {
            data.forEach((element: any) => {
                ids.push(element.id);
            });
        }
        return ids;
    }

    getArraysOfCode(data: any): Array<string> {
        const codes = [];

        if (data?.length) {
            data.forEach((element: any) => {
                codes.push(element.Code || element.code); //to be changed from backend
            });
        }
        return codes;
    }

    getArraysOfName(data: any): Array<string> {
        const codes = [];

        if (data?.length) {
            data.forEach((element: any) => {
                codes.push(" " + element.name);
            });
        }
        return codes;
    }

    getArraysOfCodeAndName(data: any): Array<string> {
        const codesAndNames = [];

        if (data?.length) {
            data.forEach((element: any) => {
                codesAndNames.push({
                    code: element.Code || element.code,
                    name: element.name,
                });
            });
        }
        return codesAndNames;
    }

    prepareAccountObject(account) {
        if (account) {
            if (account.firstName && account.lastName) {
                account.initials =
                    account.firstName.charAt(0) + account.lastName.charAt(0);
                account.fullName = account.firstName + " " + account.lastName;
            } else if (account.firstName && !account.lastName) {
                account.initials = account.firstName.charAt(0);
                account.fullName = account.firstName;
            } else if (!account.firstName && account.lastName) {
                account.initials = account.lastName.charAt(0);
                account.fullName = account.lastName;
            } else {
                account.initials = "!";
                account.fullName = "No Name";
            }
        }
        return account;
    }

    titleCase(sendMeString: string): string {
        const splitStr = sendMeString.toLowerCase().split(" ");

        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
    }

    getModifiedDate(rawDate, addDays?: number): Date {
        let modifiedDate;

        const dateToModify = moment(
            moment(rawDate).add(addDays, "d").format("YYYY/MM/DD HH:mm:ss")
        );
        if (addDays) {
            if (moment().diff(dateToModify) >= 0) {
                modifiedDate = dateToModify;
                // modifiedDate = moment(+moment(dateToModify).endOf('day')).format('YYYY/MM/DD HH:mm:ss');
            } else {
                modifiedDate = new Date();
                // modifiedDate = moment(+moment(new Date()).endOf('day')).format('YYYY/MM/DD HH:mm:ss');
            }
        } else {
            modifiedDate = new Date();
            // modifiedDate = moment(+moment(new Date()).endOf('day')).format('YYYY/MM/DD HH:mm:ss');
        }

        return modifiedDate;
    }

    displayHttpMessage(data) {
        // console.log(data);
        const me = this;
        switch (data.status) {
            case "INVALID_PASSWORD": {
                me.snackbar.showErrorMessage("Invalid Password");
                break;
            }
            case "INVALID_USERNAME": {
                me.snackbar.showErrorMessage("Invalid Username");
                break;
            }
            case "LOGIN_SUCCESSFUL": {
                me.snackbar.openSnackBar("Signed In Successfully", 3000);
                break;
            }
            case "ACCOUNT_ALREADY_PRESENT": {
                me.snackbar.openSnackBar("Account Already Present", 3000);
                break;
            }
            default: {
                me.snackbar.showErrorMessage("Internal server error");
                break;
            }
        }
    }

    toastMessage(statusString: string, isError: boolean) {
        const me = this;
        let message;
        switch (statusString) {
            case "INVALID_PASSWORD": {
                message = "Invalid Password";
                break;
            }

            case "REGISTRATION_SUCCESSFUL": {
                message = "Successfully Registered";
                break;
            }

            case "PASSWORD_CHANGED_SUCCESSFULLY": {
                message = "Password Changed Successfully";
                break;
            }

            case "ACCOUNT_UPDATED_SUCCESSFULLY": {
                message = "Account Updated Successfully";
                break;
            }

            case "ACCOUNT_REMOVED_SUCCESSFULLY": {
                message = "Account Removed Successfully";
                break;
            }

            case "REMOVED_SUCCESSFULLY": {
                message = "";
                break;
            }

            case "AVAILABLE": {
                message = "Account In Use";
                break;
            }

            case "SMS_SENT": {
                message = "Message Sent on Mobile";
                break;
            }
            case "LOGOUT_SUCCESSFUL": {
                message = "Logout Successfully";
                break;
            }
            case "OTP_SENT_SUCCESSFULLY": {
                message = "OTP sent on registered mobile";
                break;
            }
            case "EMAIL_AVAILABLE": {
                message = "";
                break;
            }
            case "FIRM_BILLING_UPDATED_SUCCESSFULLY": {
                message = "Billing details updated";
                break;
            }
            case "FIRM_BILLING_REMOVED_SUCCESSFULLY": {
                message = "Billing detail removed successfully";
                break;
            }
            case "FIRM_ADDED_SUCCESSFULLY": {
                message = "Firm added successfully";
                break;
            }
            case "FIRM_DETAILS_UPDATED_SUCCESSFULLY": {
                message = "Firm details updated successfully";
                break;
            }
            case "FIRM_SERVICE_CATEGORIES_ADDED_SUCCESSFULLY": {
                message = "Firm service categories added successfully";
                break;
            }
            case "FILES_UPLOADED_SUCCESSFULLY": {
                message = "Image gallery updated successfully";
                break;
            }
            case "FILE_UPLOADED_SUCCESSFULLY": {
                message = "File uploded successfully";
                break;
            }
            case '"FIRM_GALLERY_IMAGE_REMOVED_SUCCESSFULLY"': {
                message = "Image removed successfully.";
                break;
            }
            case "FIRM_META_ADDED_SUCCESSFULLY": {
                message = "";
                break;
            }
            case "USER_INVITATION_SENT_SUCCESSFULLY": {
                message = "User invitation sent successfully";
                break;
            }
            case '"SIGN_UP_INVITATION_DETAILS_UPDATED_SUCCESSFULLY"': {
                message = "Signup Invitation Details updated successfully";
                break;
            }
            case "LINK_SENT_SUCCESSFULLY": {
                message = "Email sent successfully";
                break;
            }
            case "REFERENCE_SENT_SUCCESSFULLY": {
                message = "Reference sent successfully";
                break;
            }
            case "REFERENCE_UPDATED_SUCCESSFULLY": {
                message = "Reference updated successfully";
                break;
            }
            case "REFER_BACK_REQUEST_SENT_SUCCESSFULLY": {
                message = "Refer back request sent successfully";
                break;
            }
            case '"SERVICE_CATEGORY_INSERTED_SUCCESSFULLY"': {
                message = "Service category added successfully";
                break;
            }
            case "SERVICECATEGORY_UPDATED_SUCCESSFULLY": {
                message = "Service category updated successfully";
                break;
            }
            case "SERVICECATEGORY_REMOVED_SUCCESSFULLY": {
                message = "Service category removed successfully";
                break;
            }
            // case "REFERENCE_SENT_SUCCESSFULLY": {
            //     message = "Reference sent successfully";
            //     break;
            // }
            // case "REFERENCE_SENT_SUCCESSFULLY": {
            //     message = "Reference sent successfully";
            //     break;
            // }
            // case "REFERENCE_SENT_SUCCESSFULLY": {
            //     message = "Reference sent successfully";
            //     break;
            // }

            default: {
                message = "Internal server error";
                break;
            }
        }

        if (!isError) {
            me.snackbar.openSnackBar(message, 3000);
        } else {
            me.snackbar.showErrorMessage(message);
        }
    }

    public checkDeviceInformation(): string {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            return "Mobile Android";
        }

        if (/iPhone|iPod/.test(userAgent)) {
            return "Mobile iOS";
        }

        if (/iPad/.test(userAgent)) {
            return "iPad Tablet";
        }

        return "Desktop";
    }

    getIpAddress(): Observable<any> {
        const me = this;
        const data: Subject<any> = new Subject();

        setTimeout(() => {
            data.next({ type: "loading", loading: true, ipData: null });
        }, 0);
        me.http.get<any>("https://jsonip.com").subscribe(
            (res) => {
                data.next({ type: "loaded", loading: false, ipData: res });
                data.complete();
            },
            (err) => {
                data.error({ type: "error", loading: false, ipData: err });
                data.complete();
            }
        );
        return data;
    }
}
