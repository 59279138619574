import { Injectable } from "@angular/core";
import {
  ActivateUserAccountLoadedState,
  ActivateUserAccountLoadingErrorState,
  ActivateUserAccountLoadingState,
  FileDeleteLoadedState,
  FileDeleteLoadingErrorState,
  FileDeleteLoadingState,
  FileUploadLoadedState,
  FileUploadLoadingState,
  homeOwnerAddressDataLoadedState,
  homeOwnerAddressDataLoadingErrorState,
  homeOwnerAddressDataLoadingState,
  homeOwnerProfileDataLoadedState,
  homeOwnerProfileDataLoadingErrorState,
  homeOwnerProfileDataLoadingState,
  listedPropertiesLoadedState,
  listedPropertiesLoadingErrorState,
  listedPropertiesLoadingState,
} from "./home-owner-profile.state";

import { Observable, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
import { FileUploadLoadingErrorState } from "src/app/pages/settings/business-account/service/business-account.state";

@Injectable({ providedIn: "root" })
export class HomeOwnerProfileService extends Store.AbstractService {
  // Upload the file..
  uploadFile(payload: FormData, userid: string): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new FileUploadLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.homeOwner.upload.endpoint,
      { userid: userid }
    );

    me.controller
      .post(path, payload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new FileUploadLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new FileUploadLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  //Get list of addressess by posting required payload...
  getListOfAddress(reqPayload: any): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new homeOwnerAddressDataLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.homeOwner.getListOfAddress.endpoint,
      {}
    );

    me.controller
      .post(path, reqPayload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new homeOwnerAddressDataLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new homeOwnerAddressDataLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  // all visible properties 
  getListedProperties(payload) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new listedPropertiesLoadingState());
    }, 0);
    // setTimeout(() => {
    //   output.next(new listedPropertiesLoadedState(Dummy_Properties))
    // }, 0);
    const path = environment.api.homeOwner.propertyManager.viewProperties.endpoint;
    me.controller.post(path, payload, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        me.getPropertyIdArray(data.data.content);
        output.next(new listedPropertiesLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new listedPropertiesLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

  propertyIdArray: Subject<any> = new Subject<any>();

  getPropertyIdArray(propertyArr: any[]): void {
    const properties = [];
    propertyArr.map((element) => {
      properties.push(element.id);
    });
    this.propertyIdArray.next(properties)
  }



  // Update the changed values of home owner profile details.
  updateHomeOwner(reqPayload: any, userid: string): Observable<Store.State> {
    const me = this;

    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new homeOwnerProfileDataLoadingState());
    }, 0);

    console.log(reqPayload);
    const path = me.controller.replaceVariables(
      environment.api.homeOwner.updateHomeOwner.endpoint,
      { userid: userid }
    );

    me.controller
      .put(path, reqPayload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new homeOwnerProfileDataLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new homeOwnerProfileDataLoadingErrorState(e));
          output.complete();

          me.logger.error("error", e);
        }
      );

    return output;
  }

  //  Delete the logo file...
  deleteFile(userid: string, type: string): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new FileDeleteLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.homeOwner.deleteProfile.endpoint,
      { userid: userid, type: type }
    );

    me.controller.delete(path, undefined, { Authorization: true }).subscribe(
      (data: any) => {
        output.next(new FileDeleteLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new FileDeleteLoadingErrorState(e));
        output.complete();
      }
    );

    return output;
  }
  activateUserAccount(userid) {
    const me = this;
    const output = new Subject<Store.State>();
    setTimeout(() => {
      output.next(new ActivateUserAccountLoadingState());
    }, 0);
    const path = me.controller.replaceVariables(
      environment.api.account.deleteAccount.endpoint,
      { userId: userid }
    );

    me.controller
      .post(path, null, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new ActivateUserAccountLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new ActivateUserAccountLoadingErrorState(e));
          output.complete();
        }
      );
    return output;
  }
}
