import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { SessionService } from './core/session/session.service';
import { CustomUtilityService } from './shared/common/services/custom-utility.service';
import { FacebookAuthenticationService } from './shared/facebook/facebook.service';
import { HOMEOWNER_DOWNLOAD_CLICK } from './shared/mixpanel/mixpanel.enums';
import { MixpanelService } from './shared/mixpanel/mixpanel.service';
import { AppLocalStorage } from './utility/local-storage.util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  loggedIn = false;

  socket: WebSocket;

  visited: boolean = false;
  deepLinkDetails: any = {};

  isAndroid: boolean = false;
  isIOS: boolean = false;

  showHomeownerLink: boolean = false;
  showProLink: boolean = false;
  ipAddress: any;


  constructor(public sessionService: SessionService, private mixpanelService: MixpanelService, private router: Router, private customUtilityService: CustomUtilityService, public fbService:FacebookAuthenticationService
  ) {
    window.moment = moment;
  }

  ngOnInit() {
    const me = this;
    me.visited = false;

    me.getIpAdress();

    // alert('here')
    // AppLocalStorage.set("SESSION", "BETTEREXPERIENCEVISITED", false);
    // me.visited = AppLocalStorage.get("SESSION", "BETTEREXPERIENCEVISITED");
    // console.log(AppLocalStorage.get("SESSION", "BETTEREXPERIENCEVISITED"));

    this.router.events.subscribe((res) => {

      if (me.router.url.includes('/home-owner')) {
        me.showHomeownerLink = true;
        me.showProLink = false
      } else {
        me.showProLink = true
        me.showHomeownerLink = false;
      }

      // me.visited = AppLocalStorage.get("SESSION", "BETTEREXPERIENCEVISITED");
      me.deepLinkDetails = AppLocalStorage.get("SESSION", "DEEPLINKCODE");


      if (/iPad|iPhone|iPod/.test((navigator.userAgent || navigator.vendor))) {
        this.isIOS = true;
        this.isAndroid = false;
      } else if (/android/i.test((navigator.userAgent || navigator.vendor))) {
        this.isAndroid = true;
        this.isIOS = false;
      };

    });

  }

  closePopup() {
    const me = this;

    // AppLocalStorage.set("SESSION", "BETTEREXPERIENCEVISITED", true);
    me.visited = true;
  }

  private getIpAdress(): void {
    const me = this;
    me.customUtilityService.getIpAddress().subscribe((data) => {
      if (data.type === "loaded") {
        me.ipAddress = data.ipData.ip;
      }
    }, (error) => {

    })

  }

  public mixPanelDownloadEvent(): void {
    const me = this;
    if (me.sessionService?.details?.mixPanelAnalyticsEnabled) {

      const device = me.customUtilityService.checkDeviceInformation();
      const superProperties = {
        Platform: device,
        "App Version": environment.applicationVersion,
      };

      me.mixpanelService.setSuperProperties(superProperties);
      const eventProperties = {
        IP: me.ipAddress
      }
      me.mixpanelService.sendEvent(HOMEOWNER_DOWNLOAD_CLICK, eventProperties);
    }
  }

}
