export const HOMEOWNER_SIGN_UP = "Homeowner Sign Up";
export const HOMEOWNER_LOG_IN = "Homeowner Log In";
export const HOMEOWNER_LOG_OUT = "Homeowner Log Out";
export const HOMEOWNER_UPDATE_PROFILE = "Homeowner Update Profile";
export const HOMEOWNER_DIRECT_REQUEST = "Homeowner Direct Request";
export const HOMEOWNER_DISCOVERY_REQUEST = "Homeowner Discovery Request";
export const HOMEOWNER_GET_QUOTE = "Homeowner Get Quote";
export const HOMEOWNER_ADDRESS_CHANGE = "Homeowner Address Change";
export const HOMEOWNER_CLICK_ON_REQUEST = "Homeowner Click on Request";
export const HOMEOWNER_FORM_CLOSED = "Homeowner Form Closed";
export const HOMEOWNER_MESSAGE = "Homeowner Message";
export const HOMEOWNER_ASKALLPROS_REQUEST = "Homeowner Ask Allpros Request";
export const HOMEOWNER_SIGN_UP_LANDING = "Homeowner Sign Up Landing";
//if user on ho signup page
export const CLICK_HERE_FOR_PRO_SIGN_UP = "Click Here For Pro Sign Up";
//if user on pro singup page
export const CLICK_HERE_FOR_HOMEOWNER_SIGN_UP = "Click Here For Homeowner Sign Up";
export const CLICK_HOMEOWNER_ON_SIGN_IN = "Click Homeowner on Sign In";
export const HOMEOWNER_DOWNLOAD_CLICK = "Homeowner Download Click";
export const HOMEOWNER_SIGN_UP_FAILURE = "Homeowner Sign Up Failure";

export const CLICK_SIGN_IN_USING_FACEBOOK = "Click Sign In using Facebook"








