import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject, OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { ActivatedRoute, Router } from "@angular/router";
import { ReplaySubject, Subject } from "rxjs";
import { debounceTime, take, takeUntil } from "rxjs/operators";
import { SessionService } from "src/app/core/session/session.service";
import { Store } from "src/app/core/store/store";
import { SnackBarService } from "src/app/shared/common/services/snack-bar.service";
import {
  HOMEOWNER_ADDRESS_CHANGE,
  HOMEOWNER_ASKALLPROS_REQUEST,
  HOMEOWNER_DIRECT_REQUEST,
  HOMEOWNER_DISCOVERY_REQUEST
} from "src/app/shared/mixpanel/mixpanel.enums";
import { MixpanelService } from "src/app/shared/mixpanel/mixpanel.service";
import { HomeOwnerProfileService } from "../../home-owner-profile/service/home-owner-profile.service";
import {
  listedPropertiesLoadedState,
  listedPropertiesLoadingErrorState,
  listedPropertiesLoadingState
} from "../../home-owner-profile/service/home-owner-profile.state";
import { MyReferralsListService } from "../../my-referrals/my-referrals-list/service/my-referrals-list.service";
import { GetAdditionalDataComponent } from "../get-additional-data/get-additional-data.component";
import { CreateRequestServiceService } from "./service/create-request-service.service";
import {
  chooseProLoadedState,
  chooseProLoadingErrorState,
  chooseProLoadingState,
  createRequestLoadedState,
  createRequestLoadingErrorState,
  createRequestLoadingState,
  requestCompletionLoadedState,
  requestCompletionLoadingErrorState,
  requestCompletionLoadingState,
  serviceCategoriesByFirmLoadedState,
  serviceCategoriesByFirmLoadingErrorState,
  serviceCategoriesLoadedState,
  serviceCategoriesLoadingErrorState,
  serviceCategoriesLoadingState
} from "./service/create-request-service.state";

@Component({
  selector: "app-create-request",
  templateUrl: "./create-request.component.html",
  styleUrls: ["./create-request.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateRequestComponent
  implements OnInit, AfterViewInit, OnDestroy {
  deleteImageFlag: boolean = false;
  serviceCategories: [];
  userid: any;
  addressList: [];
  empty: boolean;
  error: any;
  loading: boolean;
  files: any = [];
  errorMsg: string;
  showError: boolean;
  selectedFile: any = [];
  queryString: any = "";
  searchLoading: boolean;
  searchData: [] = [];
  firmAccountsArray: any = [];
  firmName: string;
  createRequestType: string = "";
  firmId: any;
  accountId: string;
  disableInputFlag: boolean = false;

  serviceCategoryValue = new FormControl();

  searchTextControl = new FormControl();

  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;
  public $serviceCategories: ReplaySubject<any[]> = new ReplaySubject(1);
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  page: any;
  dialogData: any;
  firmSelected: string;
  isProEmpty: boolean;
  userName: any;
  requestType: string = "discovery";
  mixPanelChoosePro: Partial<{ firmName: string; proName: string }> = {};
  isCheckboxClicked: boolean;
  isUserFullnamePresent: string;
  userAccountDetails: any;
  requestData: string[];
  needReferralsCheckBoxValue: boolean = true;


  constructor(
    public dialogRef: MatDialogRef<CreateRequestComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private createRequestService: CreateRequestServiceService,
    private homeOwnerProfileService: HomeOwnerProfileService,
    private sessionService: SessionService,
    private snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private mixpanelService: MixpanelService,
    private myReferralsListService: MyReferralsListService
  ) {
    dialogRef.disableClose = true;
    this.activatedRoute.url.subscribe((activeUrl) => { });
  }
  urls = [];
  selectedAddress;
  selectedCategory;
  // selectedRequest = "discovery";
  createRequestForm: FormGroup;
  googleLocationObj: any;
  zipNotAvailabel: boolean = false; //for showing zipCode not available.
  editMode: boolean = false;
  addresses = [{ value: "newAddress", viewValue: "Or Add New Address" }];
  ngOnInit(): void {
    const me = this;
    me.dialogData = me.data;
    me.firmId = me.dialogData?.pros?.detailed_information?.firm?.id;
    // me.sessionService.fullName$.subscribe((fullName) => {
    //   me.isUserFullnamePresent = fullName;
    // });
    me.userAccountDetails = me.sessionService?.account;
    me.createRequestForm = this.fb.group({
      selectedReq: new FormControl("discovery"),
      serviceCategory: new FormControl({ value: "", disabled: false }),
      serviceAddress: new FormControl("", [Validators.required]),
      street: new FormControl(""),
      city: new FormControl(""),
      state: new FormControl(""),
      zipCode: new FormControl(""),
      apartmentNumber: new FormControl(""),
      description: new FormControl("", [Validators.required]),
      askAllprosCheckbox: new FormControl(""),
      needReferralsCheckbox: new FormControl(""),
      serviceImages: new FormControl(""),
      proName: new FormControl(""),
      requestFulfillDate: new FormControl("", [Validators.required]),
    });
    me.createRequestForm.controls["needReferralsCheckbox"].setValue(true);
    me.createRequestForm
      .get("serviceAddress")
      .valueChanges.subscribe((newAddress) => {
        if (newAddress === "newAddress") {
          me.createRequestForm.controls["street"].setValidators([
            Validators.required,
          ]);
          me.createRequestForm.controls["city"].setValidators([
            Validators.required,
          ]);
          me.createRequestForm.controls["state"].setValidators([
            Validators.required,
          ]);
          me.createRequestForm.controls["zipCode"].setValidators([
            Validators.required,
          ]);
        } else {
          me.createRequestForm.get("street").clearValidators();
          me.createRequestForm.get("street").updateValueAndValidity();
          me.createRequestForm.get("city").clearValidators();
          me.createRequestForm.get("city").updateValueAndValidity();
          me.createRequestForm.get("state").clearValidators();
          me.createRequestForm.get("state").updateValueAndValidity();
          me.createRequestForm.get("zipCode").clearValidators();
          me.createRequestForm.get("zipCode").updateValueAndValidity();
        }
      });
    me.userid = me.sessionService?.account.id;
    me.getListOfAddresses();
    me.page = me.data?.dialogActivity?.page;
    if (me.data?.dialogActivity?.page == "myPros") {
      // me.selectedRequest = "directRequest";
      this.createRequestForm.controls["selectedReq"].setValue("directRequest");
      this.createRequestForm.controls["selectedReq"].disable();

      if (!me.dialogData?.pros?.detailed_information?.firm?.name) {
        me.createRequestForm.controls["proName"].setValidators([
          Validators.required,
        ]);
      }

      me.createRequestType = "directRequest";
      me.getServiceCategoriesByFirm(me.dialogData?.pros?.firmID);
    } else {
      me.getServiceCategories();
    }
    me.createRequestForm
      .get("street")
      .valueChanges.pipe()
      .subscribe((queryString) => {
        if (queryString) {
          this.createRequestForm.controls["city"].reset();
          this.createRequestForm.controls["state"].reset();
          this.createRequestForm.controls["zipCode"].reset();
          this.googleLocationObj = {};
        }
      });
    if (me.dialogData.status === "askAllpros") {
      me.isCheckboxClicked = true;
      me.createRequestForm.controls["askAllprosCheckbox"].setValue(true);
      // me.createRequestForm.controls["askAllprosCheckbox"].disable();
      me.createRequestForm.controls["selectedReq"].disable();
      // me.createRequestForm.get("serviceCategory").disable();
    }
    me.getRequestCompletionData();
    me.queryString = me.createRequestForm.get("proName").value;

    me.loadProData(
      me.queryString,
      me.createRequestForm.get("serviceCategory").value?.id
    );
  }
  ngAfterViewInit() {
    const me = this;
    // me.createRequestForm
    //   .get("selectedReq")
    //   .valueChanges.subscribe((requestType) => {
    //     me.createRequestType = requestType;
    //   });

    me.createRequestForm
      .get("proName")
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((queryString) => {
        me.queryString = queryString;
        me.loadProData(
          me.queryString,
          me.createRequestForm.get("serviceCategory").value?.id
        );
      });

    me.createRequestForm.get("serviceCategory").valueChanges.subscribe(() => {
      me.createRequestForm.get("proName").reset();
    });

    // listen for search field value changes
    this.serviceCategoryValue.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCategory();
      });
    // me.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  loadProData(value?, selectedCategory?) {
    const me = this;
    if (me.dialogData?.pros?.detailed_information?.firm?.id) {
      me.firmSelected = me.dialogData?.pros?.detailed_information?.firm?.id;
    }

    me.createRequestService
      .loadProData(
        value,
        me.firmSelected,
        selectedCategory ? selectedCategory : ""
      )
      .subscribe(
        (state: Store.State) => {
          if (state instanceof chooseProLoadingState) {
            const me = this;
            me.isProEmpty = false;
            me.searchLoading = true;
          }

          if (state instanceof chooseProLoadedState) {
            const me = this;

            for (let item of state.data.data) {
              if (item && item.accounts && item.accounts.length != 0) {
                for (let acc of item.accounts) {
                  acc["firmNameForFE"] = item.firm.name;
                }
              }
            }

            me.searchData = state.data.data;
            me.searchLoading = false;

            if (me.searchData && me.searchData.length === 0) {
              me.isProEmpty = true;
            } else {
              me.isProEmpty = false;
            }
          }
        },
        (state: chooseProLoadingErrorState) => {
          const me = this;
          me.isProEmpty = false;
          me.searchLoading = true;
        }
      );
  }

  closeDialog() {
    const me = this;
    this.dialogRef.close(false);
  }

  onChangeAddress() {
    const me = this;
  }
  handleAddressChange(event) {
    const me = this;
    me.googleLocationObj = {
      gPlaceId: event.place_id,
      address: event.formatted_address,
      gLatitude: Number(event.geometry.location.lat()),
      gLongitude: Number(event.geometry.location.lng()),
    };

    for (const component of event.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "country":
          me.googleLocationObj.gCountry = component.long_name;

          break;

        case "locality":
          me.googleLocationObj.gCity = component.long_name;

          break;

        case "administrative_area_level_1": {
          me.googleLocationObj.gState = component.long_name
            ? component.long_name
            : "";
          break;
        }

        case "administrative_area_level_2":
          me.googleLocationObj.gCounty = component.long_name;

          break;

        case "postal_code":
          me.createRequestForm.patchValue({
            zipCode: component.long_name ? component.long_name : "",
          });
          me.googleLocationObj.gZip = component.long_name;
          break;
      }
    }

    const { gZip, address, gState, gCity } = me.googleLocationObj;

    if (gZip) {
      me.zipNotAvailabel = false;
      me.createRequestForm.patchValue({
        address,
        state: gState,
        city: gCity,
      });
    } else {
      me.googleLocationObj = null;
      me.zipNotAvailabel = true;
    }
    me.disableAddressField();
  }

  disableAddressField() {
    const me = this;
    me.disableInputFlag = true;
    me.createRequestForm.get("city").disable();
    me.createRequestForm.get("state").disable();
    me.createRequestForm.get("zipCode").disable();
  }
  onSelectFile(event) {
    const me = this;
    me.prepareFilesList(event.target.files);
  }
  prepareFilesList(files: Array<any>) {
    this.showError = false;
    this.errorMsg = "";
    this.deleteImageFlag = false;

    for (const item of files) {
      //  5242880 - 5mb

      this.deleteImageFlag = true;
      if (item.size > 5242880) {
        this.showError = true;
        this.errorMsg = "Cannot upload image with size greater than 5MB ";
      } else if (
        item.type != "image/jpeg" &&
        item.type != "image/jpg" &&
        item.type != "image/png"
      ) {
        this.showError = true;
        this.errorMsg = "Allowed file types: jpeg, jpg , png";
      } else {
        if (this.urls.length <= 5) {
          const reader = new FileReader();
          this.selectedFile.push(item);

          reader.onload = (event: any) => {
            this.urls.push(event.target.result);
          };
          reader.readAsDataURL(item);
        } else {
          return;
        }
      }
    }
  }

  deleteImage(index) {
    const me = this;
    me.showError = false;
    if (me.urls.length <= 5) {
      me.urls.splice(index, 1);
      me.selectedFile.splice(index, 1);
    }
  }

  // Get service categories of firms...
  getServiceCategories() {
    const me = this;
    const payload = {
      sort: [
        {
          field: "name",
          order: "asc",
        },
      ],
      query: null,
      p: 0,
      pp: 100,
    };

    me.createRequestService
      .getAllServiceCategories(payload)
      .subscribe((state: Store.State) => {
        if (state instanceof serviceCategoriesLoadingState) {
          return;
        }

        if (state instanceof serviceCategoriesLoadedState) {
          me.serviceCategories = state?.data?.data?.content;
          me.$serviceCategories.next(me.serviceCategories);
          return;
        }

        (state: serviceCategoriesLoadingErrorState) => { };
      });
  }

  // Get service categories which the respective firm serve...
  getServiceCategoriesByFirm(firmId) {
    const me = this;
    me.createRequestService
      .getAllServiceCategoriesByFirm(firmId)
      .subscribe((state: Store.State) => {
        if (state instanceof serviceCategoriesByFirmLoadingErrorState) {
          return;
        }
        if (state instanceof serviceCategoriesByFirmLoadedState) {
          me.serviceCategories = state?.data?.data;
          me.$serviceCategories.next(me.serviceCategories);
          return;
        }

        (state: serviceCategoriesByFirmLoadingErrorState) => { };
      });
  }

  // Get list of addresses according to payload passed...
  getListOfAddresses() {
    const me = this;

    const payload = {
      query: "",
      filter: {
        keyIdentifier: "all",
      },
      sort: [
        {
          field: "createdAt",
          order: "asc",
        },
      ],
      p: 0,
      pp: 500,
    };

    me.homeOwnerProfileService
      .getListedProperties(payload)
      .subscribe((state: Store.State) => {
        if (state instanceof listedPropertiesLoadingState) {
          const me = this;
          return;
        }

        if (state instanceof listedPropertiesLoadedState) {
          const me = this;
          me.addressList = state?.data?.data.content;
          return;
        }

        (state: listedPropertiesLoadingErrorState) => {
          const me = this;
        };
      });
  }

  public handleCreateRequest(): void {
    const me = this;
    //  if direct or askallpros request and we dont have fullname or mobile number.
    if (
      (!me.userAccountDetails.fullName || !me.userAccountDetails.mobile) &&
      me.createRequestForm.valid
    ) {
      me.createRequestService.holdCreateRequestComponent = this;
      let dialogRef = this.dialog.open(GetAdditionalDataComponent, {
        width: "650px",
        disableClose: true,
      });
      me.dialogRef.close(false);
    } else {
      me.getReferral();
    }
  }

  // Get referral method: based on type of request...
  getReferral() {
    const me = this;
    const createRequestFormValues = me.createRequestForm.value;
    let mixPanelEventValues = {};
    let selectedReq;

    me.createRequestForm;
    selectedReq =
      me.createRequestForm.get("selectedReq").value === "directRequest"
        ? "direct"
        : "discovery";

    const formData = new FormData();
    for (const fileList of me.selectedFile) {
      formData.append("files", fileList);
    }
    formData.append("description", createRequestFormValues.description);
    formData.append("serviceRequesterId", me.userid);
    formData.append(
      "requestCompleteDuration",
      createRequestFormValues.requestFulfillDate
    );
    formData.append("isReferralNeeded", me.needReferralsCheckBoxValue ? "true" : "false");
    if (me.createRequestForm.get("serviceAddress").value === "newAddress") {
      let newGoogleLocationObj = { ...me.googleLocationObj };
      newGoogleLocationObj.apartmentNumber =
        createRequestFormValues.apartmentNumber
          ? createRequestFormValues.apartmentNumber
          : "";
      newGoogleLocationObj.account = { id: me.userid };
      newGoogleLocationObj.street = "";
      formData.append("address", JSON.stringify(newGoogleLocationObj));
      mixPanelEventValues = {
        ...mixPanelEventValues,
        serviceAddress: newGoogleLocationObj?.address,
      };
    } else {
      me.googleLocationObj = null;
      formData.append(
        "serviceRequesterAddressId",
        createRequestFormValues.serviceAddress?.id
      );
      mixPanelEventValues = {
        ...mixPanelEventValues,
        serviceAddress: createRequestFormValues.serviceAddress?.address,
      };
    }

    formData.append(
      "serviceCategory",
      createRequestFormValues.serviceCategory.id
        ? createRequestFormValues.serviceCategory.id
        : ""
    );

    if (selectedReq === "direct") {
      if (me.accountId) {
        formData.append("serviceRequestedForId", me.accountId);
        formData.append("isFirmSelected", "false");
      } else {
        formData.append("serviceRequestedForId", me.firmId);
        formData.append("isFirmSelected", "true");
      }
    }
    if (me.createRequestForm.status === "VALID") {
      const pageName = me.getPage();
      mixPanelEventValues = {
        ...mixPanelEventValues,
        source: pageName,
        selectedRequest: selectedReq,
        description: createRequestFormValues?.description,
        newAddress:
          me.createRequestForm.get("serviceAddress").value === "newAddress",
        serviceCategory: me.createRequestForm?.value?.serviceCategory?.name,
        myPros: me.dialogData?.dialogActivity?.page === "myPros",
      };
      if (me.mixPanelChoosePro?.firmName) {
        if (me.mixPanelChoosePro?.proName) {
          mixPanelEventValues = {
            ...mixPanelEventValues,
            selectedFirm: me.mixPanelChoosePro?.firmName,
            selectedPro: me.mixPanelChoosePro?.proName,
          };
        } else {
          mixPanelEventValues = {
            ...mixPanelEventValues,
            selectedFirm: me.mixPanelChoosePro?.firmName,
          };
        }
      }
      me.mixPanelChoosePro = {};
      me.createRequestService.getReferral(formData, selectedReq).subscribe(
        (state: Store.State) => {
          if (state instanceof createRequestLoadingState) {
            const me = this;
            me.onLoading(state);
            return;
          }

          if (state instanceof createRequestLoadedState) {
            const me = this;
            if (me.sessionService.mixPanleServiceEnabled) {
              const modifyMixpanelEventdata = {
                ...mixPanelEventValues,
                ...{ RequestId: state?.data?.data?.id },
              };
              if (me.isCheckboxClicked) {
                me.mixpanelCreateRequestEvent(modifyMixpanelEventdata);
              } else {
                me.mixpanelCreateRequestEvent(modifyMixpanelEventdata);
              }
              if (mixPanelEventValues["newAddress"]) {
                const mixPanelAddressEventObj = {
                  Label: me.googleLocationObj?.address,
                  "Zip Code": me.googleLocationObj?.gZip,
                  State: me.googleLocationObj?.gState,
                  City: me.googleLocationObj?.gCity,
                  Street: me.googleLocationObj?.address,
                  "Change Type": "Add",
                  Source: "Service Request",
                };
                me.mixpanelService.identify(me.userid);
                me.mixpanelService.setPeople({
                  [me.googleLocationObj.address]: me.googleLocationObj.address,
                });
                me.mixpanelService.sendEvent(
                  HOMEOWNER_ADDRESS_CHANGE,
                  mixPanelAddressEventObj
                );
              }
            }
            me.onLoaded(state);
            return;
          }
        },
        (state: createRequestLoadingErrorState) => {
          const me = this;
          me.onLoadingError(state);
        }
      );
    }
  }
  private onLoading(state: createRequestLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoaded(state: createRequestLoadedState) {
    const me = this;
    me.loading = false;
    const responseRequestType = state.data.data.requestType;
    if (state.data.status === "SERVICE_REQUEST_CREATED_SUCCESSFULLY") {

      me.myReferralsListService.notifyKey.next(true);

      me.snackBar.openSnackBar(state.data.message, 3000);
      me.firmId = "";
      me.accountId = "";
      if (responseRequestType == "DIRECT") {
        me.route.navigate(["/home-owner/services"], {
          queryParams: { activeTab: 1 },
        });
      } else {
        me.route.navigate(["/home-owner/my-referrals/open-tickets/"], {
          skipLocationChange: true,
        });
        // this.route.navigateByUrl('/', { skipLocationChange: true }).then(() =>

        // );
        // me.route.navigate(["/home-owner/my-referrals/list"])

        // me.route.navigateByUrl("/home-owner/my-referrals/list", {skipLocationChange: true});
      }
      if (me.selectedAddress == "newAddress") {
        console.log(responseRequestType);
        me.createRequestService.newAddressCreated.next(true);
      }
      me.createRequestForm.reset();

      me.dialogRef.close(true);
    }
  }

  private onLoadingError(state: createRequestLoadingErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = false;
    if (state?.error?.error?.status === "PLEASE_PROVIDE_OTHER_LABEL") {
      me.snackBar.openSnackBar(
        "Address you are trying to enter is already exists in your profile",
        3000
      );
    } else {
      me.snackBar.openSnackBar(state?.error?.error?.message, 3000);
    }
  }

  secondaryLabel(items) {
    const me = this;
    let accountName = "";
    if (items.length) {
      for (let item of items) {
        accountName += " " + item.fullName;
      }
    }
    return accountName;
  }
  onFirmSelect(obj) {
    const me = this;
    me.firmId = obj.id;
    me.mixPanelChoosePro = {};
    me.mixPanelChoosePro = { firmName: obj.name };
    me.accountId = "";
  }
  onAccountSelect(obj, firm) {
    const me = this;
    me.firmId = "";
    me.accountId = obj.id;
    me.mixPanelChoosePro = {};
    me.mixPanelChoosePro = { firmName: firm.name, proName: obj.fullName };
  }
  disp(obj): string {
    const me = this;
    if (obj?.name) {
      return obj.name;
    }
    if (obj?.firmNameForFE && obj.type == "PRO") {
      return `${obj?.firmNameForFE} - ${obj?.fullName}`;
    }
    return "";
  }

  //Reset the create request form...
  onClickReset() {
    const me = this;
    me.createRequestForm.get("street").reset();
    me.createRequestForm.get("city").reset();
    me.createRequestForm.get("state").reset();
    me.createRequestForm.get("zipCode").reset();
    me.createRequestForm.get("apartmentNumber").reset();
  }

  // Validator Condition for proName of createRequestForm.
  onItemChange(event) {
    const me = this;
    if (event.value === "directRequest") {
      me.createRequestForm.controls["askAllprosCheckbox"].setValue(false);
      me.isCheckboxClicked = false;
      me.createRequestForm.controls["proName"].setValidators([
        Validators.required,
      ]);
      me.createRequestForm.get("serviceCategory").enable();
    } else {
      me.createRequestForm.get("proName").clearValidators();
      me.createRequestForm.get("proName").updateValueAndValidity();
    }
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.$serviceCategories
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a.id === b.id;
      });
  }

  protected filterCategory() {
    if (!this.serviceCategories) {
      return;
    }
    // get the search keyword
    let search = this.serviceCategoryValue.value;
    if (!search) {
      this.$serviceCategories.next(this.serviceCategories.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.$serviceCategories.next(
      this.serviceCategories.filter(
        (category: any) => category.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private getPage() {
    const me = this;
    const url = me.route.url;
    if (url.includes("services")) {
      return "Services";
    } else if (url.includes("my-referrals")) {
      return "My Referrals";
    } else if (url.includes("my-pros")) {
      return "My Pros";
    } else {
      return "Unknown";
    }
  }

  // send event on create direct or discovery request with event property.
  private mixpanelCreateRequestEvent(createRequestEventProperties: any): void {
    const me = this;
    if (createRequestEventProperties?.selectedRequest === "discovery") {
      const eventProperties = {
        Source: createRequestEventProperties?.source,
        "Service Category": createRequestEventProperties?.serviceCategory,
        "Service Address": createRequestEventProperties?.serviceAddress,
        "New Address": createRequestEventProperties?.newAddress,
        Description: createRequestEventProperties?.description,
        "Request ID": createRequestEventProperties.RequestId,
      };
      me.mixpanelService.sendEvent(
        HOMEOWNER_DISCOVERY_REQUEST,
        eventProperties
      );
      return;
    }

    if (createRequestEventProperties?.selectedRequest === "direct") {
      const eventProperties = {
        MyPros: createRequestEventProperties.myPros,
        Source: createRequestEventProperties?.source,
        "Service Category": createRequestEventProperties?.serviceCategory,
        "Service Address": createRequestEventProperties?.serviceAddress,
        "Selected Firm": createRequestEventProperties?.selectedFirm,
        "Selected Pro": createRequestEventProperties?.selectedPro,
        "New Address": createRequestEventProperties?.newAddress,
        Description: createRequestEventProperties?.description,
        "Request ID": createRequestEventProperties.RequestId,
      };
      me.mixpanelService.sendEvent(HOMEOWNER_DIRECT_REQUEST, eventProperties);
      return;
    }
    if (me.isCheckboxClicked) {
      const eventProperties = {
        Source: createRequestEventProperties?.source,
        "Service Address": createRequestEventProperties?.serviceAddress,
        "New Address": createRequestEventProperties?.newAddress,
        Description: createRequestEventProperties?.description,
        "Request ID": createRequestEventProperties.RequestId,
      };
      me.mixpanelService.sendEvent(
        HOMEOWNER_ASKALLPROS_REQUEST,
        eventProperties
      );
      return;
    }
  }
  checkboxClicked(event: MatCheckboxChange) {
    const me = this;
    me.needReferralsCheckBoxValue = event.checked;
  }

  getRequestCompletionData() {
    const me = this;
    me.createRequestService
      .getRequestCompletion()
      .subscribe((state: Store.State) => {
        if (state instanceof requestCompletionLoadingState) {
          const me = this;
          return;
        }

        if (state instanceof requestCompletionLoadedState) {
          me.requestData = state.data.data;
          return;
        }

        (state: requestCompletionLoadingErrorState) => {
          const me = this;
        };
      });
  }
}
