import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cometchat'
})
export class CometchatPipe implements PipeTransform {

  transform(groupArr: any[] = [], order: string, key: string): any[] {

    if (!key && !order) {
      return groupArr;
    }

    if (key) {
      if (order == 'asc') {
        return groupArr.sort((a, b) => {
          if (a?.lastMessage?.[key] > b?.lastMessage?.[key]) return 1
          if (a?.lastMessage?.[key] < b?.lastMessage?.[key]) return -1
          return 0;
        });
      } else {
        return groupArr.sort((a, b) => {
          if (a?.lastMessage?.[key] > b?.lastMessage?.[key]) return 1
          if (a?.lastMessage?.[key] < b?.lastMessage?.[key]) return -1
          return 0;
        }).reverse();
      }

    }
    return groupArr;
  }

}
