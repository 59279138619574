import { Store } from "src/app/core/store/store";

export class serviceCategoriesLoadingState extends Store.AbstractLoadingState<any> {}
export class serviceCategoriesLoadingErrorState extends Store.AbstractErrorState<any> {}
export class serviceCategoriesLoadedState extends Store.AbstractIdealState<any> {}

export class serviceCategoriesByFirmLoadingState extends Store.AbstractLoadingState<any> {}
export class serviceCategoriesByFirmLoadingErrorState extends Store.AbstractErrorState<any> {}
export class serviceCategoriesByFirmLoadedState extends Store.AbstractIdealState<any> {}

export class createRequestLoadingState extends Store.AbstractLoadingState<any> {}
export class createRequestLoadingErrorState extends Store.AbstractErrorState<any> {}
export class createRequestLoadedState extends Store.AbstractIdealState<any> {}

export class chooseProLoadingState extends Store.AbstractLoadingState<any> {}
export class chooseProLoadingErrorState extends Store.AbstractErrorState<any> {}
export class chooseProLoadedState extends Store.AbstractIdealState<any> {}

export class requestCompletionLoadingState extends Store.AbstractLoadingState<any> {}
export class requestCompletionLoadingErrorState extends Store.AbstractErrorState<any> {}
export class requestCompletionLoadedState extends Store.AbstractIdealState<any> {}
