import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { SessionService } from "src/app/core/session/session.service";
import { CreateRequestComponent } from "../../pages/home-owner/dialogs/create-request/create-request.component";
import { Sort } from "../../pages/network/make-refferal/service/make-refferal.model";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  homeAssistPreferredProLogo: String;
  homeAssistPreferredProName: String;
  orignalInviter: any;
  subscription: Subscription;
  pageNumber: number = 0;
  serviceRequestList: any = [];
  serviceRequestListdata: any;
  sort: Sort[] = [
    {
      field: "createdAt",
      order: "desc",
    },
  ];

  constructor(
    private dialog: MatDialog,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    const me = this;

    me.subscription = me.sessionService.homeAssistPreferredData$.subscribe(
      (res) => {
        
        me.orignalInviter=res;
        me.homeAssistPreferredProName = res?.name;
        me.homeAssistPreferredProLogo = res?.logoPath;
      }
    );
  }

  // New service request dialog...
  openDialog(status, element?: any): void {
    const me = this;
    let dialogRef = this.dialog.open(CreateRequestComponent, {
      width: "650px",
      data: {
        element,
        status,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // console.log('here')
        // window.location.href="home-owner/my-referrals/list"
        me.doDefaultRequestList();
        // me.getAllServiceList(me.userid, me.addressId);
      }
    });
  }

  doDefaultRequestList() {
    const me = this;
    me.pageNumber = 0;
    me.sort = [
      {
        field: "createdAt",
        order: "desc",
      },
    ];
    me.serviceRequestListdata = null;
    me.serviceRequestList = [];
  }
  ngOnDestroy() {
    const me = this;
    me.subscription.unsubscribe();
  }
}
