import { SessionService } from "src/app/core/session/session.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import {
  homeOwnerAddressDataLoadedState,
  homeOwnerAddressDataLoadingErrorState,
  homeOwnerAddressDataLoadingState,
} from "src/app/pages/home-owner/home-owner-profile/service/home-owner-profile.state";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class SidenavService {
  addressSelected: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  $addressSelected: Observable<any> = this.addressSelected.asObservable();

  // getListOfAddress( userId:string): Observable<Store.State> {
  //     const me = this;
  //     const output = new Subject<Store.State>();

  //     setTimeout(() => {
  //       output.next(new homeOwnerAddressDataLoadingState());
  //     }, 0);
  //     const path = me.controller.replaceVariables(
  //       environment.api.homeOwner.getListOfAddress.endpoint,
  //       { userId: userId},
  //     );

  //     me.controller.get(path, null, undefined, {Authorization:true}).subscribe(
  //       (data: any) => {
  //         output.next(new homeOwnerAddressDataLoadedState(data));
  //         output.complete();
  //       },
  //       (e: any) => {
  //         output.error(new homeOwnerAddressDataLoadingErrorState(e));
  //         output.complete();
  //       }
  //     );

  //     return output;
  //   }
}
