import { Injectable, Optional } from "@angular/core";
// import { AngularFireMessaging } from "@angular/fire/messaging/messaging";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { CometChat } from "@cometchat-pro/chat";
import { Observable, Subject } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
import { SnackBarService } from "../common/services/snack-bar.service";
import { FcmTokenRegisterPayload } from "./push-notification.model";
import {
  PushNotificationTokenErrorState,
  PushNotificationTokenLoadedState,
  PushNotificationTokenLoadingState,
} from "./push-notification.state";
@Injectable({
  providedIn: "root",
})
export class PushNotificationService extends Store.AbstractService {
  private accountId: string;
  public fcmToken: string;

  constructor(
    private afMessaging: AngularFireMessaging,
    private snackBarService: SnackBarService
  ) {
    super();
  }

  /*
  to get token from AngularFireMessaging.
  */
  public getToken(accountId: string, cometchatUid: string) {
    const me = this;
    me.accountId = accountId;
    try {
      me.afMessaging.getToken.subscribe(
        (token: string) => {
          if (token) {
            me.fcmToken = token;
            const fcmTokenRegisterPayload: FcmTokenRegisterPayload = {
              accountId: me.accountId,
              deviceType: "Web",
              fcmToken: token,
            };
            me.registerPushNotificationToken(fcmTokenRegisterPayload);
            if (cometchatUid) {
              // start comet chat push notification service.
              CometChat.registerTokenForPushNotification(token);
            }
            this.listen();
          } else {
            me.requestPermissionAndGetToken(me.accountId, cometchatUid);
          }
        },
        (error) => {  
          console.log("error ", error);
          Notification.requestPermission();

        }
      );
    } catch (error) {
      console.log("error ", error);
    }
//     this.afMessaging.requestPermission.subscribe(permission => {
//       console.log("permission ", permission);

//     }, error => {
//       console.log("error ", error);
// Notification.requestPermission()
//     })
  }

  // get permission and token at first time.
  public requestPermissionAndGetToken(
    accountId: string,
    cometchatUid?: string
  ): void {
    const me = this;
    try {
      this.afMessaging.requestToken.subscribe(
        (token) => {
          if (token) {
            me.fcmToken = token;
            const fcmTokenRegisterPayload: FcmTokenRegisterPayload = {
              accountId: accountId,
              deviceType: "Web",
              fcmToken: token,
            };
            me.registerPushNotificationToken(fcmTokenRegisterPayload);
            if (cometchatUid) {
              CometChat.registerTokenForPushNotification(token);
            }
            this.listen();
          }
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) { }
  }

  //delete token on logout.
  public deleteToken(): void {
    try {
      this.afMessaging.getToken
        .pipe(mergeMap((token) => this.afMessaging.deleteToken(token)))
        .subscribe((token) => {
          console.log("Token deleted!");
        });
    } catch (error) {
      console.log("error ", error);
    }
  }

  //start realtime foreground messaging.
  public listen(): void {
    const me = this;
    this.afMessaging.messages.subscribe((message: any) => {
      if (message?.notification?.body && message.data.hasOwnProperty("notificationData")) {
        // me.snackBarService.openSnackBar(message?.notification?.title, 3000);
        me.snackBarService.customSnackbar(
          message?.notification?.title,
          message?.notification?.body,
          3000
        );
      }
    });
  }

  //save token into allpros database.
  private registerPushNotificationToken(
    fcmTokenRegisterPayload: FcmTokenRegisterPayload
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new PushNotificationTokenLoadingState());
    }, 0);

    const path = environment.api.auth.fcmPushNotification.endpoint;

    me.controller
      .post(path, fcmTokenRegisterPayload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new PushNotificationTokenLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new PushNotificationTokenErrorState(e));
          output.complete();
        }
      );

    return output;
  }
}
