import { Store } from "src/app/core/store/store";
import { isDevMode } from "@angular/core";

export const environment = {
  production: false,
  localstorage: {
    key: "allpros",
  },

  stripeKey: {
    value: window["env"].STRIPE_KEY,
  },

  applicationVersion: "3.17.2",

  firebase: {
    apiKey: window["env"].FIREBASE_APIKEY,
    authDomain: window["env"].FIREBASE_AUTHDOMAIN,
    projectId: window["env"].FIREBASE_PROJECTID,
    storageBucket: window["env"].FIREBASE_STORAGEBUCKET,
    messagingSenderId: window["env"].FIREBASE_MESSAGINGSENDERID,
    appId: window["env"].FIREBASE_APPID,
    measurementId: window["env"].FIREBASE_MEASUREMENTID,
  },

  mixpanelKeys: {
    projectToken: window["env"].MIXPANEL_PROJECTTOKEN,
  },

  cometChatKeys: {
    appId: window["env"].COMETCHAT_APPID,
    region: window["env"].COMETCHAT_REGION,
    authKey: window["env"].COMETCHAT_AUTHKEY,
    apiKey: window["env"].COMETCHAT_APIKEY,
  },

  facebookAppId: "704902207211098", //client id
  facebookPlatformVersion: "v2.4", //js sdk version

  configuration: {
    // on/off consumption
    creditsUse: false,
  },

  api: {
    core: {
      base: "/api",
    } as Store.APIConfig,

    websocket: {
      stomp: {
        endpoint: "/socket/websocket",
      },
    } as Store.APIConfigGroup,

    chatting: {
      getChatUserId: {
        endpoint: "/account/meta/get-chat-userId",
      },
      getUserList: {
        endpoint: "/chat/get-accounts/{groupId}",
      },
      addMembersIntoGroup: {
        endpoint: "/chat/add-member-to-group/{groupId}",
      },
      getServiceCategoryImages: {
        endpoint: "/service-request/get-request-images-by-guid/{groupId}",
      },
      uploadFilesToServer: {
        endpoint: "/chat/upload-document/{documentType}",
      },
      getUploadFilesToServer: {
        endpoint: "/service-quote/get-quote-summery-list/{cometChatId}",
      },
    } as Store.APIConfigGroup,

    auth: {
      me: {
        endpoint: "/auth/me",
      },
      validate: {
        endpoint: "/auth/validate",
      },
      forgotPassword: {
        endpoint: "/auth/forgot-password",
      },
      verifyOtp: {
        endpoint: "/auth/otp/token",
      },
      verifyEmail: {
        endpoint: "/auth/verify-email",
      },
      homeOwnerLogin: {
        endpoint: "/auth/homeowner/login",
      },
      fcmPushNotification: {
        endpoint: "/auth/register-fcm_token",
      },
      details: {
        endpoint: "/api-config?platform=web",
      },
    } as Store.APIConfigGroup,

    account: {
      deleteAccount: {
        endpoint: "/account/{userId}/undo-delete-account",
      },
      read: {
        endpoint: "/account/{ID}",
      },
      register: {
        endpoint: "/account/register",
      },
      homeOwnerRegister: {
        endpoint: "/homeowner/register",
      },
      getAccountDetails: {
        endpoint: "/account/{Firmid}",
      },

      registerforProUser: {
        // endpoint: '/account/register?sendEmail={sendEmail}'
        endpoint: "/account/register",
      },
      getByEmail: {
        endpoint: "/account/get-by-email",
      },
      getAllFirmMember: {
        endpoint: "/account/members-of-firm",
      },
      updateAccount: {
        endpoint: "/account/{id}/update",
      },
      // checkFirmAvaiblity: {
      //   endpoint: "/account/firm/{firmName}/check-firm-available"
      // },
      checkFirmAvailability: {
        endpoint: "/account/firm/check-firm-available",
      },
      firmSearch: {
        endpoint: "/refer-request/public/search",
      },
      source: {
        endpoint: "/api-config/get-signup-source",
      },
    } as Store.APIConfigGroup,

    facebook: {
      me: {
        endpoint: "/me/",
      },
      permissions: {
        endpoint: "/me/permissions",
      },
    } as Store.APIConfigGroup,

    invite: {
      getInviteDetailUsingToken: {
        endpoint: "/invite/{token}/validate-token",
      },
      forwardInvitation: {
        endpoint: "/invite/forward-invite",
      },
    } as Store.APIConfigGroup,

    location: {
      states: {
        endpoint: "/state/country/{countryCode}/",
      },
      city: {
        endpoint: "/city/country/{countryCode}/state/{stateCode}",
      },
      zipCode: {
        endpoint: "/zip/country/{countryCode}/state/{stateCode}/city/{cityId}",
      },
    } as Store.APIConfigGroup,

    session: {
      login: {
        endpoint: "/auth/login",
      },
      logout: {
        endpoint: "/auth/logout",
      },
      token: {
        endpoint: "/auth/token",
      },
      tokenRefresh: {
        endpoint: "/token/refresh",
      },
    },

    team: {
      myteamlist: {
        // endpoint: '/firm/{Firmid}/team/list',   // Deprecated
        endpoint: "/firm/team-list",
      },
      updatemyteamlist: {
        endpoint: "/account/{accountId}/update",
      },
      updateAddProlist: {
        endpoint: "/account/{accountId}/update",
      },
    },

    firm: {
      getFirmInfo: {
        endpoint: "/firm/firm-details/{firmId}",
      },
      getServiceCoverageForFirmPage: {
        endpoint: "/service-coverage/get-service-coverage-for-firm-page",
      },
      unauthGetFirmInfo: {
        endpoint: "/firm/public/firm-details/{firmId}",
      },
      unauthGetServiceCoverageForFirmPage: {
        endpoint: "/service-coverage/public/get-service-coverage-for-firm-page",
      },
      unauthGetGallery: {
        endpoint: "/firm/public/gallery-image",
      },
      unauthGetAllFirmMember: {
        endpoint: "/account/public/members-of-firm",
      },
      getHomeAssistPreferredProsDetails: {
        endpoint:
          "/firm-group/home-assist-preferred-list?serviceRequestID={id}",
      },
      getHomeAssistPreferredProsCardList: {
        endpoint: "/firm-group/home-assist-preferred-list",
      },
      postReqReferral: {
        endpoint: "/service-request/update-referral-status-key",
      },
    },

    network: {
      referralsList: {
        endpoint: "/reference/list",
      },
      refer: {
        endpoint: "/reference/proceed/refer",
      },
      // TODO:previous API
      validateRefer: {
        endpoint: "/reference/validate/refer",
      },
      // new endpoint for validate referral
      validateReferral: {
        endpoint: "/reference/validate/{actionName}/invite-reference",
      },
      //new endpoint proceed referral
      proceedReferral: {
        endpoint: "/reference/proceed/{actionName}/invite-reference",
      },
      receivedReferralsFirms: {
        endpoint: "/reference/{accountId}/referral-received-firms",
      },
      sentReferralsFirms: {
        endpoint: "/reference/{accountId}/referred-firms",
      },
      getProFirmServiceCategories: {
        endpoint: "/reference/get-pro-firm-service-categories",
      },
      // referback:{
      //   endpoint:"/refer-request/referback"
      // },
      updateReference: {
        endpoint: "/reference/{proId}/update",
      },
      referback: {
        endpoint: "/refer-request/referback",
        // endpoint: "/reference/{referenceId}/update"
      },
      referBackList: {
        endpoint: "/refer-request/my-referback-requests",
      },
      referRequestAction: {
        endpoint:
          "/refer-request/action/{referRequestActionId}/{requestStatus}",
      },
      referralSearch: {
        endpoint: "/refer-request/search",
      },
    },

    services: {
      acceptDeclineRequest: {
        endpoint: "/service-quote/{requestId}/update",
      },

      getQuoteRequestDetails: {
        endpoint: "/service-quote/my-service-quotes",
      },

      getFilterCategory: {
        endpoint:
          "/service-request/get-filter-service-category-list?type={type}",
      },
    },

    myClient: {
      inviteHomeOwner: {
        endpoint: "/invite/invite-homeowner",
      },
      getMyClient: {
        endpoint: "/invite/home-owner-list",
      },
    },

    notification: {
      getNotification: {
        endpoint: "/in-app-notifications/list",
      },
      updateNotification: {
        endpoint: "/in-app-notifications/glance",
      },
      updateNotificationGlance: {
        endpoint:
          "/service-request/notification-glance?serviceRequestId={serviceRequestId}&accountId={accountId}",
      },
    },

    settings: {
      businessAccount: {
        getBusinessAccount: {
          endpoint: "/firm",
        },
      },
      adminAccount: {
        getAdminAccount: {
          endpoint: "/account",
        },
        update: {
          endpoint: "/account/{accountId}/update",
        },
        changePassword: {
          endpoint: "/account/{accountId}/profile/change-password",
        },
        verifyEmail: {
          endpoint: "/account/email/{email}/check-availability",
        },
        forgotPassword: {
          endpoint: "/account/{accountId}/profile/change-password",
        },
        notificationControl: {
          endpoint: "/account/notification-consent/{notifyKey}",
        },
      },
      billingInfo: {
        getBillingInfo: {
          endpoint: "/firm",
        },
      },
      serviceCategory: {
        getServiceCategory: {
          endpoint: "/firm",
        },
        list: {
          endpoint: "/servicecategory/",
        },
        update: {
          endpoint: "/firm/{firmID}/service-category",
        },
        galleryUpload: {
          endpoint: "/firm/{firmID}/gallery-image/upload",
        },
        getGallery: {
          endpoint: "/firm/gallery-image",
        },
        deleteImage: {
          endpoint: "/firm/gallery-image/{imageId}/remove",
        },
      },
      serviceCoverage: {
        getServiceCoverage: {
          endpoint: "/service-coverage/get-service-coverage",
        },
        updateServiceCoverage: {
          endpoint: "/service-coverage/set-service-coverage",
        },
      },
    },

    file: {
      upload: {
        endpoint: "/firm/{firmID}/attachment/{type}/upload",
      },
      deleteLogo: {
        endpoint: "/firm/{firmID}/{type}/delete",
      },
      uploadDocumentFile: {
        endpoint: "/firm/firm-document-upload/{type}",
      },
      viewDocuments: {
        endpoint: "/firm/firm-documents/{firmId}",
      },
      deleteDocument: {
        endpoint: "/firm/delete-firm-document-Id/{documentId}",
      },
    },
    payment: {
      initiateTransaction: {
        endpoint: "/leads-transaction/create-payment-intent",
      },
      updateTransaction: {
        endpoint: "/leads-transaction/update-payment-gateway-response",
      },
      checkStatusBeforePayment: {
        endpoint: "/leads-transaction/check-payment-allowed/{requestId}",
      },
      cancelPayment: {
        endpoint: "/leads-transaction/cancel-payment-intent",
      },
      updatePaymentError: {
        endpoint: "/leads-transaction-attempts/create-payment-attempt",
      },
      updateQuoteByPromotion: {
        endpoint: "/service-quote/accept-quote-by-promotional-waiver",
      },
      zeroBalanceCredit: {
        endpoint:
          "/leads-transaction/zero-payment-gateway-response-from-stripe",
      },
    },
    homeOwner: {
      upload: {
        endpoint: "/account/{userid}/upload",
      },
      deleteProfile: {
        endpoint: "/account/{userid}",
      },
      addAddress: {
        endpoint: "/address/register",
      },
      updateAddress: {
        endpoint: "/address/{addressId}/update",
      },
      getListOfAddress: {
        endpoint: "/address/list",
      },
      updateHomeOwner: {
        endpoint: "/homeowner/update/{userid}",
      },
      createServiceRequest: {
        endpoint: "/service-request/create-service-request/{serviceType}",
      },
      requestCompletion: {
        endpoint: "/service-request/get-request-completion-drop_down",
      },
      homeOwnerService: {
        getAllServiceRequest: {
          endpoint: "/service-request/list",
        },
        getAllMembers: {
          endpoint: "/account/members-of-firm",
        },
      },
      pro: {
        invitePro: {
          endpoint: "/invite/invite-pro",
        },
        invitedProList: {
          endpoint: "/invite/home-owner-list",
        },
      },
      serviceRequest: {
        updateDocumentStatus: {
          endpoint: "/service-quote/update-document-status",
        },
        allServiceRequest: {
          endpoint: "/service-request/list",
        },
        recommendations: {
          endpoint: "/recommendation/get-list-of-firms/{requestId}",
        },
        closeServiceTicket: {
          endpoint:
            "/service-request/update-publish-status-key/{serviceRequestId}",
        },
        favoritePros: {
          addFavPros: {
            endpoint: "/favourite-pros/add-favourite-pros/{toggleText}",
          },
          getAllFavProsWithSearch: {
            endpoint:
              "/favourite-pros/get-favourite-pros-list/{userId}?firm={firmName}",
          },
          getAllFavProsServiceRequestId: {
            endpoint:
              "/favourite-pros/get-favourite-pros-list/{userId}?serviceRequestID={serviceRequestId}",
          },
          getAllUnfavoriteProsLits: {
            endpoint: "/favourite-pros/get-unfavourite-pros-list",
          },
        },
        quoteRequested: {
          addQuote: {
            endpoint: "/service-quote/create",
          },
          getQuote: {
            endpoint: "/service-quote/my-service-quotes",
          },
        },
        quoteDetails: {
          endpoint: "/service-quote/get-quote-summery-list/{serviceRequestId}",
        },
        serviceTicketData: {
          endpoint: "/service-request/get-service-request/{serviceTicketId}",
        },
        downloadPdf: {
          endpoint: "/service-quote/export-pdf",
        },
      },
      getAllFavouritePros: {
        // endpoint: "/favourite-pros/get-favourite-pros/{userId}",
        endpoint:
          "/favourite-pros/get-favourite-pros-list/{userId}?serviceRequestID={requestId}",
      },
      getAllFavouriteProsWithFilter: {
        // endpoint: "/favourite-pros/get-favourite-pros/{userId}",
        endpoint:
          "/favourite-pros/get-favourite-pros-list/{userId}?firm={queryString}",
      },
      choosePro: {
        endpoint:
          "/homeowner/search-pros?searchValue={queryString}&serviceCategoryId={category}",
      },
      chooseProWithFirm: {
        endpoint:
          "/homeowner/search-pros?firmID={firmId}&searchValue={queryString}&serviceCategoryId={category}",
      },
      propertyManager: {
        viewProperties: {
          endpoint: "/address/get-listed-properties",
        },
        assignProperty: {
          endpoint: "/account-property/assign-property-to-account",
        },
        search: {
          endpoint: "/account-property/account-list",
        },
      },
      propertyManagerAdmin: {
        listPropertyManagers: {
          endpoint: "/account-property/firm-account-list",
        },
      },
    },

    deepLink: {
      getFullUrl: {
        endpoint: "/deep_linking/get_shortened_urls?uniqueId={uniqID}",
      },
    },
    credits: {
      getListOfCredits: {
        endPoint: "/credit-system/credits-list",
      },
      totalCredits: {
        endPoint: "/credit-system/credit-breakdown",
      },
    },
  },

  formats: {
    date: {
      // Moment JS date formats
      default: "MM-DD-YYYY",
      long: "D-MMM-YYYY",
      short: "D-MMM-YYYY",
    },
    dateTime: {
      // Moment JS date formats
      default: "MM-DD-YYYY HH:mm:ss",
    },
  },
};

// if (isDevMode()) {        //TODO: temp change.
//   // environment.api.core.base = 'https://d3haw2uhk38dxc.cloudfront.net/api';  // uat server
//   // environment.api.core.base = 'https://leftrightmind.mockable.io/allpros/api';    //mockable
//   // environment.api.core.base = 'http://allprosdev1-env.eba-b3ba6tbs.us-east-1.elasticbeanstalk.com/api'
//   // environment.api.core.base = 'https://d1fwym6igs527s.cloudfront.net/api';  //AllPros dev server
//   // environment.api.core.base = 'https://d8gewzv3bc5pz.cloudfront.net/api';  //allpros QA
// }
