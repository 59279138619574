import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material-module';
import { PipesModule } from '../pipes/pipes.module';
import { DataHandlerComponent } from './data-handler.component';

const components = [DataHandlerComponent];
const moduleDependencies = [MaterialModule, PipesModule];

@NgModule({
    declarations: [components],
    entryComponents: components,
    imports: moduleDependencies,
    exports: [moduleDependencies, components]
})

export class DataHandlerModule { }
