import { AppError } from './../../../core/error/error.model';
import { Store } from "src/app/core/store/store";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "../CONSTS";
import { SocketService } from "src/app/shared/messaging/socket.service";
import { environment } from "src/environments/environment";
import {
  AddGroupMemberErrorState,
  AddGroupMemberLoadedState,
  AddGroupMemberLoadingState,
  GetUploadedFileCometChatErrorState,
  GetUploadedFileCometChatLoadedState,
  GetUploadedFileCometChatLoadingState,
  MemberListErrorState,
  MemberListLoadedState,
  MemberListLoadingState,
  UploadFileCometChatErrorState,
  UploadFileCometChatLoadedState,
  UploadFileCometChatLoadingState,
} from "./comet-chat.state";
import {
  AddMemberPayload,
  CometChatMemberPayload,
  MemberInfo,
  UploadFilePayload,
} from "./comet-chat.model";
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: "root",
})
export class CometChatCustomService extends Store.AbstractService {
  // private readonly appID: string = COMETCHAT_CONSTANTS.APP_ID;
  // private readonly region: string = COMETCHAT_CONSTANTS.REGION;
  private readonly authKey: string = COMETCHAT_CONSTANTS.AUTH_KEY;
  private readonly apiKey: string = COMETCHAT_CONSTANTS.API_KEY;
  private readonly appId: string = COMETCHAT_CONSTANTS.APP_ID;
  private readonly region: string = COMETCHAT_CONSTANTS.REGION;


  public onChattingAccountCreated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly $onChattingAccountCreated: Observable<boolean> =
    this.onChattingAccountCreated.asObservable();

  public loggedinUserInfo: CometChat.User;

  constructor(private httpClient: HttpClient) {
    super();
  }

  /**
   * initialize comet chat sdk into application.
   * @requires applicationId
   * @requires region
   */
  public init(cometchatAccountId?: string): void {
    const me = this;

    // const appSetting = new CometChat.AppSettingsBuilder()
    //   .subscribePresenceForAllUsers()
    //   .setRegion(me.region)
    //   .autoEstablishSocketConnection(true)
    //   .build();
    // CometChat.init(me.appID, appSetting).then(
    //   () => {
    // console.log("Initialization completed successfully");
    me.login(cometchatAccountId);
    // },
    // (error) => {
    // console.log("Initialization failed with error:", error);
    // }
    // );
  }

  /**
   * login user into the comet chat SDK.
   * @param uid
   * @requires authKey
   * @returns Observable <boolean>
   */
  public login(uid: string): Observable<boolean> {
    const me = this;
    const output = new Subject<boolean>();

    CometChat.getLoggedinUser().then(
      (user: CometChat.User) => {
        if (!user) {
          CometChat.login(uid, me.authKey).then(
            (user: CometChat.User) => {
              me.loggedinUserInfo = user;
              me.onChattingAccountCreated.next(true);
              output.next(true);
              output.complete();
            },
            (error) => {
              console.log("Login failed with exception:", { error });
              output.error(true);
              output.complete();
            }
          );
        } else {
          me.loggedinUserInfo = user;
          if (uid == null) {
            me.logout().subscribe(isLogout => {
              if (isLogout) {
                me.onChattingAccountCreated.next(false);
              }
            })
            return;
          }
          if (me.loggedinUserInfo.getUid() !== uid) {
            me.onChattingAccountCreated.next(false);
            me.logout().subscribe((isLogout) => {
              if (isLogout) {
                me.login(uid);
                return;
              }
            });
          }
          me.onChattingAccountCreated.next(true);
        }
      },
      (error) => {
        console.log("Something went wrong", error);
      }
    );
    return output;
  }

  /**
   * logout login user from comet chat SDK.
   * @returns Observable <boolean>
   */
  public logout(): Observable<boolean> {
    const output = new Subject<boolean>();
    CometChat.logout().then(
      () => {
        output.next(true);
        output.complete();
      },
      (error) => {
        console.log("Logout failed with exception:", { error });
        output.error(false);
        output.complete();
      }
    );

    return output;
  }


  //not in use do not remove.
  public getMemberList(
    groupId: string,
    requestPayload: CometChatMemberPayload
  ): Observable<Store.State> {

    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new MemberListLoadingState());
    }, 0);

    const path = me.controller.replaceVariables(
      environment.api.chatting.getUserList.endpoint,
      { groupId: groupId },
    );

    me.controller
      .post(path, requestPayload, undefined, { Authorization: true })
      .subscribe(
        (data: MemberInfo[]) => {
          output.next(new MemberListLoadedState(data));
          output.complete();
        },
        (e: AppError) => {
          output.error(new MemberListErrorState(e));
          output.complete();

          me.logger.error("error", e);
        }
      );

    return output;
  }

  //not in use do not remove.
  public addMemberIntoGroup(groupId,
    addGroupMemberPayload: AddMemberPayload[]
  ): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new AddGroupMemberLoadingState());
    }, 0);

    const path = me.controller.replaceVariables(environment.api.chatting.addMembersIntoGroup.endpoint, { groupId });

    me.controller
      .post(path, addGroupMemberPayload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new AddGroupMemberLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new AddGroupMemberErrorState(e));
          output.complete();
          me.logger.error("error", e);
        }
      );

    return output;
  }

  public uploadFile(
    requestPayload: FormData,
    docType
  ): Observable<Store.State> {

    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new UploadFileCometChatLoadingState());
    }, 0);

    const path = me.controller.replaceVariables(
      environment.api.chatting.uploadFilesToServer.endpoint,
      { documentType: docType },
    );

    me.controller
      .post(path, requestPayload, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new UploadFileCometChatLoadedState(data));
          output.complete();
        },
        (e: AppError) => {
          output.error(new UploadFileCometChatErrorState(e));
          output.complete();

          me.logger.error("error", e);
        }
      );

    return output;
  }

  public getUploadedFiles(id): Observable<Store.State> {

    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new GetUploadedFileCometChatLoadingState());
    }, 0);

    const path = me.controller.replaceVariables(
      environment.api.chatting.getUploadFilesToServer.endpoint,
      { cometChatId: id },
    );

    me.controller
      .get(path, undefined, undefined, { Authorization: true })
      .subscribe(
        (data: any) => {
          output.next(new GetUploadedFileCometChatLoadedState(data));
          output.complete();
        },
        (e: AppError) => {
          output.error(new GetUploadedFileCometChatErrorState(e));
          output.complete();

          me.logger.error("error", e);
        }
      );

    return output;
  }

  updateChatMessage(message) {
    const me = this;
    return me.updateChatMessageFromAPI(message.id, message.sender, message)
  }

  updateChatMessageFromAPI(id, onBehalfOf, message) {
    const me = this;
    let header: HttpHeaders = new HttpHeaders;
    header = header.append("apiKey", me.apiKey);
    header = header.append("Accept", 'application/json');
    header = header.append('Content-Type', 'application/json');
    header = header.append('onBehalfOf', onBehalfOf.uid);

    let params = {
      data: {
        metadata: {
          documentType: message.data.metadata.documentType,
          quoteAmount: message.data.metadata.quoteAmount,
          description: message.data.metadata.description,
          file: message.data.metadata.file,
          mediaType: message.data.metadata.mediaType,
          status: "APPROVED",
          documentId: message.data.metadata.documentId,
          type: "file"
          // status: "APPROVED"
        }
      }
    }


    const path = `https://${me.appId}.api-${me.region}.cometchat.io/v3/messages/`
    return me.httpClient
      .put(path + id, params, {
        headers: header
      })

  }



  updateChatCustomMessage(message) {
    const me = this;

    let header: HttpHeaders = new HttpHeaders;
    header = header.append("apiKey", me.apiKey);
    header = header.append("Accept", 'application/json');
    header = header.append('Content-Type', 'application/json');
    header = header.append('onBehalfOf', message.senderID);

    let params = {
      data: {
        metadata: {
          documentType: message.documentType,
          quoteAmount: message.quoteAmount,
          description: message.description,
          status: message.status,
          documentId: message.documentId,
          type: "file",
          mediaType: message.mediaType,
          file: message.file,
        }
      }
    }
    const path = `https://${me.appId}.api-${me.region}.cometchat.io/v3/messages/`

    return me.httpClient
      .put(path + message.messageId, params, {
        headers: header
      })

  }


  public sendUnApproveMessage(message) {
    let receiverID = message.receiverId;
    let messageText = message.messageText;
    let receiverType = CometChat.RECEIVER_TYPE.GROUP;
    let messageId = message.messageId;
    let textMessage = new CometChat.TextMessage(
      receiverID,
      messageText,
      receiverType
    );

    textMessage.setId(messageId);
    let mData = {
      documentType: message.documentType,
      quoteAmount: message.quoteAmount,
      description: message.description,
      status: message.status,
      documentId: message.documentId,
      mediaType: message.mediaType,
      file: message.file,
      type: "file",
      action: message.action
    }

    textMessage.setMetadata(mData);

    CometChat.editMessage(textMessage).then(
      (message) => {
        console.log("Message Edited", message);

      },
      (error) => {
        console.log("Message editing failed with error:", error);
      }
    );
  }



}
