import { Store } from "src/app/core/store/store";
import { AdminAccountComponent } from "../admin-account.component";
import {
  AdminPasswordData,
  SubmitAdminDataPayload,
} from "./admin-acoount.model";

export class AdminAccountLoadingState extends Store.AbstractLoadingState<AdminAccountComponent> {}
export class AdminAccountLoadingErrorState extends Store.AbstractErrorState<AdminAccountComponent> {}
export class AdminAccountLoadedState extends Store.AbstractIdealState<AdminAccountComponent> {}

export class AdminPasswordLoadingState extends Store.AbstractLoadingState<AdminPasswordData> {}
export class AdminPasswordLoadingErrorState extends Store.AbstractErrorState<AdminPasswordData> {}
export class AdminPasswordLoadedState extends Store.AbstractIdealState<AdminPasswordData> {}

export class SubmitAdminDataLoadingState extends Store.AbstractLoadingState<SubmitAdminDataPayload> {}
export class SubmitAdminDataLoadingErrorState extends Store.AbstractErrorState<SubmitAdminDataPayload> {}
export class SubmitAdminDataLoadedState extends Store.AbstractIdealState<SubmitAdminDataPayload> {}

export class VerifyEmailLoadingState extends Store.AbstractLoadingState<AdminAccountComponent> {}
export class VerifyEmailLoadingErrorState extends Store.AbstractErrorState<AdminAccountComponent> {}
export class VerifyEmailLoadedState extends Store.AbstractIdealState<AdminAccountComponent> {}

export class ActivateUserAccountLoadingState extends Store.AbstractLoadingState<any> {}
export class ActivateUserAccountLoadingErrorState extends Store.AbstractErrorState<any> {}
export class ActivateUserAccountLoadedState extends Store.AbstractIdealState<any> {}
