import { NgModule } from "@angular/core";
import { FilterPipe } from "src/app/pages/my-team/filter.pipe";
import { CometchatPipe } from "./chat-pipe/cometchat.pipe";
import { DataSuffixPipe, ModifyRolePipe } from "./data-suffix/data-suffix.pipe";
import { DatePipe, DateAgoPipe } from "./date-pipe/date.pipe";
import { EllipsisPipe } from "./ellipsis/ellipsis.pipe";
import { FileSizePipe } from "./file-size-pipe/file-size.pipe";
import { MobileNumberPipe } from "./mobile-number-pipe/mobile-number.pipe";
import { ProMobileNumberPipe } from "./mobile-number-pipe/proMobile-number-pipe";
import { RequestStatusPipe } from "./request-status/request-status.pipe";

import { SortByPipe } from "./sort-by/sort-by.pipe";


const pipes = [
  DatePipe,
  EllipsisPipe,
  FileSizePipe,
  DataSuffixPipe,
  DateAgoPipe,
  SortByPipe,
  MobileNumberPipe,
  ProMobileNumberPipe,
  FilterPipe,
  CometchatPipe,
  RequestStatusPipe,
  ModifyRolePipe
];

@NgModule({
  declarations: [pipes],
  exports: [pipes],
})
export class PipesModule { }
