import { browser } from "protractor";
import { SnackBarService } from "src/app/shared/common/services/snack-bar.service";
import { statusHandler } from "src/app/utility/status-handler.util";
import { Router } from "@angular/router";
import {
  LogoutLoadingState,
  LogoutLoadedState,
  LogoutErrorState,
} from "./../../core/session/session.state";
import { Store } from "src/app/core/store/store";
import { SessionService } from "src/app/core/session/session.service";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ChangePasswordComponent } from "src/app/pages/settings/dialogs/change-password/change-password.component";
import { JoyrideService } from "ngx-joyride";
import { MyNotificationService } from "src/app/pages/notifications/service/my-notification.service";
import * as moment from "moment";
import { MyNotificationPayload } from "src/app/pages/notifications/service/my-notification.model";
import { ServiceCoverageService } from "src/app/pages/settings/service-coverage/service/service-coverage.service";
import {
  FinishGuidedJourneyLoadedState,
  FinishGuidedJourneyLoadingErrorState,
  FinishGuidedJourneyLoadingState,
} from "src/app/pages/settings/service-coverage/service/service-coverage.state";
import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { HomeOwnerProfileService } from "src/app/pages/home-owner/home-owner-profile/service/home-owner-profile.service";
import {
  listedPropertiesLoadedState,
  listedPropertiesLoadingErrorState,
  listedPropertiesLoadingState,
} from "src/app/pages/home-owner/home-owner-profile/service/home-owner-profile.state";
import { CreateRequestServiceService } from "src/app/pages/home-owner/dialogs/create-request/service/create-request-service.service";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { SidenavService } from "./service/sidenav.service";
import { MatDrawer, MatSidenav } from "@angular/material/sidenav";
import { isMobile } from "src/app/utility/browser.util";
import { MixpanelService } from "../mixpanel/mixpanel.service";
import { HOMEOWNER_LOG_OUT } from "../mixpanel/mixpanel.enums";
import { FacebookAuthenticationService } from "../facebook/facebook.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  encapsulation: ViewEncapsulation.None,

  animations: [
    trigger("ngIfAnimation", [
      transition(":enter, :leave", [query("@*", animateChild())]),
    ]),
    trigger("easeInOut", [
      transition("void => *", [
        style({
          opacity: 0,
        }),
        animate(
          "0.5s ease-in",
          style({
            opacity: 1,
          })
        ),
      ]),
      transition("* => void", [
        style({
          opacity: 1,
        }),
        animate(
          "0.5s ease-in",
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class SidenavComponent implements OnInit, AfterViewInit {
  isLoggedIn$: Observable<boolean>;

  isOpen: boolean = false; // open setting overlay.

  data: any;
  error: boolean;
  empty: boolean;
  loading: boolean;
  notificationData: any;
  notificationEmpty: boolean;
  notificationError: boolean;
  notificationLoading: boolean;

  logoPath: Observable<string>;
  // profilePath:any
  FirmName: Observable<string>;
  proRole: any;
  tabLinksLabel: any;
  firstUserLoggedIn: boolean = false;
  isUserGuideCompleted: boolean;
  notification: any = [];
  dates: any;
  isShow: boolean = true;
  filterText: any;
  accountDetails: any;
  queryString: string;
  receiverId: string;
  fromDate: string;
  toDate: string;
  userGuideText: any;
  pagination: {
    p: number;
    pp: number;
  };
  field: string;
  order: string;
  selectedAddress: any;
  // selected='value';
  value;
  userid: any;
  userAddressData: any[];

  defaultnotificationData: any;

  NotificationData;
  newNotification: Observable<any>;
  NotificationId: string;
  PresentNotification: boolean;
  isDisabledOn = true;

  searchTextControl = new FormControl();

  selectedDay: any = "30";
  notificationPayload: MyNotificationPayload = {
    query: null,
    filter: {
      receiverId: [],
      fromDate: "",
      toDate: "",
    },
    sort: [
      {
        field: "",
        order: "",
      },
    ],
    p: 0,
    pp: 100,
  };

  fullName: Observable<string>;
  // fullName: string;
  selected = "allProperties";

  statusClass = "";
  // role: string = "home-owner";
  @Input() isExpanded: boolean = true;

  // isExpanded:boolean;

  constructor(
    private sidenavService?: SidenavService,
    public createRequestServiceService?: CreateRequestServiceService,
    private snackbar?: SnackBarService,
    public dialog?: MatDialog,
    public notificationService?: MyNotificationService,
    public session?: SessionService,
    private readonly joyrideService?: JoyrideService,
    private router?: Router,
    private cd?: ChangeDetectorRef,
    private serviceCoverageService?: ServiceCoverageService,
    private homeOwnerProfileService?: HomeOwnerProfileService,
    private navigator?: Router,
    private mixpanelService?: MixpanelService,
    private facebookAuthenticationService?: FacebookAuthenticationService
  ) { }

  tabLinks: any[] = []; //TODO: add datatype for this

  settingTabLinks: object[];
  logo: Observable<any>;
  @Output() actionHandler = new EventEmitter();
  mobile: boolean = false;

  ngOnInit(): void {
    const me = this;
    me.logo = me.session.logo$;
    // me.FirmName = me.session?.account?.firms[0].name;
    me.userid = me.session?.account?.id;
    me.proRole = me.session?.account?.roles[0].code;
    me.firstUserLoggedIn = me.session?.account?.hasUserLoggedInFirstTime;
    me.isUserGuideCompleted = me.session?.account?.isUserGuideCompleted;

    me.notificationService.notificationData.subscribe((data) => {
      me.notificationData = data.data;
      // console.log(me.notificationData);
    });

    me.getPropertyIdArray();

    // if (me.firstUserLoggedIn === true && me.proRole === "PRO_USER") {
    //   me.openChangePasswordDialog();
    //   me.firstUserLoggedIn = false;
    // } else {
    //   me.firstUserLoggedIn = false;
    // }

    if (
      me.proRole === "HOMEOWNER" ||
      me.proRole === "PROPERTY_MANAGER" ||
      me.proRole === "PROPERTY_MANAGER_ADMIN"
    ) {
      me.tabLinks.push(
        {
          // icon:'../assets/sidenav_logo.svg',
          label: "chat",
          link: "/home-owner/services",
          icon: "../assets/services.svg",
          tooltip: "chat",
          showTab: true,
        },
        {
          label: "Service Tickets",
          link: "/home-owner/my-referrals",
          icon: "../assets/my_referral.svg",
          tooltip: "referral requests",
          showTab: true,
        },
        {
          label: "My Pros",
          link: "/home-owner/pros",
          icon: "../assets/myPros.svg",
          tooltip: "my pros",
          showTab: true,
        },
        {
          label: "notifications",
          link: "/home-owner/notifications",
          icon: "../assets/notification.svg",
          tooltip: "notifications",
          showTab: true,
        },
        {
          label: "properties",
          link: "/home-owner/properties",
          icon: "../assets/property-icon.svg",
          tooltip: "properties",
          showTab: true,
        },
        // {
        //   label: 'img cropper',
        //   link: '/home-owner/img-cropper',
        //   icon: '../assets/leads.svg',
        //   tooltip: 'img cropper',
        //   showTab: true
        // },
        {
          label: "my pms",
          link: "/home-owner/property-managers",
          icon: "../assets/leads.svg",
          tooltip: "my pms",
          showTab:
            me.proRole === "PROPERTY_MANAGER_ADMIN" ? true : false,
        },
      );
    }
    // else if (me.proRole === "PROPERTY_MANAGER") {
    //   me.tabLinks.push({
    //     label: "properties",
    //     link: "/home-owner/properties",
    //     icon: "../assets/leads.svg",
    //     tooltip: "properties",
    //   },)
    // }
    // else if (me.proRole === "PROPERTY_MANAGER_ADMIN") {
    //   me.tabLinks.push({
    //     label: "properties",
    //     link: "/home-owner/properties",
    //     icon: "../assets/leads.svg",
    //     tooltip: "properties",
    //   },)
    // }
    else {
      me.tabLinks.push(
        {
          label: "leads",
          link: "/pro/leads",
          icon: "../assets/leads.svg",
          tooltip: "leads",
          showTab: true,
        },
        {
          label: "chat",
          link: "/pro/chats",
          icon: "../assets/services.svg",
          tooltip: "chat",
          showTab: true,
        },

        {
          label: "my trade network",
          link: "/pro/network",
          icon: "../assets/myTradeNetwork.svg",
          tooltip: "my trade network",
          showTab: true,
        },
        {
          label: "my clients",
          link: "/pro/my-client",
          permission: ["CAN_ACCESS_ADMIN_ACCOUNT"],
          icon: "../assets/my_client.svg",
          tooltip: "my clients",
          showTab: true,
        },
        {
          label: "notifications",
          link: "/pro/notifications",
          icon: "../assets/notification.svg",
          tooltip: "notifications",
          showTab: true,
        },
        //for pro user
        {
          label: "profile",
          link: "/pro/settings/my-account",
          permission: ["CAN_ACCESS_MY_ACCOUNT"],
          icon: "../assets/profile.svg",
          tooltip: "profile",
          showTab: true,
        },
        //for pro admin
        {
          label: "profile",
          link: "/pro/settings",
          permission: ["CAN_ACCESS_BUSINESS_ACCOUNT"],
          icon: "../assets/profile.svg",
          tooltip: "profile",
          showTab: true,
        },
        {
          label: "credits",
          link: "/pro/credits",
          icon: "/assets/credits.svg",
          tooltip: "credits",
          showTab: true,
        }
        //invites tab wip
        // {
        //   label: "invites",
        //   link: "/pro/invites",
        //   icon: "/assets/credits.svg",
        //   tooltip: "invite"
        // }

        // {
        //   label: "my users",
        //   link: "/pro/my-team",
        //   permission: ["CAN_ACCESS_MY_TEAM"],
        //   icon: "../assets/myUsers.svg",
        //   tooltip: "my users",
        // }
      );
    }
    // me.homeOwnerTabLinks = [
    //   //homw owner sidenav
    //   {
    //     label: "My Pros",
    //     link: "/my-pros",
    //   },
    // ];

    if (me.proRole === "HOMEOWNER") {
      me.userGuideText = [
        {
          text: "",
        },
        {
          text: "Create new Referral Requests and find new Pros here. You can also check the status of your Requests.",
        },
        {
          text: "Quick access to Pros you work with.",
        },
        {
          text: "View notifications here.",
        },
      ];
    } else if (me.proRole === "PRO_ADMIN") {
      me.userGuideText = [
        {
          text: "You can view your leads here.",
        },
        {
          text: "You can chat with your clients here.",
        },
        {
          text: "Build and manage your trade network here. The better your trade network the better your referrals.",
        },
        {
          text: "You can view your clients here and their contact information. You can also invite your clients to improve your target leads.",
        },
        {
          text: "You can view notifications here.",
        },
        {
          text: "Hi, let us show you around. Begin by setting up your business profile.",
        },
        {
          text: "Click here to view your firm and edit your firm profile, service area, and service categories. This will directly affect where and what type of leads you receive.",
        },
        // {
        //   text: "If you have more than one pro in your team, you can manage them here.",
        // },
      ];
    } else {
      me.userGuideText = [
        {
          text: "You can view your leads here.",
        },
        {
          text: "You can chat with your clients here.",
        },
        {
          text: "Build and manage your trade network here. The better your trade network the better your referrals.",
        },
        {
          text: "",
        },
        {
          text: "You can view notifications here.",
        },
        {
          text: "View your account details here. Make sure to have a valid mobile phone number to receive text notifications of incoming leads.",
        },
      ];
    }

    me.createSettingRoutes(); // create static routing array of object for setting.

    // me.profilePath = me.sessionService?.account.meta.profile;
    // console.log(me.profilePath);
    me.logoPath = me.session.logoPath$;

    me.fullName = me.session.fullName$;
    // me.fullName = me.sessionService?.account.fullName;

    me.FirmName = me.session.firmName$;
    //console.log(me.fullName);
    // get notification
    var toDate = new Date();
    var fromdate = new Date().setDate(toDate.getDate() - 7);

    me.fromDate = moment(fromdate).startOf("day").format("YYYY-MM-DD HH:mm:SS");
    me.toDate = moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:SS");

    me.accountDetails = me.session?.account;
    me.receiverId = me.accountDetails?.id;

    me.onPasswordChange();

    me.getListOfAddress();

    me.createRequestServiceService.$newAddressCreated.subscribe(
      (addressChangeEvent) => {
        if (addressChangeEvent) {
          me.getListOfAddress();
        }
      }
    );
    // me.getSelectedAddress();
  }

  ngAfterViewInit() {
    const me = this;

    if (
      me.proRole === "PRO_ADMIN" &&
      me.firstUserLoggedIn === false &&
      me.isUserGuideCompleted === false
    ) {
      me.joyrideService.startTour({
        steps: [
          "tabIndex6@pro/settings/business-account",
          "step0@pro/settings/business-account",
          "step1@pro/settings/service-category",
          "step2@pro/settings/service-gallery",
          "step3@pro/settings/service-coverage",
          "step5@pro/settings/admin-account",
          "step6@pro/settings/my-team",
          "tabIndex0@pro/leads",
          "tabIndex1@pro/services",
          "tabIndex2@pro/network",
          "tabIndex3@pro/my-client/client",
          // "tabIndex7@pro/my-team",
          "tabIndex4@pro/notifications",
          "profile@/pro/firm-info",
          "confirmClose@pro/settings/business-account",
        ],
        showCounter: false,
      });
    } else if (
      me.proRole === "HOMEOWNER" &&
      me.firstUserLoggedIn === false &&
      me.isUserGuideCompleted === false &&
      !me.showUserGuidedJourney()
    ) {
      me.joyrideService.startTour({
        steps: [
          "profile@/home-owner/profile",
          "propertyDropdown",
          "createRequest@home-owner/services",
          "chat@home-owner/services",
          "requests_pending@home-owner/services",
          "tabIndex2@/home-owner/my-pros",
          "tabIndex1@/home-owner/my-referrals",
          "tabIndex3@/home-owner/notifications",
          "confirmClose@/home-owner/services",
        ],
        showCounter: false,
      });
    }

    // me.proRole === 'PRO_USER' &&
    if (me.firstUserLoggedIn === true) {
      me.openChangePasswordDialog();
      me.firstUserLoggedIn = false;
    }
  }

  // profile setting tab array.

  createSettingRoutes() {
    const me = this;

    if (me.proRole == "HOMEOWNER") {
      me.settingTabLinks = [
        {
          label: "view profile",
          link: "/home-owner/profile",
        },
      ];
    } else {
      me.settingTabLinks = [
        {
          label: "view profile",
          link: "/pro/firm-info",
        },
        {
          label: "account settings",
          link: "/pro/admin-account",
          permission: ["CAN_ACCESS_ADMIN_ACCOUNT"],
        },
        // {
        //   label: "billing information",
        //   link: "/billing-information",
        //   permission: ["CAN_ACCESS_BILLING_INFORMATION"],
        // },
        {
          label: "my account",
          link: "/pro/my-account",
          permission: ["CAN_ACCESS_MY_ACCOUNT"],
        },
      ];
    }
  }

  getFirmName(firmName: string) {
    if (firmName.length < 15) {
      return firmName;
    } else {
      const fName = firmName.split(" ");
      let newFirmName = "";
      fName.forEach((str) => (newFirmName += str.charAt(0)));
      return newFirmName.toUpperCase();
    }
  }

  // logout function
  logOut() {
    const me = this;

    me.session.logout().subscribe(
      (state: Store.State) => {
        if (state instanceof LogoutLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof LogoutLoadedState) {
          me.onLoaded(state);
          return;
        }
      },
      (state: LogoutErrorState) => {
        me.onLoadingError(state);
      }
    );
  }

  private onLoading(state: LogoutLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: LogoutErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = true;
  }

  private onLoaded(state: LogoutLoadedState) {
    const me = this;
    me.data = state.data;
    me.error = false;
    me.loading = false;
    if (me.data) {
      me.snackbar.openSnackBar(statusHandler(me.data.status), 3000);
      if (me.session.account) {
        // me.session.details?.mixPanelAnalyticsEnabled
        if (me.session.details?.mixPanelAnalyticsEnabled) {
          const role = me.session.account?.roles[0]?.name;
          let mixPanelLogoutEvent;
          switch (role) {
            case "Homeowner": {
              mixPanelLogoutEvent = HOMEOWNER_LOG_OUT;
              break;
            }
            default: {
              break;
            }
          }
          me.mixpanelService.sendEvent(mixPanelLogoutEvent);
          me.mixpanelService.logout();
        }
      }
      if (me.facebookAuthenticationService.facebookAuthObj) {
        me.facebookAuthenticationService.logout();
      }
      me.router.navigate(["logout"]);
    }
  }

  openChangePasswordDialog(): void {
    const me = this;
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: "550px",
      data: {
        userLoggedIn: false,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onPasswordChange();
    });
  }

  routeToFirmInformation() {
    this.router.navigate(["/firm-info/firm-information"]);
    // this.router.navigate(['/info/information', '58b6b485-bb68-4e90-be19-1cba20d97aa3'])
  }

  public finishGuidedJourney() {
    const me = this;

    const reqBody = {
      isUserGuideCompleted: true,
    };

    me.serviceCoverageService
      .finishGuidedJourney(reqBody, me.session.account.id)
      .subscribe(
        (state: Store.State) => {
          if (state instanceof FinishGuidedJourneyLoadingState) {
            return;
          }

          if (state instanceof FinishGuidedJourneyLoadedState) {
            me.session.refreshAccountInfo();
            return;
          }
        },
        (state: FinishGuidedJourneyLoadingErrorState) => {
          // console.log(state.data)
        }
      );
  }

  public startGuidedJourney() {
    const me = this;
    if (me.proRole === "PRO_USER") {
      me.joyrideService.startTour({
        steps: [
          "tabIndex5@/pro/settings/my-account",
          // "step0@pro/settings/my-account",
          "tabIndex0@pro/leads",
          "tabIndex1@pro/chats",
          "tabIndex2@pro/network",
          "tabIndex4@pro/notifications",
          "profile@/pro/firm-info",
          "confirmClose@pro/settings/my-account",
        ],
        showCounter: false,
      });
    } else if (me.proRole === "PRO_ADMIN") {
      me.joyrideService.startTour(
        // {
        //   steps: ['tabIndex5', 'step0@settings/business-account', 'editStep@settings/business-account',
        //     'step1@settings/admin-account', 'adminEditStep@settings/admin-account',
        //     'step2@settings/service-category', 'categoryEditStep@settings/service-category', 'galleryEditStep@settings/service-category',
        //     'step3@settings/service-coverage', 'coverageEditStep@settings/service-coverage', 'tabIndex4@notifications', 'tabIndex3@network', 'makeReferral@network', 'referBack@network', 'refferalReceived@network', 'referAbc@network', 'tabIndex2'],
        //   showCounter: false
        // },
        {
          steps: [
            "tabIndex6@pro/settings/business-account",
            "step0@pro/settings/business-account",
            "step1@pro/settings/service-category",
            "step2@pro/settings/service-gallery",
            "step3@pro/settings/service-coverage",
            "step5@pro/settings/admin-account",
            "step6@pro/settings/my-team",
            "tabIndex0@pro/leads",
            "tabIndex1@pro/services",
            "tabIndex2@pro/network",
            "tabIndex3@pro/my-client/client",
            // "tabIndex7@pro/my-team",
            "tabIndex4@pro/notifications",
            "profile@/pro/firm-info",
            "confirmClose@pro/settings/business-account",
          ],
          showCounter: false,
        }
      );
    } else if (me.proRole === "HOMEOWNER") {
      me.joyrideService.startTour({
        steps: [
          "profile@/home-owner/profile",
          "propertyDropdown",
          "createRequest@home-owner/services",
          "chat@home-owner/services",
          "requests_pending@home-owner/services",
          "tabIndex2@/home-owner/pros",
          "tabIndex1@/home-owner/my-referrals",
          "tabIndex3@/home-owner/notifications",
          "confirmClose@/home-owner/services",
        ],
        showCounter: false,
      });
    }
  }

  endGuidedJourney() {
    const me = this;
    setTimeout(() => {
      if (me.proRole === "PRO_USER") {
        me.joyrideService.startTour({
          steps: ["confirmClose@pro/settings/my-account"],
          showCounter: false,
        });
      } else if (me.proRole === "PRO_ADMIN") {
        me.joyrideService.startTour({
          steps: ["confirmClose@pro/settings/business-account"],
          showCounter: false,
        });
      } else {
        me.joyrideService.startTour({
          steps: ["confirmClose@home-owner/services"],
          showCounter: false,
        });
      }
    }, 100);
  }

  onPasswordChange() {
    const me = this;
    if (
      me.proRole === "PRO_USER" &&
      me.firstUserLoggedIn === false &&
      me.isUserGuideCompleted === false
    ) {
      me.joyrideService.startTour({
        steps: [
          "tabIndex5@/pro/settings/my-account",
          // "step0@pro/settings/my-account",
          "tabIndex0@pro/leads",
          "tabIndex1@pro/services",
          "tabIndex2@pro/network",
          "tabIndex4@pro/notifications",
          "profile@/pro/firm-info",
          "confirmClose@pro/settings/my-account",
        ],
        showCounter: false,
      });
    } else if (
      me.proRole === "PRO_ADMIN" &&
      me.firstUserLoggedIn === false &&
      me.isUserGuideCompleted === false
    ) {
      me.joyrideService.startTour({
        steps: [
          "tabIndex6@pro/settings/business-account",
          "step0@pro/settings/business-account",
          "step1@pro/settings/service-category",
          "step2@pro/settings/service-gallery",
          "step3@pro/settings/service-coverage",
          "step5@pro/settings/admin-account",
          "step6@pro/settings/my-team",
          "tabIndex0@pro/leads",
          "tabIndex1@pro/chats",
          "tabIndex2@pro/network",
          "tabIndex3@pro/my-client/client",
          // "tabIndex7@pro/my-team",
          "tabIndex4@pro/notifications",
          "profile@/pro/firm-info",
          "confirmClose@pro/settings/business-account",
        ],
        showCounter: false,
      });
    }
  }

  getListOfAddress() {
    const me = this;

    const payload = {
      query: "",
      filter: {
        keyIdentifier: "all",
      },
      sort: [
        {
          field: "createdAt",
          order: "asc",
        },
      ],
      p: 0,
      pp: 500,
    };

    me.homeOwnerProfileService
      .getListedProperties(payload)
      .subscribe((state: Store.State) => {
        if (state instanceof listedPropertiesLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof listedPropertiesLoadedState) {
          const me = this;
          me.userAddressData = state?.data?.data.content;
          me.getSelectedAddress();
          return;
        }

        (state: listedPropertiesLoadingErrorState) => {
          const me = this;
          //  me.addressdata = null;
          //  me.addressempty = false;
          //  me.addresserror = true;
          //  me.addressloading = true;
        };
      });
  }

  // append latest time and date at the end of banner image URL for avoid browser cache.
  // appendDateAndTime(): string {

  //   return '?date' + new Date();
  // }

  allPropertyArray: any;

  getPropertyIdArray() {
    this.homeOwnerProfileService.propertyIdArray.subscribe((idArr: any[]) => {
      this.allPropertyArray = [...idArr];
    });
  }

  getSelectedAddress() {
    const me = this;
    const addressObject = AppLocalStorage.get("SESSION", "SELECTEDADDRESS");
    const selectedAddress = addressObject?.selectedAddress;
    if (selectedAddress) {
      me.selectedAddress = selectedAddress;
      me.sidenavService.addressSelected.next([selectedAddress]);
    } else {
      me.selectedAddress = "";
      me.sidenavService.addressSelected.next(this.allPropertyArray);
    }
  }

  onAddressChange(event) {
    const me = this;
    const selectedAddress = {
      selectedAddress: event,
    };
    me.closeFilter();
    AppLocalStorage.set("SESSION", "SELECTEDADDRESS", selectedAddress);

    if (event) {
      me.sidenavService.addressSelected.next([event]);
    } else {
      me.sidenavService.addressSelected.next(this.allPropertyArray);
    }
  }

  // onViewProfile() {
  //   const me = this;
  //   me.router.navigate(["/home-owner/profile"]);
  // }
  closeFilter() {
    // this.drawer.close();
    this.actionHandler.emit({ isMobile: isMobile });
    // console.log("clickedddd");
  }

  public showUserGuidedJourney() {
    return /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  public getViewProfileUserGuidedText(role: string): string {
    if (role === "HOMEOWNER") {
      return "Hi, let us show you around. Begin by setting up your profile and adding your address.";
    } else {
      return "View your Firm Profile here.";
    }
  }
}
