import { Directive, HostListener, ElementRef } from "@angular/core";

@Directive({
  selector: "[appAlphabetOnly]",
})
export class AlphabetOnlyDirective {
  private regex: RegExp = new RegExp("^[a-zA-Z ]*$");

  constructor(private _el: ElementRef) {}

  @HostListener("keydown", ["$event"]) onKeyDown(event: KeyboardEvent) {
    const inputValue: string = this._el.nativeElement.value.concat(event.key);
    //  var inputValue = inputValue.replace(/^\s+|\s+$/g, "");
    // console.log(inputValue);

    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }
    return;
  }

  @HostListener("paste", ["$event"]) onPaste(event) {
    const clipboarddata = (event.originalEvent || event).clipboarddata.getData(
      "text/plain"
    );

    if (clipboarddata) {
      const regex = new RegExp("^[a-zA-Z]+$");
      if (!regex.test(clipboarddata)) {
        event.preventDefault();
      }

      return;
    }
  }
}
